/* SHE Pack Component

    This component consists of all the she pack compoents that the user can user to naviagte to different options of the SHE Pack
*/

// -------------- React Components ----------
import React from "react";
import { Link } from "react-router-dom";



// ------------- Icons --------------

import { IoPeopleOutline } from "react-icons/io5";

import { AiOutlineFolderAdd } from "react-icons/ai"; // Icon -  Create File
import { AiOutlineFileAdd } from "react-icons/ai"; // Icon - create Template

import { IoIosLink } from "react-icons/io";

import { CiPen } from "react-icons/ci";

// ------------ CSS File ------------ 
import '../../assets/css/Checklists.css';

const ComplianceManagementComponent = () => {

    const user = JSON.parse(sessionStorage.getItem('user'));

    

    return (

        <div className="component-container">

            <div className="manage-users-component-container">

                <div className="manage-users-header-component-container">

                    <div>
                        <h1>Compliance Tracker Management</h1>
                    </div>

                </div>

                {/* Section - File and Template Manager   */}
                {(user.userRole === 'super admin' || user.userRole === 'user')  && (
                
                    <div className="manage-users-company-main-container">

                        <div className="manage-users-text-container">

                            <div>
                                <h2>File and Templates Management</h2>
                                <p>Create, edit, and manage files efficiently</p>
                            </div>
                        
                        </div>

                        {/* Items - file and Template Manager */}
                        <div className="manage-users-feature-main-container">

                            {/* Link - Create SHE File */}
                            <Link className="link" to='/compliance-tracker/compliance-tracker-management/create-she-file'>

                                <div className='manage-users-feature-container'>

                                    <div className="feature-img-container">
                                        <AiOutlineFolderAdd className="feature-img"/>
                                    </div>

                                    <div className="feature-name-container">

                                        <h4>Create SHE File</h4>

                                    </div>

                                    <div className="feature-description-container">

                                        <p>
                                            To create a she file tailored to your requirements.
                                        </p>

                                    </div>

                                </div>
                                
                                </Link>

                                <Link className="link" to='/compliance-tracker/compliance-tracker-management/create-templates'>

                                <div className='manage-users-feature-container'>

                                    <div className="feature-img-container">
                                        <AiOutlineFileAdd className="feature-img"/>
                                    </div>

                                    <div className="feature-name-container">

                                        <h4>Create Templates</h4>

                                    </div>

                                    <div className="feature-description-container">

                                        <p>
                                            To create a templates for a SHE File tailored to your requirements
                                        </p>

                                    </div>

                                </div>
                                
                                </Link>

                               
                        
                        
                        </div>
                    </div>
                )}

                {/* Section - People Management   */}
                {(user.userRole === 'super admin' || user.userRole === 'user')  && (
                
                <div className="manage-users-company-main-container">

                    <div className="manage-users-text-container">

                        <div>
                            <h2>SHE File & Template Submissions</h2>
                            <p>Complete and submit SHE files and templates. </p>
                        </div>
                    
                    </div>

                    {/* Items - file and Template Manager */}
                    <div className="manage-users-feature-main-container">

                        {/* Link - Create SHE File */}
                        <Link className="link" to='/compliance-tracker/compliance-tracker-management/consultant-template-fill-in'>

                            <div className='manage-users-feature-container'>

                                <div className="feature-img-container">
                                    <CiPen  className="feature-img"/>
                                </div>

                                <div className="feature-name-container">

                                    <h4>Consultant Template Fill In</h4>

                                </div>

                                <div className="feature-description-container">

                                    <p>
                                        To complete and submit a template.
                                    </p>

                                </div>

                            </div>
                            
                            </Link>

                          
                    
                    </div>
                </div>
            )}


                {/* Section - People Management   */}
                {(user.userRole === 'super admin' || user.userRole === 'user')  && (
                
                <div className="manage-users-company-main-container">

                    <div className="manage-users-text-container">

                        <div>
                            <h2>People Management</h2>
                            <p>Create, edit and manage users linked to compliance.</p>
                        </div>
                    
                    </div>

                    {/* Items - file and Template Manager */}
                    <div className="manage-users-feature-main-container">

                        {/* Link - Create SHE File */}
                        <Link className="link" to='/compliance-tracker/people-management/create-employee'>

                            <div className='manage-users-feature-container'>

                                <div className="feature-img-container">
                                    <IoPeopleOutline  className="feature-img"/>
                                </div>

                                <div className="feature-name-container">

                                    <h4>Create Employee</h4>

                                </div>

                                <div className="feature-description-container">

                                    <p>
                                        Create employee lists that belong to a company and department.
                                    </p>

                                </div>

                            </div>
                            
                            </Link>

                            {/* Link - Create SHE File */}
                        <Link className="link" to='/compliance-tracker/people-management/assign-consultant'>

                            <div className='manage-users-feature-container'>

                                <div className="feature-img-container">
                                    <IoIosLink  className="feature-img"/>
                                </div>

                                <div className="feature-name-container">

                                    <h4>Assign Template to Consultant</h4>

                                </div>

                                <div className="feature-description-container">

                                    <p>
                                        Assign a consultant or a company to a she file and templates
                                    </p>

                                </div>

                            </div>
                            
                            </Link>

                    
                    </div>
                </div>
            )}


            </div>

        </div>

    )
}

export default ComplianceManagementComponent