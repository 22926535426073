/* ---------- SHE Pack Fill In component ------------------

    -   This component is used for when the user is completing a checklist. 
*/

// ------------------ React Components ------------------------
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

// -------------------- API -----------------------------
import API_BASE_URL from "../../utils/api/apiConfig.js";


// --------------------- CSS File --------------------
import '../../../assets/css/Checklists.css';

// --------------------- icons --------------------

import { LiaSpinnerSolid } from "react-icons/lia";



import { FaRegFolderOpen } from "react-icons/fa";

// --------------------- Assests -------------------
import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png';

import ToasterConsultantTemplateFill from './ToasterConsultantTemplateFill.js';



// --------------------- View SHE Pack component -------------------
const ConsultantTempateFillInComponent = () => {

  // Retrieve user data from session storage
  const user = JSON.parse(sessionStorage.getItem('user'));

  const navigate = useNavigate();

  // --------------- State Managements ------------------------

  // New state for companies and departments
  const [sheFileCompanies, setSheFileCompanies] = useState({});
  const [selectedCompanies, setSelectedCompanies] = useState({});
  const [selectedCompanyName, setSelectedCompanyName] = useState({});

  
  const [departmentsPerCompany, setDepartmentsPerCompany] = useState({});
  const [selectedDepartments, setSelectedDepartments] = useState({});
  const [selectedDepartmentName, setSelectedDepartmentName] = useState({});

  // State Management - Global 
  const [submissionStatus, setSubmissionStatus] = useState('Not Started');

  

  // State Management - selected she pack
  const [selectedSheFileId, setSelectedSheFileId] = useState(null);
  const [selectedSheFileName, setSelectedSheFileName] = useState('');
  const [selectedSheFileScore, setSelectedSheFileScore] = useState(null);

  const [templates, setTemplates] = useState([]);
  

  // state management - slected document 
  // Add these state variables near your other state declarations
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [selectedTemplateName, setSelectedTemplateName] = useState('');
  const [selectedTemplateScore, setSelectedTemplateScore] = useState('');
  const [selectedTemplateUserPercentage, setSelectedTemplateUserPercentage] = useState('');


  //
  const [assignedSheFiles, setAssignedSheFiles] = useState([]);

  
  //
  const [showTemplateToaster, setShowTemplateToaster] = useState(false);

 
  const [isLoading, setIsLoading] = useState(true);
  
  

  // ------------------- Functions ---------------------------

  // Fetch assigned SHE packs and their companies
 // Fetch assigned SHE packs and their companies
 useEffect(() => {

    const fetchAssignedSheFiles = async () => {

      setIsLoading(true);
      
      try {
        const response = await axios.get(`${API_BASE_URL}/api/compliance-tracker/consultant/template-fill-in/assigned-she-files/${user.user_id}`);
        const processedSheFiles = response.data.map(shefile => ({
          ...shefile,
          total_submissions: shefile.total_submissions 
            ? parseInt(shefile.total_submissions, 10) 
            : 0
        }));

        setAssignedSheFiles(processedSheFiles);

        // Fetch companies and their departments for each SHE pack
        for (const shefile of processedSheFiles) {

          const companiesResponse = await axios.get(
            `${API_BASE_URL}/api/compliance-tracker/consultant-fill-in/consultant-assignments/company/${shefile.shefile_id}/${user.user_id}`
          );
          
          setSheFileCompanies(prev => ({
            ...prev,
            [shefile.shefile_id]: companiesResponse.data
          }));

          // Store departments for each company
          const departmentsMap = {};
          companiesResponse.data.forEach(companyData => {
            departmentsMap[companyData.company] = companyData.departments;
          });
          
          setDepartmentsPerCompany(prev => ({
            ...prev,
            [shefile.shefile_id]: departmentsMap
          }));
        }

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAssignedSheFiles();
  }, [user.user_id]);

  
  // Modify handleCompanyChange to reset department when company changes
  const handleCompanyChange = (sheFileId, company) => {
    // Find the full company name from the companies list
    const companyData = sheFileCompanies[sheFileId]?.find(c => c.company === company);
    
    setSelectedCompanies(prev => ({
      ...prev,
      [sheFileId]: company
    }));
  
    // Store the company name
    setSelectedCompanyName(prev => ({
      ...prev,
      [sheFileId]: companyData ? companyData.companyName : ''
    }));
    
    // Reset selected department when company changes
    setSelectedDepartments(prev => ({
      ...prev,
      [sheFileId]: ''
    }));
    
    // Reset selected department name
    setSelectedDepartmentName(prev => ({
      ...prev,
      [sheFileId]: ''
    }));
  };

  // Add handleDepartmentChange function
  const handleDepartmentChange = (sheFileId, departmentId) => {
    // Find the full department name from the departments list
    const departmentList = selectedCompanies[sheFileId] 
      ? departmentsPerCompany[sheFileId]?.[selectedCompanies[sheFileId]] 
      : [];
    
    const departmentData = departmentList?.find(d => d.id === departmentId);
    
    setSelectedDepartments(prev => ({
      ...prev,
      [sheFileId]: departmentId
    }));
  
    // Store the department name
    setSelectedDepartmentName(prev => ({
      ...prev,
      [sheFileId]: departmentData ? departmentData.name : ''
    }));
  };

  // Fucntions - Global
  const handleNavigateBack = () => {
    navigate(-1);
  };

  // Update the handleClick function to include company and department
  const handleClick = async (sheFileId, sheFileName, sheFileScore) => {

    setIsLoading(true);
    setSelectedSheFileId(sheFileId);
    setSelectedSheFileName(sheFileName);
    setSelectedSheFileScore(sheFileScore);

    try {
        console.log('Fetching templates for sheFileId:', sheFileId);
        const templatesResponse = await axios.get(
            `${API_BASE_URL}/api/compliance-tracker/consultant-fill-in/view-assigned-templates/${sheFileId}`
        );
        
        console.log('Templates API Response:', templatesResponse.data);
        
        if (templatesResponse.status === 200) {
            if (Array.isArray(templatesResponse.data)) {
                setTemplates(templatesResponse.data);
                setShowTemplateToaster(true);
            }
        }
    } catch (error) {

        console.error('Error fetching SHE File templates:', error);

    } finally {

        setIsLoading(false);
        
    }

};
  // Function - Submissions 
  
  return (
    <div className="component-container">
      <div className="create-checklist-component-container">

        <div className="create-company-text-container">

          <div>
            <h1> Compliance - Consultant Fill In Template </h1>
            <p> Fill in a template </p>
          </div>

          <div>
              <img src={blueKbcLogo} alt="white-kbc-logo" className="side-menu-logo" />
          </div>
          
        </div>

        <div className="navigate-back-button-main-container">
          <div className="navigate-back-button-container">
            <button className="navigate-back-button" onClick={handleNavigateBack}>Back</button>
          </div>
        </div>

        <div className="view-checklist-table-container">
          {isLoading ? (
            <div className="loading-spinner-container">
              <LiaSpinnerSolid className="lia-spin-solid-icon" />
            </div>
          ) : (
            <div className="view-checklist-table-table-container">
              <table className="view-checklist-table">

                <colgroup>

                  {/* Remove whitespace between col elements */}
                  <col style={{width: "300px"}}/>{/* Select SHE Pack */}
                  <col style={{width: "350px"}}/>{/* SHE Pack Name */}       
                  <col style={{width: "200px"}}/>{/* SHE Pack Score */} 
                  <col style={{width: "200px"}}/>{/* Company */} 
                  <col style={{width: "200px"}}/>{/* Department */} 
                       
                </colgroup>

                <thead>
                  <tr>
                    
                    <th className="table-header">SHE File Name</th>
                    {/* <th className="table-header">SHE Pack Score</th>*/}
                    <th className="table-header">Company</th>
                    <th className="table-header">Site</th>
                    <th className="table-header">Select SHE File</th>
                    
                    
                    
                  </tr>
                </thead>

                <tbody className="view-table-body">

                  {assignedSheFiles && assignedSheFiles.length < 0 ? ( 
                    <tr>
                      <td colSpan="5">
                        <p>Currently no SHE Packs created. Click "Create SHE Pack" button to create a SHE Pack.</p>
                        
                        <Link to='/she-pack/create-she-pack'>
                          <div className='view-checklist-create-checklist-button-contianer'>
                            <p>Create SHE Pack</p>
                          </div>
                        </Link>

                      </td>
                    </tr>
                  ) : (
                    assignedSheFiles.map((sheFile, index) => (

                      <tr key={index}>

                        

                        {/* column - SHE Pack name */}
                        <td>{sheFile.shefile_name}</td>

                      

                        {/* column - Compnay Name  */}
                        <td >
                        <select
                          className="company-dropdown"
                          value={selectedCompanies[sheFile.shefile_id] || ''}
                          onChange={(e) => handleCompanyChange(sheFile.shefile_id, e.target.value)}
                      >
                          <option value="">Select Company</option>
                          {sheFileCompanies[sheFile.shefile_id]?.map((companyData, idx) => (
                              <option key={idx} value={companyData.company}>
                                  {companyData.companyName}
                              </option>
                          ))}
                      </select>
                        </td>

                        {/* column - Department name  */}
                        <td>
                        <select
                          className="department-dropdown"
                          value={selectedDepartments[sheFile.shefile_id] || ''}
                          onChange={(e) => handleDepartmentChange(sheFile.shefile_id, e.target.value)}
                          disabled={!selectedCompanies[sheFile.shefile_id]}
                      >
                          <option value="">Select Site</option>
                          {selectedCompanies[sheFile.shefile_id] && 
                              departmentsPerCompany[sheFile.shefile_id]?.[selectedCompanies[sheFile.shefile_id]]?.map((department, idx) => (
                                  <option key={idx} value={department.id}>
                                      {department.name}
                                  </option>
                          ))}
                      </select>
                        </td>

                        {/* column - view SHE pack */}
                        <td onClick={() => handleClick(sheFile.shefile_id, sheFile.shefile_name, sheFile.shefile_score)}>
                          <FaRegFolderOpen className='view-checklist-submition-icon' />
                        </td>

                      
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>

      
      </div>

     

        <ToasterConsultantTemplateFill
          isOpen={showTemplateToaster}
          onClose={() => navigate('/compliance-tracker/compliance-tracker-management')}
          selectedSheFileName={selectedSheFileName}
          selectedSheFileId={selectedSheFileId}
          selectedSheFileScore={selectedSheFileScore}
          templates={templates}
          isLoading={isLoading}
    
          // Company and Departments
          selectedCompany={selectedCompanies[selectedSheFileId]} // Pass specific company
          selectedCompanyName={selectedCompanyName[selectedSheFileId]} 
          
          selectedDepartmentName={selectedDepartmentName[selectedSheFileId]} 
          selectedDepartment={selectedDepartments[selectedSheFileId]} // Pass specific department

          templateId={selectedTemplateId}      // She Pack Document Id
          templateName={selectedTemplateName}   // SHe Pack Document Name
          templateScore={selectedTemplateScore} // She Pack Document score
          templateUserPercentage={selectedTemplateUserPercentage}
          
          submissionStatus={submissionStatus}
          
        />

        

      

      

     
     
      
        
      


    </div>
  );
};

export default ConsultantTempateFillInComponent;