import React, { useState } from 'react';
import { FaSpinner } from 'react-icons/fa';

const QARejectToaster = ({ onClose, onSubmit, documentId }) => {
    const [rejectionReason, setRejectionReason] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = () => {
        // Validate rejection reason
        if (!rejectionReason.trim()) {
            setErrorMessage('Please provide a reason for rejection');
            return;
        }

        setIsSubmitting(true);
        setErrorMessage('');
        
        // Call the parent's onSubmit function, passing the rejection reason and document ID
        onSubmit(rejectionReason, documentId)
            .then(() => {
                setSuccessMessage('QA Review Successfully Rejected');
                // Add a delay before closing the toaster to allow the user to see the success message
                setTimeout(() => {
                    onClose();
                }, 2000);
            })
            .catch(error => {
                setErrorMessage(error.message || 'Failed to submit rejection');
                setIsSubmitting(false);
            });
    };

    return (
        <div className='checklist-question-user-fill-in-checklist-main-container'>
            <div className="qa-reject-main-container">
                <div className="template-fill-in-elements-container">
                    <div className="user-fill-in-back-button-time-container">
                        {/* QA Header */}
                        <div className='user-fill-in-back-button-container'>
                            <div>
                                <h3>QA View - Reject Submission</h3>
                                <p>Please provide a detailed explanation for rejecting the submission.</p>
                            </div>
                        </div>
                    </div>

                    

                    {/* Body */}
                    <div>
                        <div className='qa-reject-text-area-container'>
                            <textarea
                                placeholder='Type the explanation here'
                                className='qa-reject-text-area'
                                value={rejectionReason}
                                onChange={(e) => setRejectionReason(e.target.value)}
                                rows={6}
                                disabled={isSubmitting}
                            />
                        </div>
                    </div>

                    {/* Footer */}
                    <div>
                        <div>
                            <div>
                                {errorMessage && (
                                    <div className='error-text'>
                                        <p>{errorMessage}</p>
                                    </div>
                                )}

                                {successMessage && (
                                    <div className='success-message'>
                                        <p>{successMessage}</p>
                                    </div>
                                )}
                                
                                
                                <p>By clicking 'Confirm,' you acknowledge that you are submitting the reason for rejecting this submission during the QA process.</p>
                                            
                                <div className='checklist-question-toaster-button-cancel-container'>
                                    <button 
                                        onClick={handleSubmit} 
                                        className='confirm-button'
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? (
                                            <>
                                                <FaSpinner className="fa-spin" />
                                                <span className="submitting-questions-text"> Processing...</span>
                                            </>
                                        ) : (
                                            'Confirm'
                                        )}
                                    </button>
                                            
                                    <button 
                                        onClick={onClose} 
                                        type='button' 
                                        className='cancel-button'
                                        disabled={isSubmitting}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QARejectToaster;