// ------------------ React Components ------------------------
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CiImageOn, CiSearch } from "react-icons/ci";
import { LiaSpinnerSolid } from "react-icons/lia";
import { MdDeleteOutline } from "react-icons/md";
import { FaRegSave, FaClipboardList, FaSpinner } from 'react-icons/fa';
import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png';
import ItemAddedToaster from '../../toasterscomponents/checklistToasters/ItemAddedToaster';
import FilledInChecklistToaster from '../../toasterscomponents/FilledInChecklistToaster';
import CreatedSuccesFullyToaster from '../../toasterscomponents/shepacktoasters/CreatedSuccesFullyToaster';

const TemplateFillInToaster = ({ 
  isOpen, 
  onClose, 
  selectedShePackName,
  shePackDocuments,
  isLoading,
  questionsPerDocument
}) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const [showToaster, setShowToaster] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [answers, setAnswers] = useState({});
  const [previewPhoto, setPreviewPhoto] = useState(null);
  const [isPreviewExpanded, setIsPreviewExpanded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [questionErrors, setQuestionErrors] = useState({});
  const [questionCategoryErrors, setQuestionCategoryErrors] = useState({});
  const [expiryDateInput, setExpiryDateInput] = useState({});
  const [expandedDocuments, setExpandedDocuments] = useState({});
  const [comments, setComments] = useState({});
  const [questionScores, setQuestionScores] = useState({});

  const user = JSON.parse(sessionStorage.getItem('user'));

  
  useEffect(() => {
    // Initialize previous responses when questions are loaded
    if (questionsPerDocument) {
      Object.entries(questionsPerDocument).forEach(([documentId, categories]) => {
        Object.values(categories).forEach(questions => {
          questions.forEach(question => {
            // Handle previous comments
            if (question.previous_comment) {
              setComments(prev => ({
                ...prev,
                [question.question_id]: question.previous_comment
              }));
            }

            // Handle previous scores
          if (question.previous_user_question_score !== undefined) {
            setQuestionScores(prev => ({
              ...prev,
              [question.question_id]: question.previous_user_question_score
            }));
          }

            // Handle previous file responses

            /*
            if (question.previous_file_response) {
              setPreviousFiles(prev => ({
                ...prev,
                [question.question_id]: question.previous_file_response
              }));
            } */
          });
        });
      });
    }
  }, [questionsPerDocument]); 

  
  useEffect(() => {
    const timers = Object.keys(showToaster).map(key => showToaster[key] && setTimeout(() => setShowToaster(prev => ({ ...prev, [key]: false })), 3000));
    return () => timers.forEach(timer => clearTimeout(timer));
  }, [showToaster]);

  const toggleDocument = (documentId) => {
    setExpandedDocuments(prev => ({ ...prev, [documentId]: !prev[documentId] }));
  };

  const handleAnswerChange = (questionId, answer, questionType) => {
    setAnswers(prev => {
      if (questionType === 'Multiple Select') {
        const currentAnswers = Array.isArray(prev[questionId]) ? prev[questionId] : [];
        return { ...prev, [questionId]: currentAnswers.includes(answer) ? currentAnswers.filter(item => item !== answer) : [...currentAnswers, answer] };
      } else {
        return { ...prev, [questionId]: answer };
      }
    });
  };

  const handleFileChange = (questionId, event) => {
    const file = event.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'application/pdf') && file.size <= 10 * 1024 * 1024) {
      handleAnswerChange(questionId, file);
      setPreviewPhoto(file.type.startsWith('image/') ? URL.createObjectURL(file) : null);
    } else {
      setErrorMessage('Only PNG, JPEG, and PDF files less than 10MB are allowed.');
    }
  };

  const handleRemoveFile = (questionId) => {
    handleAnswerChange(questionId, '');
    setPreviewPhoto(null);
    document.querySelectorAll('input[type="file"]').forEach(input => input.value = '');
  };

  const handleCommentChange = (questionId, comment) => {
    setComments(prev => ({
      ...prev,
      [questionId]: comment
    }));
  };


  const handleIsExpiryDateChange = (questionId, isExpiryDate) => {
    setExpiryDateInput(prev => ({ ...prev, [questionId]: isExpiryDate }));
  };

  const handleScoreChange = (questionId, score) => {
    setQuestionScores(prev => ({
      ...prev,
      [questionId]: score === 'Not Applicable' ? null : score
    }));
  };

  const generateScoreOptions = (maxScore) => {
    const options = [<option key="not-selected" value="Not Selected">Not Selected</option>];
    for (let i = 0; i <= maxScore; i += 5) {
      options.push(<option key={i} value={i.toString()}>{i}</option>);
    }
    options.push(<option key="not-applicable" value="Not Applicable">Not Applicable</option>);
    return options;
  };

  const isOptionSelected = (questionId, option) => {
    const answer = answers[questionId];
    return Array.isArray(answer) ? answer.includes(option) : answer === option;
  };

  const handleSaveIndividualQuestion = async (questionId) => {

    setErrorMessage('');
    setSubmitStatus('loading');
    //
    // Validate that all required questions have scores
    // Validate score
    const score = questionScores[questionId];
    if (score === 'Not Selected') {
      setQuestionErrors(prev => ({ 
        ...prev, 
        [questionId]: 'Please select a score or mark as Not Applicable' 
      }));
      setSubmitStatus('idle');
      return;
    }
    
  

    let question = null;
    let documentId = null;

    for (const docId in questionsPerDocument) {
      const documentQuestions = questionsPerDocument[docId];
      for (const category in documentQuestions) {
        const questionsInCategory = documentQuestions[category];
        const foundQuestion = questionsInCategory.find(q => q.question_id === questionId);
        if (foundQuestion) {
          question = foundQuestion;
          documentId = docId;
          break;
        }
      }
      if (question) break;
    }
    if (!question) {
      setQuestionErrors(prev => ({ ...prev, [questionId]: 'Error: Question not found in the document' }));
      setSubmitStatus('idle');
      return;
    }
    try {

      const formData = new FormData();
      const score = questionScores[questionId];
      const answer = answers[questionId];
      const currentDocument = shePackDocuments.find(doc => doc.shepack_document_id === documentId);

      if (!currentDocument) throw new Error('Document not found');

      formData.append('userId', user.user_id);
      formData.append('userName', user.userName);
      formData.append('userSurname', user.userSurname);
      formData.append('userType', user.userType);
      formData.append('companyId', user.companyId);
      formData.append('departmentId', user.department_id);

      formData.append('shePackDocumentId', documentId);
      formData.append('shePackDocumentName', currentDocument.shepack_document_name);
      formData.append('userTotalScore', score);
      formData.append('shepackDocumentscore', currentDocument.shepack_document_score);
      formData.append('questionId', questionId);
      formData.append('questionName', question.question_name);
      formData.append('questionScore', score === null ? 'Not Applicable' : score);
      formData.append('category', question.category);
      formData.append('questionType', question.question_type);


      
      if (question.question_type === 'Multiple Select') {
        formData.append('answer', Array.isArray(answer) ? answer.join(', ') : answer || '');
      } else if ((question.question_type === 'File' || question.question_type === 'Photo') && answer instanceof File) {
        formData.append(`files[${questionId}]`, answer);
        formData.append('answer', answer.name);
      } else {
        formData.append('answer', answer || '');
      }
      if (comments[questionId]) formData.append('comment', comments[questionId]);
      if (expiryDateInput[questionId]) formData.append('expiry_date', expiryDateInput[questionId]);
      const response = await axios.post('/api/she-pack/fill-in-she-pack/save-individual-question-bank-response', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      setQuestionErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[questionId];
        return newErrors;
      });
      setShowToaster(prev => ({ ...prev, questionSaved: true }));
      setSubmitStatus('success');
    } catch (error) {
      setSubmitStatus('idle');
      const errorMsg = error.response 
        ? `Server error: ${error.response.data.message}` 
        : error.request 
          ? 'No response from server. Please check your connection.' 
          : `Error: ${error.message}`;
      setQuestionErrors(prev => ({ ...prev, [questionId]: errorMsg }));
    }
  };

  const handleSaveCategory = async (category, documentId) => {
    console.log('Starting category save process...', category);
    console.log('Starting category save process...', category, 'Document ID:', documentId);
    setSubmitStatus('loading');

    // Get all questions for this category
  const categoryQuestions = questionsPerDocument[documentId][category];

  // Validate that all required questions have scores
  // Validate that all questions have valid scores
  const invalidScoreQuestions = categoryQuestions.filter(question => {
    const score = questionScores[question.question_id];
    return (score === 'Not Selected');
  });

  if (invalidScoreQuestions.length > 0) {
    const validationErrors = invalidScoreQuestions.map(question => 
      `Please select a score or mark as Not Applicable for question: ${question.question_name}`
    );

    setQuestionCategoryErrors(prev => ({
      ...prev,
      [category]: validationErrors
    }));
    setSubmitStatus('idle');
    return;
  }

    try {
        // Get all questions for this category
        const categoryQuestions = questionsPerDocument[documentId][category];

        // Find the document details from shePackDocuments array
        const currentDocument = shePackDocuments.find(doc => doc.shepack_document_id === documentId);
        
        if (!currentDocument) {
            throw new Error('Document not found');
        }
        
        // Prepare answers array for all questions in the category
        const categoryAnswers = categoryQuestions.map(question => ({
            questionId: question.question_id,
            questionName: question.question_name,
            questionType: question.question_type,
            category: category,
            answer: answers[question.question_id] || '',
            questionScore: questionScores[question.question_id],
            comment: comments[question.question_id],
            expiry_date: expiryDateInput[question.question_id]
        }));

        // Validate that all required questions have scores
        

        // Prepare form data
        const formData = new FormData();
        formData.append('answers', JSON.stringify(categoryAnswers));
       
        // Use the document ID from the current document
        formData.append('shePackDocumentId', documentId);
        formData.append('shePackDocumentName', currentDocument.shepack_document_name);
        formData.append('userId', user.user_id);
        formData.append('userName', user.userName);
        formData.append('userSurname', user.userSurname);
        formData.append('userType', user.userType);
        formData.append('companyId', user.companyId);
        formData.append('departmentId', user.department_id);
        
        formData.append('userTotalScore', currentDocument.user_score || 0);
        formData.append('shepackDocumentscore', currentDocument.shepack_document_score || 0);
        formData.append('totalUserScorePercentage', currentDocument.user_percentage || 0);
        
        console.log('Sending form data:', {
            documentId,
            documentName: currentDocument.shepack_document_name,
            category,
            answersCount: categoryAnswers.length
        });

        // Append files if any
        categoryQuestions.forEach(question => {
            const answer = answers[question.question_id];
            if ((question.question_type === 'File' || question.question_type === 'Photo') && answer instanceof File) {
                formData.append(`files[${question.question_id}]`, answer);
            }
        });

        const response = await axios.post(
            '/api/she-pack/fill-in-she-pack/save-category-question-bank-response',
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data' }
            }
        );

        console.log('Category save response:', response.data);

        // Clear any existing errors for this category
        setQuestionCategoryErrors(prev => {
            const newErrors = { ...prev };
            delete newErrors[category];
            return newErrors;
        });

        setQuestionErrors(prev => {
            const newErrors = { ...prev };
            delete newErrors[documentId];
            return newErrors;
        });

        setShowToaster(prev => ({ ...prev, categorySaved: true }));
        setSubmitStatus('success');

    } catch (error) {
        console.error('Save category error:', error);
        setQuestionCategoryErrors(prev => ({
            ...prev,
            [category]: [`Error saving category: ${error.response?.data?.message || error.message}`]
        }));
        setSubmitStatus('idle');
    }
};

  const handleSubmit = async (documentId) => {
    setErrorMessage('');
    setSubmitStatus('loading');

    const currentDocument = shePackDocuments.find(doc => doc.shepack_document_id === documentId);
  if (!currentDocument) {
    setErrorMessage('Document not found');
    setSubmitStatus('idle');
    return;
  }

  const documentQuestions = questionsPerDocument[documentId];
  if (!documentQuestions) {
    setErrorMessage('No questions found for this document');
    setSubmitStatus('idle');
    return;
  }

   // Validate all questions have valid scores
   const invalidScores = [];
   Object.entries(documentQuestions).forEach(([category, questions]) => {
     questions.forEach(question => {
       const score = questionScores[question.question_id];
       if (score === 'Not Selected') {
         invalidScores.push(question.question_name);
       }
     });
   });
 
   if (invalidScores.length > 0) {
     setSubmitStatus('error');
     setErrorMessage(`Please select a score or mark as Not Applicable for the following questions: ${invalidScores.join(', ')}`);
     return;
   }
  
    try {
      const currentDocument = shePackDocuments.find(doc => doc.shepack_document_id === documentId);
      if (!currentDocument) throw new Error('Document not found');
      const documentQuestions = questionsPerDocument[documentId];
      if (!documentQuestions) throw new Error('No questions found for this document');
      const unansweredMandatoryQuestions = [];
      Object.entries(documentQuestions).forEach(([category, questions]) => {
        questions.forEach(question => {
          if (question.is_mandatory === 'Yes' && !answers[question.question_id]) {
            unansweredMandatoryQuestions.push(question.question_name);
          }
        });
      });
      if (unansweredMandatoryQuestions.length > 0) {
        setSubmitStatus('error');
        setErrorMessage(`Please answer all mandatory questions: ${unansweredMandatoryQuestions.join(', ')}`);
        return;
      }
      const formData = new FormData();
      formData.append('shePackDocumentId', documentId);
      formData.append('shePackDocumentName', currentDocument.shepack_document_name);
      formData.append('userId', user.user_id);
      formData.append('userName', user.userName);
      formData.append('userSurname', user.userSurname);
      formData.append('userType', user.userType);
      formData.append('companyId', user.companyId);
      formData.append('departmentId', user.department_id);

      formData.append('userTotalScore', currentDocument.user_score || '0');
      formData.append('shepackDocumentscore', currentDocument.shepack_document_score || '0');
      formData.append('totalUserScorePercentage', currentDocument.user_percentage || '0');
      const allAnswers = [];
      Object.entries(documentQuestions).forEach(([category, questions]) => {
        questions.forEach(question => {
          const answer = answers[question.question_id];
          const answerData = {
            questionId: question.question_id,
            questionName: question.question_name,
            questionType: question.question_type,
            category: category,
            answer: '',
            questionScore: questionScores[question.question_id],
            comment: comments[question.question_id] || '',
            expiry_date: expiryDateInput[question.question_id] || ''
          };
          if (question.question_type === 'Multiple Select') {
            answerData.answer = Array.isArray(answer) ? answer.join(', ') : answer || '';
          } else if ((question.question_type === 'File' || question.question_type === 'Photo') && answer instanceof File) {
            formData.append(`files[${question.question_id}]`, answer);
            answerData.answer = answer.name;
          } else {
            answerData.answer = answer || '';
          }
          allAnswers.push(answerData);
        });
      });
      formData.append('answers', JSON.stringify(allAnswers));
      await axios.post('/api/she-pack/fill-in-she-pack/submit-all-question-response', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setSubmitStatus('success');
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        navigate('/she-pack/she-pack-management');
      }, 2000);
    } catch (error) {
      setSubmitStatus('idle');
      const errorMsg = error.response?.data?.message 
        ? `Server error: ${error.response.data.message}`
        : error.request 
          ? 'No response from server. Please check your connection.'
          : `Error: ${error.message}`;
      setErrorMessage(errorMsg);
    }
  };

  const renderQuestionOptions = (question) => {
    const options = ['option_1', 'option_2', 'option_3', 'option_4', 'option_5', 'option_6', 'option_7', 'option_8', 'option_9', 'option_10', 'option_11', 'option_12', 'option_13', 'option_14', 'option_15'];
    switch (question.question_type) {
      
     
      case 'File':
      case 'Photo':
        return (
          <div className='file-upload-container'>
            <p className='secondary-color-paragraph'>Please upload a {question.question_type}. File should not exceed 10MB</p>
            <input
              className='file-upload-input'
              type="file"
              accept={question.question_type === 'File' ? ".png,.jpg,.jpeg,.pdf" : "image/png, image/jpeg"}
              onChange={(e) => handleFileChange(question.question_id, e)}
            />
            {answers[question.question_id] && (
              <div>
                {answers[question.question_id].type.startsWith('image/') && (
                  <div className='checklist-question-toaster-image-delete-button-container'>
                    <CiImageOn className='checklist-question-toaster-image-icon' onClick={() => setIsPreviewExpanded(!isPreviewExpanded)} />
                    <button type='button' className='delete-image-button' onClick={() => handleRemoveFile(question.question_id)}> <MdDeleteOutline /> </button>
                  </div>
                )}
                {isPreviewExpanded && previewPhoto && (
                  <img onClick={() => setIsPreviewExpanded(false)} src={previewPhoto} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                )}
              </div>
            )}
          </div>
        );
      case 'Short Text':
        return (
          <div className='file-upload-container'>
            <input
              className="create-questions-form-name-input"
              type="text"
              value={answers[question.question_id] || ''}
              onChange={(e) => handleAnswerChange(question.question_id, e.target.value)}
              placeholder="Please enter your response"
            />
          </div>
        );
      case 'Time':
        return (
          <div className='file-upload-container'>
            <input
              type="time"
              value={answers[question.question_id] || ''}
              onChange={(e) => handleAnswerChange(question.question_id, e.target.value)}
            />
          </div>
        );
      case 'Date':
        return (
          <div className='file-upload-container'>
            <input
              type="date"
              value={answers[question.question_id] || ''}
              onChange={(e) => handleAnswerChange(question.question_id, e.target.value)}
              max={new Date().toISOString().split('T')[0]}
              className='checklist-question-toaster-date'
            />
          </div>
        );
      case 'Drop Down':
        return (
          <div className='file-upload-container'>
            <select
              value={answers[question.question_id] || ''}
              onChange={(e) => handleAnswerChange(question.question_id, e.target.value)}
            >
              <option value="">Select an option</option>
              {options.map((option, optionIndex) => (
                question[option] && (
                  <option key={optionIndex} value={question[option]}>
                    {question[option]}
                  </option>
                )
              ))}
            </select>
          </div>
        );
      default:
        return null;
    }
  };

  if (!isOpen) return null;

  return (

    <div className="checklist-question-user-fill-in-checklist-main-container">
        
        <div className="template-question-fill-in-checklist-container">
        <div className="template-fill-in-elements-container">
          <div className="user-fill-in-back-button-time-container">
            <div className='user-fill-in-back-button-container'>
              <img src={blueKbcLogo} alt="white-kbc-logo" className="add-quesiton-side-menu-logo" />
              <button type="button" onClick={onClose} className="user-fill-in-back-button"> Back</button>
            </div>
          </div>
          
          <div className="Template-fill-in-header-main-container">
            <div className="Template-fill-in-header-text-and-search-main-container">
              <div className="Template-fill-in-header-text-main-container">
                <h1>Fill In Template</h1>
                <h3>Selected SHE File: <span style={{ color: 'black' }}>{selectedShePackName}</span></h3>
              </div>
              <div className="Template-fill-in-header-search-main-container">
                <div>
                  <h4 className='secondary-color-text'>Quick Questions Search</h4>
                  <div className="Template-fill-in-header-search-search-bar-container">
                    <input 
                      placeholder='Enter question name'
                      className='template-fill-in-search-bar'
                    />
                    <button className='confirm-button'>
                      <CiSearch />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {isLoading ? (
              <div className="loading-spinner-container">
                <LiaSpinnerSolid className="lia-spin-solid-icon" />
              </div>
            ) : (
              <div className='template-questions-user-fill-in-main-container'>
                <div className="template-table-main-container">
                  <div className='template-table-main-header-container'>
                    <div className='template-table-header-container'>
                      <div className="template-table-header">View Questions</div>
                      <div className="template-table-header">Document Name</div>
                      <div className="template-table-header">Document Score</div>
                      <div className="template-table-header">Percentage</div>
                      <div className="template-table-header">Status</div>
                    </div>
                  </div>
                  <div className='template-table-body'>
                    {shePackDocuments.length === 0 ? (
                      <div className='template-table-body-row-main-container'>
                        <div className='template-table-body-row-container'>
                          <i>No documents found for this SHE Pack.</i>
                        </div>
                      </div>
                    ) : (
                      shePackDocuments.map((document, index) => (
                        <div className='template-table-body-row-main-container' key={index}>
                          <div className='template-table-body-row-container'>

                            <div className='template-table-body-row-td'>
                              <FaClipboardList 
                                className='view-checklist-submition-icon' 
                                onClick={() => document.submission_status !== 'Completed' && toggleDocument(document.shepack_document_id)}
                                style={{
                                  cursor: document.submission_status === 'Completed' ? 'not-allowed' : 'pointer',
                                  opacity: document.submission_status === 'Completed' ? 0.5 : 1
                                }}
                              />
                            </div>


                            <div className='template-table-body-row-td'>{document.shepack_document_name}</div>
                            <div className='template-table-body-row-td'>{document.user_score || 0} / {document.shepack_document_score || 'No Score'}</div>
                            <div className='template-table-body-row-td'>{document.user_percentage || 0} %</div>
                            <div className='template-table-body-row-td'>{document.submission_status || 'No submission'}</div>
                          </div>
                          {expandedDocuments[document.shepack_document_id] && (
                            <div className='template-questions-fill-in-main-container'>

                              {questionsPerDocument[document.shepack_document_id] && 
                                Object.entries(questionsPerDocument[document.shepack_document_id]).map(([category, categoryQuestions]) => (
                                  <>  
                                    <div className='template-questions-fill-in-container' key={category}>
                                    <div className='checklist-question-toaster-user-fill-in-checklist-element-category-save-container'>
                                      <h2>Category {category}</h2>
                                      <div 
                                        className='save-questions-category-category-button-container'
                                        onClick={() => handleSaveCategory(category, document.shepack_document_id)}
                                      >
                                        <div>Save Category</div>
                                        <div>
                                          <FaRegSave className='checklist-fillin-button-question-name-and-save-icon-rendering-save-category-icon' />
                                        </div>
                                      </div>
                                    </div>
                                    {questionCategoryErrors[category] && (
                                      <div className='error-text'>
                                        <ul>
                                          {questionCategoryErrors[category].map((error, index) => (
                                            <li key={index} className='category-error-item'>{error}</li>
                                          ))}
                                        </ul>
                                      </div>
                                    )}
                                    <div className="checklist-question-toaster-user-fill-in-checklist-category-container">
                                      <div className='template-questions-fill-in-individal-questions-main-container'>
                                        {categoryQuestions.map((question, index) => (
                                          <div key={index} className='template-questions-fill-in-individal-questions-container'>
                                            <div className='template-questions-fill-in-individal-questions-container-container'>
                                              <div className='template-questions-fill-in-sections-errors-and-save-button-main-container'>
                                                <div>
                                                  <p style={{ color: 'red', fontSize: '12px' }}>* Mandatory Question</p>
                                                </div>
                                                <div 
                                                  className='save-questions-category-button-container' 
                                                  onClick={() => handleSaveIndividualQuestion(question.question_id)} 
                                                >
                                                  <div>Save Question</div>
                                                  <div>
                                                    <FaRegSave className='checklist-fillin-button-question-name-and-save-icon-rendering-save-category-icon' />
                                                  </div>
                                                </div>
                                              </div>
                                              {questionErrors[question.question_id] && (
                                                <div className='error-text'>
                                                  <p>{questionErrors[question.question_id]}</p>
                                                </div>
                                              )}
                                              <div className='template-questions-fill-in-sections-question-name-and-question-score-main-container'>
                                                <div className='template-questions-fill-in-sections-question-name-main-container'>
                                                  <div>
                                                    <h4>Question </h4>
                                                  </div>
                                                  <div>
                                                    <p>{question.question_name}</p>
                                                  </div>
                                                </div>
                                                <div className='template-questions-fill-in-sections-question-score-main-container'>
                                                  <div>
                                                    <h4 className='secondary-color-text'>Question Score </h4>
                                                  </div>
                                                  <div className='she-pack-fill-in-question-score-container'>
                                                    <div>
                                                      <select
                                                        value={questionScores[question.question_id] === null ? 'Not Applicable' : (questionScores[question.question_id] || 'Not Selected')}
                                                        onChange={(e) => handleScoreChange(question.question_id, e.target.value)}
                                                        className={questionErrors[question.question_id] ? 'error-border' : ''}
                                                      >
                                                        {generateScoreOptions(parseInt(question.question_score))}
                                                      </select>
                                                    </div>
                                                    <div>
                                                      <p>/{question.question_score}</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className='template-questions-fill-in-sections-additional-comment-main-main-container'>
                                                <div className='template-questions-fill-in-sections-additional-comment-additional-information-main-container'>
                                                  <div>
                                                    <div>
                                                      <h4>Additional Information</h4>
                                                      <p>Click on the expand button to supply additional information</p>
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <button 
                                                      className='confirm-button'
                                                      onClick={() => setIsExpanded(!isExpanded)}
                                                    >
                                                      {isExpanded ? 'Minimize' : 'Expand'}
                                                    </button>
                                                  </div>
                                                </div>
                                                {isExpanded && (
                                                  <div className='template-questions-fill-in-sections-additional-comment-main-container'>
                                                    <div className='template-questions-fill-in-sections-additional-comment-main-container'>
                                                      <div className='template-questions-fill-in-sections-additional-comment-container'> 
                                                        {question.comment === 'Yes' && (
                                                          <div>
                                                            <div className='secondary-color-paragraph'>
                                                              <p><i>Additional Comment : </i></p>
                                                            </div>
                                                            <div>
                                                              <textarea
                                                                placeholder='Type your additional comments here '
                                                                rows={2}
                                                                cols={40}
                                                                maxLength={200}
                                                                value={comments[question.question_id] || ''}
                                                                onChange={(e) => handleCommentChange(question.question_id, e.target.value)}
                                                              />
                                                            </div>
                                                            <div>
                                                              <p><i>Maximum characters allowed 200.</i></p>
                                                            </div>
                                                          </div>
                                                        )}
                                                      </div>
                                                      <div className='Template-file-upload-main-container'>
                                                        <p className='secondary-color-paragraph'>File Upload</p>
                                                        <p className='secondary-color-paragraph'>Please upload a {question.question_type}. File should not exceed 10MB</p>
                                                        <input
                                                          className='file-upload-input'
                                                          type="file"
                                                          accept={question.question_type === 'File' ? ".png,.jpg,.jpeg,.pdf" : "image/png, image/jpeg"}
                                                          onChange={(e) => handleFileChange(question.question_id, e)}
                                                        />
                                                        {answers[question.question_id] && (
                                                          <div>
                                                            {answers[question.question_id].type.startsWith('image/') && (
                                                              <div className='checklist-question-toaster-image-delete-button-container'>
                                                                <CiImageOn className='checklist-question-toaster-image-icon' onClick={() => setIsPreviewExpanded(!isPreviewExpanded)} />
                                                                <button type='button' className='delete-image-button' onClick={() => handleRemoveFile(question.question_id)}> <MdDeleteOutline /> </button>
                                                              </div>
                                                            )}
                                                            {isPreviewExpanded && previewPhoto && (
                                                              <img onClick={() => setIsPreviewExpanded(false)} src={previewPhoto} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                                                            )}
                                                          </div>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                              <div className='template-questions-fill-in-individal-questions-header-container-container'>
                                                <p className='confidential'>{question.checklist_id}</p>
                                                <p className='confidential'>{question.question_id}</p>
                                              </div>
                                              <div className='classNamechecklist-question-toaster-user-fill-in-checklist-question-element-options-container'>
                                                {renderQuestionOptions(question)}
                                              </div>
                                              {question.expires === 'Yes' && (
                                                <div>
                                                  <div className='secondary-color-paragraph'>
                                                    <p><i>If applicable. Choose an expiry Date : </i></p>
                                                  </div>
                                                  <div>
                                                    <input
                                                      type="date"
                                                      onChange={(e) => handleIsExpiryDateChange(question.question_id, e.target.value)}
                                                      value={expiryDateInput[question.question_id] || ''}
                                                    />
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>

                                  <div>
                                {errorMessage && (
                                  <div className='error-text'>
                                    <p>{errorMessage}</p>
                                  </div>
                                )}
                                <p>By Clicking Submit you agree that the information provided is true</p>
                                <div className='checklist-question-toaster-button-cancel-container'>
                                  <button 
                                    onClick={() => handleSubmit(document.shepack_document_id)}
                                    className="confirm-button" disabled={submitStatus === 'loading'}>
                                    {submitStatus === 'loading' ? (
                                      <>
                                        <FaSpinner className="fa-spin" />
                                        <span className="submitting-questions-text"> Submitting questions...</span>
                                      </>
                                    ) : (
                                      'Submit'
                                    )}
                                  </button>
                                  <button type='button' className='cancel-button'> Clear</button>
                                </div>
                              </div>
                                  
                                  
                                  </>

                                  
                                ))}
                              
                            </div>
                          )}
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {showToaster.questionSaved && <ItemAddedToaster item={'Question Saved'} />}
      {showToaster.categorySaved && <ItemAddedToaster item={'Category Saved'} />}

      {showSuccess && 
        
        <CreatedSuccesFullyToaster
          heading='SHE File Filled Successfully'
        />
      }

        
    </div>
  );
};

export default TemplateFillInToaster;
