import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Import icons and assets
import { MdDeleteOutline } from "react-icons/md";
import { FaSpinner } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { MdOutlineCancel } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png';

// Import components
import ToasterChecklistCreated from "../../toasterscomponents/ToasterChecklistCreated.js";
import API_BASE_URL from "../../utils/api/apiConfig.js";

// Toaster 
import CreatedSuccessfullToaster from "../../toasterscomponents/manageusersToaster/createdSuccessfullToaster";

const AddDepartmentComponent = () => {

    
    // Retrieve user data from session storage
    const user = JSON.parse(sessionStorage.getItem('user'));


    //
    const navigate = useNavigate();
    const [viewSection, setViewSection] = useState({ A: true, B: false });
    
    // Form state
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState("");
    const [selectedCompanyName, setSelectedCompanyName] = useState("");
    const [departmentName, setDepartmentName] = useState("");
    const [submitStatus, setSubmitStatus] = useState('idle');
    const [errors, setErrors] = useState([]);
    const [showToaster, setShowToaster] = useState({ success: false });

    // Fetch companies on component mount
    useEffect(() => {
        fetchCompanies();
    }, []);

    // Handle toaster timeout
    useEffect(() => {
        const timers = Object.keys(showToaster).map(key => 
            showToaster[key] && setTimeout(() => 
                setShowToaster(prev => ({ ...prev, [key]: false })), 3000)
        );
        return () => timers.forEach(timer => clearTimeout(timer));
    }, [showToaster]);

    const fetchCompanies = async () => {
        try {
            const response = await fetch('/api/companies');
            const data = await response.json();
            if (Array.isArray(data)) {
                setCompanies(data);
            } else {
                console.error('Invalid data format for companies:', data);
            }
        } catch (error) {
            console.error('Error fetching companies:', error);
            setErrors(["Error loading companies"]);
        }
    };

    const handleCompanyChange = (e) => {
        const companyId = e.target.value;
        setSelectedCompany(companyId);
        const company = companies.find(c => c.company_id === companyId);
        setSelectedCompanyName(company ? company.companyName : "");
    };

    const handleClear = () => {
        setSelectedCompany("");
        setSelectedCompanyName("");
        setDepartmentName("");
        setErrors([]);
        setSubmitStatus('idle');
    };

    const handleSubmit = async () => {
        setSubmitStatus('loading');
        setErrors([]);
        let newErrors = [];

        // Validation
        if (!selectedCompany) newErrors.push("Error - Please select a company.");
        if (!departmentName.trim()) newErrors.push("Error - Department name cannot be empty.");

        if (newErrors.length > 0) {
            setErrors(newErrors);
            setSubmitStatus('error');
            return;
        }

        try {
            const response = await axios.post(`${API_BASE_URL}/api/people-management/create-department`, {
                department_name: departmentName,
                company_id: selectedCompany,
                company_name: selectedCompanyName,
                created_by: user.user_id
            });

            console.log('Department created successfully:', response.data);
            setShowToaster(prev => ({ ...prev, success: true }));
            setSubmitStatus('success');
            
            // Navigate after success
            setTimeout(() => {
                setShowToaster(prev => ({ ...prev, success: false }));
                navigate('/managerusers');
            }, 2000);

        } catch (error) {

            console.error('Error creating department:', error);

            // Display the error message from the server
            const errorMessage = error.response?.data?.message || 'An unexpected error occurred. Please try again.';
            setErrors([errorMessage]);
            setSubmitStatus('error');
        }
    };

    return (
        <div className="add-question-custom-field-main-component-container">
            <div className="add-question-create-questions-checklist-custom-field-component-container">
                <div className="create-questions-custom-field-container">
                    <div className="add-question-view-checklist-component-container">
                        <div className="add-question-view-checklist-header-container">
                            <div>
                                <img src={blueKbcLogo} alt="white-kbc-logo" className="add-quesiton-side-menu-logo" />
                            </div>
                            <div className="navigate-back-button-container">
                                <button className="navigate-back-button" onClick={() => navigate('/managerusers')}>
                                    Back
                                </button>
                            </div>
                        </div>

                        <div className="add-question-view-checklist-header-step-container">
                            <div>
                                <h2>Create Department</h2>
                                <p>To create department for an existing company.</p>
                                <p className="secondary-color-paragraph">
                                    To complete each section, click on the 'Maximize' button to expand the section.
                                </p>
                            </div>
                        </div>

                        {/* Section A - Company Information */}
                        <div className="create-checklist-section-a-information-main-container">
                            <div className="create-checklist-section-container-main-container">
                                <div className="create-checklist-section-container">
                                    <div>
                                        <h3>Section A - Company Information</h3>
                                    </div>
                                    <div>
                                        <button 
                                            className="confirm-button" 
                                            onClick={() => setViewSection(prev => ({ ...prev, A: !prev.A }))}
                                        >
                                            {viewSection.A ? 'Minimize' : 'Maximize'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            
                            {viewSection.A && (
                                <div className="create-checklist-section-a-information-container">
                                    <div className="create-checklist-section-a-information-container-container">
                                        <div>
                                            <h3 className="category-secondary-color">Company Details</h3>
                                            <p>Please choose name of company that you would like to create a department for.</p>
                                        </div>
                                    </div>
                                    <div className="create-checklist-section-a-information-container-container">
                                        <div className="create-checklist-section-a-information-checklist-name-container">
                                            <h4>Company Name</h4>
                                            <select
                                                value={selectedCompany}
                                                onChange={handleCompanyChange}
                                            >
                                                <option value="">-- Select Company --</option>
                                                {companies.map(company => (
                                                    <option key={company.company_id} value={company.company_id}>
                                                        {company.companyName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Section B - Department Information */}
                        <div className="create-checklist-section-a-information-main-container">
                            <div className="create-checklist-section-container-main-container">
                                <div className="create-checklist-section-container">
                                    <div>
                                        <h3>Section B - Department Information</h3>
                                    </div>
                                    <div>
                                        <button 
                                            className="confirm-button" 
                                            onClick={() => setViewSection(prev => ({ ...prev, B: !prev.B }))}
                                        >
                                            {viewSection.B ? 'Minimize' : 'Maximize'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            
                            {viewSection.B && (
                                <div className="create-checklist-section-a-information-container">
                                    <div className="create-checklist-section-a-information-container-container">
                                        <div>
                                            <h3 className="category-secondary-color">Department Details</h3>
                                            <p>Please enter name of the department that you are creating.</p>
                                        </div>
                                    </div>
                                    <div className="create-checklist-section-a-information-container-container">
                                        <div className="create-checklist-section-a-information-checklist-name-container">
                                            <div>
                                                <h4>Department Name</h4>
                                            </div>
                                            <div>
                                                <input
                                                    value={departmentName}
                                                    onChange={(e) => setDepartmentName(e.target.value)}
                                                    placeholder="Enter Department Name"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="add-department-button-text-container">
                            {errors.length > 0 && (
                                <div className="error-text">
                                    <ul>
                                        {errors.map((error, index) => (
                                            <li key={index}>{error}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}

                            <div>
                                <p>By clicking 'Create Department' button, department will be created</p>
                            </div>

                            <div className="add-department-button-container">
                                <div>
                                    <button 
                                        className="confirm-button"
                                        onClick={handleSubmit}
                                        disabled={submitStatus === 'loading'}
                                    >
                                        {submitStatus === 'loading' ? (
                                            <FaSpinner className="spinner" />
                                        ) : 'Create Department'}
                                    </button>
                                </div>
                                <div>
                                    <button className="cancel-button" onClick={handleClear}>
                                        Clear
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showToaster.success && 
             
             <CreatedSuccessfullToaster 
                successToasterTitle="Created Successfully" 
                successToasterMessage="Department created successfully. One moment while we redirect you to view users page"
            />
            
            }
        </div>
    );
};

export default AddDepartmentComponent;