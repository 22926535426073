import React, { useState } from "react";
import axios from "axios";

import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png'; 

import { LiaSpinnerSolid } from "react-icons/lia";

import '../../../assets/css/Toasters.css';

const EditPowerBiReportLink = ({ report, onSave, onCancel , onDelete  }) => {

  const [editedReport, setEditedReport] = useState(report);

  
  const [inputErrors, setInputErrors] = useState({});

  
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedReport({ ...editedReport, [name]: value });
    // Clear the error for this field when the user starts typing
    setInputErrors({ ...inputErrors, [name]: '' });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateInputs()) {
      setError('Please fill in all required fields.');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
    

      setIsLoading(false);
      onSave(editedReport);
    } catch (error) {
      setIsLoading(false);
      setError('Error updating Power BI report. Please try again.');
      console.error('Error updating Power BI report:', error);
    }
  };

  const validateInputs = () => {
    const errors = {};
    if (!editedReport.power_bi_name.trim()) {
      errors.power_bi_name = 'Power BI Report Name cannot be empty';
    }
    if (!editedReport.power_bi_link.trim()) {
      errors.power_bi_link = 'Power BI Report Link cannot be empty';
    }
    setInputErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this Power BI report?")) {
      setIsLoading(true);
      setError(null);

      try {
        await axios.delete(`/api/rows/delete-analytics/power-bi-report/${editedReport.power_bi_id}`);
        setIsLoading(false);
        onDelete(editedReport.power_bi_id);
      } catch (error) {
        setIsLoading(false);
        setError('Error deleting Power BI report. Please try again.');
        console.error('Error deleting Power BI report:', error);
      }
    }
  };

  return (
    <div className="login-toaster-component-container">

      <div className="edit-power-bit-toaster-container">

        <div className="edit-power-bit-toaster-header-container">

            <div>
                <h2>Edit Power BI Repor Link</h2>
            </div>

            <div>
                <img src={blueKbcLogo} alt="white-kbc-logo" className="add-quesiton-side-menu-logo" />
            </div>

        </div>

        <div className="edit-power-bit-toaster-form-container">
            <form onSubmit={handleSubmit}>

                <div className="edit-power-bit-toaster-form-id-container">
                    <p>{editedReport.power_bi_id}</p>
                </div>

                <div>
                    <p>Enter the details in the input field to edit the MS Power BI report information. </p>
                </div>
                

                <div className="edit-power-bit-toaster-form-element-container">

                    <div>
                        <label htmlFor="power_bi_name">Power BI Report Name:</label>
                    </div>

                    <div>

                        <input
                            id="power_bi_name"
                            name="power_bi_name"
                            value={editedReport.power_bi_name}
                            onChange={handleInputChange}
                            placeholder="Enter Power BI Report Name"
                            className="edit-power-bit-toaster-link-input"
                        />

                    </div>

                    <div>
                        {inputErrors.power_bi_name && <p className="error-text">{inputErrors.power_bi_name}</p>}
                    </div>
                    
                    
                </div>

                <div className='edit-power-bit-toaster-form-element-container'>

                    <div>
                        <label htmlFor="power_bi_link">Power BI Report Link:</label>
                    </div>

                    <div>

                        <input
                            id="power_bi_link"
                            name="power_bi_link"
                            value={editedReport.power_bi_link}
                            onChange={handleInputChange}
                            placeholder="Enter Power BI Report Link"
                            className="edit-power-bit-toaster-link-input"
                        />

                    </div>

                    <div>
                        {inputErrors.power_bi_link && <p className="error-text">{inputErrors.power_bi_link}</p>}
                    </div>
                    
                    
                </div>

                <div>
                    <p>Clicking 'Save' will apply the changes, and they cannot be undone.</p>
                </div>

                <div>
                    {error && <p className="error-message">{error}</p>}
                </div>
                
                <div className="edit-power-bit-toaster-button-container">

                    <div>
                    <   button className='confirm-button' type="submit" disabled={isLoading} onClick={handleDelete}>
                            {isLoading ? <LiaSpinnerSolid className="spinner" /> : 'Save'}
                        </button>
                    </div>
                    

                    <div>
                    <   button className='cancel-button' type="button" onClick={onCancel} disabled={isLoading}>Cancel</button>
                    </div>
                    
                </div>
            </form>

        </div>

        

        
        
      </div>
    </div>
  );
};

export default EditPowerBiReportLink;