/* ----------- API Config file -------------

    - This file consisits of the domiains that the applicaiton connects to on different enviormnets */

//const API_BASE_URL = 'https://sosdev.devkbcsafety.com'; // staging url

const API_BASE_URL = 'https://sosstaging.devkbcsafety.com'; // development URL 

//const API_BASE_URL = 'https://sosproduction.devkbcsafety.com'; // development URL 


//const API_BASE_URL = 'http://localhost:5000'; // local host

export default API_BASE_URL;