import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CiImageOn } from "react-icons/ci";
import { LiaSpinnerSolid } from "react-icons/lia";
import { MdDeleteOutline } from "react-icons/md";
import { FaRegSave, FaClipboardList, FaSpinner } from 'react-icons/fa';
import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png';
import ItemAddedToaster from '../../toasterscomponents/checklistToasters/ItemAddedToaster';
import FilledInChecklistToaster from '../../toasterscomponents/FilledInChecklistToaster';
import CreatedSuccesFullyToaster from '../../toasterscomponents/shepacktoasters/CreatedSuccesFullyToaster';

const ToasterConsultantTemplateFill = ({ 
  isOpen, 
  onClose, 
  selectedSheFileId,
  selectedSheFileName,
  selectedSheFileScore,
  templates,
  isLoading,
  selectedCompany,
  selectedCompanyName,
  selectedDepartmentName,
  selectedDepartment
}) => {

  const navigate = useNavigate();

  // Company Name and Department Name 
 
  const [showToaster, setShowToaster] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [expandedDocuments, setExpandedDocuments] = useState({});

  const [expandedPreviewPhotos, setExpandedPreviewPhotos] = useState({});

  // State for file handling
  const [files, setFiles] = useState({});
  const [previewPhotos, setPreviewPhotos] = useState({});

  // Add this state to track the full-screen image
const [fullScreenImage, setFullScreenImage] = useState(null);

// New state to track which template is in manual upload mode
const [manualUploadTemplate, setManualUploadTemplate] = useState(null);

// ---------------- File Uploads -----------------
const [userScore, setUserScore] = useState({});

const [userTemplateScore, setUserTemplateScore] = useState({});
const [userTemplatePercentage, setUserTemplatePercentage] = useState({});


// ---------------- Employees ---------------------

// Add this state to store the employee data
const [employees, setEmployees] = useState([]);
const [loadingEmployees, setLoadingEmployees] = useState(false);

const [totalEmployeeScore, setTotalEmployeeScore] = useState(0);
const [savedEmployeeScores, setSavedEmployeeScores] = useState({});


const [errorMessage, setErrorMessage] = useState('');

const user = JSON.parse(sessionStorage.getItem('user'));

/*-----------------------------------------------------------------
                            Use Effects 
  ---------------------------------------------------------------*/

  // Add this useEffect to recalculate total when files are removed
useEffect(() => {
  if (manualUploadTemplate && manualUploadTemplate.template_reference === "Medical Certificate") {
    const newTotal = Object.values(savedEmployeeScores).reduce((sum, score) => sum + parseFloat(score), 0);
    setTotalEmployeeScore(newTotal);
  }
}, [savedEmployeeScores]);

useEffect(() => {
  // Calculate and store percentages whenever scores change
  templates.forEach(document => {
    if (document.submission_type === 'Manual' && document.template_reference === "Medical Certificate") {
      if (totalEmployeeScore > 0) {
        const percentage = (document.template_score / totalEmployeeScore) * 100;
        setUserTemplatePercentage(prev => ({
          ...prev,
          [document.template_id]: percentage.toFixed(2)
        }));
      }
    } else if (document.user_score > 0) {
      const percentage = (document.template_score / document.user_score) * 100;
      setUserTemplatePercentage(prev => ({
        ...prev,
        [document.template_id]: percentage.toFixed(2)
      }));
    }
  });
}, [totalEmployeeScore, templates]);

  

  useEffect(() => {
    const timers = Object.keys(showToaster).map(key => showToaster[key] && setTimeout(() => setShowToaster(prev => ({ ...prev, [key]: false })), 3000));
    return () => timers.forEach(timer => clearTimeout(timer));
  }, [showToaster]);

  // Employees
  useEffect(() => {
    if (manualUploadTemplate) {
      fetchEmployees();
    }
  }, [manualUploadTemplate]);

  // Modify the image preview handling
const openFullScreenImage = (imageUrl) => {
  setFullScreenImage(imageUrl);
};

const closeFullScreenImage = () => {
  setFullScreenImage(null);
};


const handleRemoveFile = (documentId) => {
  setPreviewPhotos(prev => {
    const newPreviewPhotos = { ...prev };
    delete newPreviewPhotos[documentId];
    return newPreviewPhotos;
  });
  setExpandedPreviewPhotos(prev => {
    const newExpandedPreviewPhotos = { ...prev };
    delete newExpandedPreviewPhotos[documentId];
    return newExpandedPreviewPhotos;
  });
  
  // Also remove from savedEmployeeScores if it's an employee file
  if (documentId.includes('-')) {
    setSavedEmployeeScores(prev => {
      const newScores = { ...prev };
      delete newScores[documentId];
      return newScores;
    });
  }
};

  const handleManualUpload = (document) => {
    // Only set the manual upload template if it's a Medical Certificate
    if (document.template_reference === "Medical Certificate") {
      setManualUploadTemplate(document);
    } else {
      // For other document types, show an error message
      setErrorMessage("Manual upload is only available for Medical Certificate templates");
      setTimeout(() => setErrorMessage(''), 3000); // Clear the error after 3 seconds
    }
  };

  const togglePreviewExpanded = (documentId) => {
    setExpandedPreviewPhotos(prev => ({
      ...prev,
      [documentId]: !prev[documentId]
    }));
  };

  if (!isOpen) return null;

  const handleFileChange = (templateId, event) => {
    const file = event.target.files[0];
    
    if (file) {
      console.log('File selected:', file); // Debug log
      console.log('File details:', {
        name: file.name,
        type: file.type,
        size: file.size
      });
  
      // Validate file size (optional)
      if (file.size <= 10 * 1024 * 1024) { // 10MB limit
        // Update files state
        setFiles(prev => ({ 
          ...prev, 
          [templateId]: file 
        }));
        
        // Create preview for image files
        if (file.type.startsWith('image/')) {
          setPreviewPhotos(prev => ({
            ...prev,
            [templateId]: URL.createObjectURL(file)
          }));
        }
      } else {
        setErrorMessage('File size exceeds 10MB limit.');
        // Clear the file input
        event.target.value = '';
      }
    } else {
      console.log('No file selected'); // Debug log
    }
  };

  

  // Function to fetch employees from the API
  const fetchEmployees = async () => {
    try {
      setLoadingEmployees(true);
      // Use URL encoding for the template reference to handle spaces and special characters
      const encodedTemplateReference = encodeURIComponent(manualUploadTemplate.template_reference);
      const response = await axios.get(
        `/api/compliance-tracker/consultant-fill-in/manual-employee-upload/${selectedCompany}/${selectedDepartment}/${encodedTemplateReference}`
      );
      setEmployees(response.data || []);
      setLoadingEmployees(false);
    } catch (error) {
      console.error('Error fetching employees:', error);
      setErrorMessage('Failed to load employees. Please try again.');
      setLoadingEmployees(false);
      setEmployees([]);
    }
  };

 /* ---------------------------------------------------------------------
                              Employee
  --------------------------------------------------------------------- */

  // ------------------------ Employee Functions -------------------------


                                        

  // EMployee Function - Item Score 

  const calculateEmployeeTemplateItemScore = (templateScore, employeeCount) => {
    if (!employeeCount || employeeCount <= 0) return 0;
    // Calculate individual employee's portion of the template score
    return (templateScore / employeeCount).toFixed(2);
  };

  // Add this function to handle setting user score when a file is uploaded
  const handleEmployeeFileChange = (templateId, employeeId, event) => {
    const file = event.target.files[0];
    const compositeId = `${templateId}-${employeeId}`;
    
    if (file) {
      console.log('File selected:', file);
      
      // Validate file size
      if (file.size <= 10 * 1024 * 1024) { // 10MB limit
        // Update files state
        setFiles(prev => ({ 
          ...prev, 
          [compositeId]: file 
        }));
        
        // Create preview for image files
        if (file.type.startsWith('image/')) {
          setPreviewPhotos(prev => ({
            ...prev,
            [compositeId]: URL.createObjectURL(file)
          }));
        }
        
        // Remove the score update logic from here
        // The score will only be updated when save is pressed
        
      } else {
        setErrorMessage('File size exceeds 10MB limit.');
        event.target.value = '';
      }
    } else {
      console.log('No file selected');
    }
  };
  

 
  
  /* ------------------- Hande Save Functions ------------ */
  

  // Handle Save Employee 
  const handleEmployeeFileSubmit = async (document, employee) => {
    console.log('Current files state:', files);
    
    // Use the composite key that matches what you set in handleFileChange
    const compositeId = `${document.template_id}-${employee.id}`;
    const file = files[compositeId];
    
    console.log('File for employee template:', compositeId, file);
  
    if (!file) {
      console.error('No file found for employee template:', compositeId);
      setShowToaster(prev => ({ 
        ...prev, 
        templateSubmissionError: true 
      }));
      return;
    }
  
    try {
      // Calculate the scores here when save is pressed
      const itemTemplateScore = calculateEmployeeTemplateItemScore(document.template_score, employees.length);
      
      // Check if this employee-template combination has already been saved
      const previouslyStoredScore = savedEmployeeScores[compositeId] || 0;
      
      // Calculate the new total by removing any previous score for this item and adding the new score
      const newEmployeeScore = parseFloat(itemTemplateScore);
      const newTotalScore = totalEmployeeScore - previouslyStoredScore + newEmployeeScore;
      
      // Save this in our tracking object for later use
      const userItemScore = itemTemplateScore; // Full score since file is uploaded
      const percentage = 100; // 100% since file is uploaded
      
      // Update states
      setUserTemplateScore(prev => ({
        ...prev,
        [compositeId]: itemTemplateScore
      }));
      
      setUserTemplatePercentage(prev => ({
        ...prev,
        [compositeId]: 100
      }));
  
      // Set the new total score in state for future reference
      setTotalEmployeeScore(newTotalScore);
      
      // Store this employee's score in our tracking object
      setSavedEmployeeScores(prev => ({
        ...prev,
        [compositeId]: itemTemplateScore
      }));
  
      // Create FormData
      const formData = new FormData();
      
      // Use the newly calculated total score directly, don't reference the state variable
      formData.append('sheFileId', selectedSheFileId);
      formData.append('sheFileName', selectedSheFileName);
      
      // Template Details - use newTotalScore instead of totalEmployeeScore
      formData.append('templateId', document.template_id);
      formData.append('templateName', document.template_name);
      formData.append('templateUserScore', newTotalScore.toFixed(2)); // Use the directly calculated value
      formData.append('templateScore', document.template_score);
      formData.append('submissionType', document.submission_type);
      
      // User details from session or state
      const user = JSON.parse(sessionStorage.getItem('user'));
      
      formData.append('filledInByUserId', user.user_id);
      formData.append('filledInByUserType', user.userType);
      formData.append('filledInByUserCompany', user.companyId);
      formData.append('filledInByUserDepartment', user.department_id);
      formData.append('filledInByUserName', user.userName);
      formData.append('filledInByUserSurname', user.userSurname);
      formData.append('selectedCompanyId', selectedCompany);
      formData.append('selectedCompanyName', selectedCompanyName);
      formData.append('selectedDepartmentId', selectedDepartment);
      formData.append('selectedDepartmentName', selectedDepartmentName);
  
      // ------------- Employee -------------
      formData.append('empId', employee.employee_id_no);
      formData.append('empName', employee.employee_name);
      formData.append('empSurname', employee.employee_surname);
      
      // Add the score calculations
      formData.append('employeeUserScore', userItemScore);
      formData.append('employeeItemTemplateScore', itemTemplateScore);
      formData.append('employeeItemPercentage', percentage);
      
      // Append the file with its original filename
      formData.append('file', file, file.name);
  
      console.log('Submitting form data:', Object.fromEntries(formData));
  
      const response = await axios.post(
        '/compliance/consultant-fill-in/save-template-file/employee-response', 
        formData, 
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      );
  
      console.log('Submission response:', response.data);
  
      // Handle successful submission
      setShowToaster(prev => ({ 
        ...prev, 
        templateSubmitted: true 
      }));
  
    } catch (error) {
      console.error('Template file submission error:', error.response ? error.response.data : error);
      setShowToaster(prev => ({ 
        ...prev, 
        templateSubmissionError: true 
      }));
    }
};

  // Handle Save Template
  const handleTemplateFileSubmit = async (document) => {
    console.log('Current files state:', files);
    
    const file = files[document.template_id];
    
    console.log('File for template:', document.template_id, file);
  
    if (!file) {
      console.error('No file found for template:', document.template_id);
      setShowToaster(prev => ({ 
        ...prev, 
        templateSubmissionError: true 
      }));
      return;
    }
  
    try {
      // Calculate user score and percentage when saving
      const newUserScore = document.template_score; // Full score since file is uploaded
      const newPercentage = 100; // 100% since file is uploaded
      
      const formData = new FormData();
      
      // Populate form data with all necessary details
      formData.append('sheFileId', selectedSheFileId);
      formData.append('sheFileName', selectedSheFileName);
      formData.append('sheFileScore', selectedSheFileScore);
      
      // Templates 
      formData.append('templateId', document.template_id);
      formData.append('templateName', document.template_name);
      formData.append('templateScore', document.template_score);
      formData.append('submissionType', document.submission_type);
  
      // Add the user score and percentage
      formData.append('userScore', newUserScore);
      formData.append('userPercentage', newPercentage);
      
      // User details from session or state
      const user = JSON.parse(sessionStorage.getItem('user'));
      
      formData.append('filledInByUserId', user.user_id);
      formData.append('filledInByUserType', user.userType);
      formData.append('filledInByUserCompany', user.companyId);
      formData.append('filledInByUserDepartment', user.department_id);
      formData.append('filledInByUserName', user.userName);
      formData.append('filledInByUserSurname', user.userSurname);
      formData.append('selectedCompanyId', selectedCompany);
      formData.append('selectedCompanyName', selectedCompanyName);
      formData.append('selectedDepartmentId', selectedDepartment);
      formData.append('selectedDepartmentName', selectedDepartmentName);
      
      // Append the file with its original filename
      formData.append('file', file, file.name);
  
      console.log('Submitting form data:', Object.fromEntries(formData));
  
      const response = await axios.post(
        '/compliance/consultant-fill-in/save-template-file', 
        formData, 
        {
          headers: { 'Content-Type': 'multipart/form-data' }
        }
      );
  
      console.log('Submission response:', response.data);
  
      // Only update state after successful submission
      setUserScore(prev => ({
        ...prev,
        [document.template_id]: newUserScore
      }));
      
      setUserTemplatePercentage(prev => ({
        ...prev,
        [document.template_id]: newPercentage
      }));
  
      // Handle successful submission
      setShowToaster(prev => ({ 
        ...prev, 
        templateSubmitted: true 
      }));
  
    } catch (error) {
      console.error('Template file submission error:', error.response ? error.response.data : error);
      setShowToaster(prev => ({ 
        ...prev, 
        templateSubmissionError: true 
      }));
    }
  };

  return (
    <div className="checklist-question-user-fill-in-checklist-main-container">
      <div className="template-question-fill-in-checklist-container">
        <div className="template-fill-in-elements-container">
          <div className="user-fill-in-back-button-time-container">
            <div className='user-fill-in-back-button-container'>
              <img src={blueKbcLogo} alt="white-kbc-logo" className="add-quesiton-side-menu-logo" />
              <button type="button" onClick={onClose} className="user-fill-in-back-button"> Back</button>
            </div>
          </div>
          
          <div className="Template-fill-in-header-main-container">
            <div className="Template-fill-in-header-text-and-search-main-container">

              <div className="Template-fill-in-header-text-main-container">
                <h2>Compliance Tracker -  Fill In Template </h2>
                <h2>SHE File: <span style={{ color: 'black' }}>{selectedSheFileName} </span></h2>
                <p>Company <span className="secondary-color-paragraph">{selectedCompanyName} </span> , Department <span className="secondary-color-paragraph">{selectedDepartmentName}</span></p>
              </div>

              <div>
                <h2><span className="secondary-color-paragraph">SHE File Score : {selectedSheFileScore}</span></h2>
              </div>
            </div>

            <div>
              {errorMessage && (
                  <div className="error-message">
                    {errorMessage}
                  </div>
                )}
            </div>

            {isLoading ? (
              <div className="loading-spinner-container">
                <LiaSpinnerSolid className="lia-spin-solid-icon" />
              </div>
            ) : (
              <div className='template-questions-user-fill-in-main-container-consultant'>
                <div className="template-table-main-container-consultant">
                  <table className="template-table">
                    <colgroup>
                      <col style={{width: "200px"}}/>{/* Submit */}
                      <col style={{width: "100px"}}/>{/* Submit */}
                      <col style={{width: "400px"}}/>{/* Template Name */}
                      <col style={{width: "400px"}}/>{/* Template Reference */}
                      <col style={{width: "200px"}}/>{/* Template Score */}
                      <col style={{width: "100px"}}/>{/* Percentage */}
                      <col style={{width: "150px"}}/>{/* Template Frequency */}
                      <col style={{width: "150px"}}/>{/* Submission Status */}
                      <col style={{width: "100px"}}/>{/* QA Status */}
                      <col style={{width: "200px"}}/>{/* QA Status Comment */}
                      <col style={{width: "150px"}}/>{/* Image Preview */}
                    </colgroup>
                    
                    <thead>

                      <tr>
                        <th>Save</th>
                        <th>Submit</th>
                        <th>Template Name</th>
                        <th>Template Reference</th>
                        <th>User Score</th>
                        <th>Template Score</th>
                        <th>Percentage</th>
                        <th>Template Frequency</th>
                        <th>Submission Status</th>
                        <th>QA Status</th>
                        <th>QA Status Comment</th>
                      </tr>
                      
                    </thead>

                    <tbody>
                      {templates.length === 0 ? (
                        <tr>
                          <td colSpan="10" className="no-data-message">
                            No documents found for this SHE Pack.
                          </td>
                        </tr>
                      ) : (
                        templates.map((document, index) => (

                          <>

                          <tr key={index}>
                            
                          <td>
                            {document.submission_type !== 'Manual' && (
                              <FaRegSave 
                                onClick={() => handleTemplateFileSubmit(document)}
                                className='template-save-icon' 
                              />
                            )}
                          </td>


                            <td>
                              {document.submission_type === 'Upload' ? (
                                <div className="file-upload-container">
                                  <input 
                                    type="file" 
                                    id={`file-upload-${index}`}
                                    className="submit-button hidden-file-input"
                                    onChange={(e) => handleFileChange(document.template_id, e)}
                                    accept=".png,.jpg,.jpeg,.pdf"
                                  />
                                  {previewPhotos[document.template_id] && (
                                <div className='checklist-question-toaster-image-delete-button-container'>
                                  <CiImageOn 
                                    className='checklist-question-toaster-image-icon' 
                                    onClick={() => openFullScreenImage(previewPhotos[document.template_id])} 
                                  />
                                  <button 
                                    type='button' 
                                    className='delete-image-button' 
                                    onClick={() => handleRemoveFile(document.template_id)}
                                  > 
                                    <MdDeleteOutline /> 
                                  </button>
                                </div>
                              )}
                              {expandedPreviewPhotos[document.template_id] && previewPhotos[document.template_id] && (
                                <img 
                                  onClick={() => togglePreviewExpanded(document.template_id)} 
                                  src={previewPhotos[document.template_id]} 
                                  alt="Preview" 
                                  style={{ maxWidth: '200px', maxHeight: '200px' }} 
                                />
                              )}
                                </div>
                              ) : document.submission_type === 'Manual' ? (
                                <button 
                                  className="confirm-button"
                                  onClick={() => handleManualUpload(document)}
                                  >
                                  Manual
                                </button>
                              ) : null}
                              {errorMessage && (
                                <div className="error-message">
                                  {errorMessage}
                                </div>
                              )}
                            </td>
                            <td>{document.template_name}</td>
                            <td>{document.template_reference}</td>

                            {/** */}
                            <td>
                              {document.submission_type === 'Manual' && document.template_reference === "Medical Certificate" 
                                ? totalEmployeeScore.toFixed(2) 
                                : userScore[document.template_id] || 0
                              }
                            </td>


                            <td>
                              {document.template_score}
                            </td>

                            <td>
                              {document.submission_type === 'Manual' && document.template_reference === "Medical Certificate" 
                                ? totalEmployeeScore > 0 
                                  ? ((totalEmployeeScore / document.template_score) * 100).toFixed(2) 
                                  : 0
                                : userScore[document.template_id] > 0 || userTemplatePercentage[document.template_id] > 0
                                  ? userTemplatePercentage[document.template_id] || ((userScore[document.template_id] / document.template_score) * 100).toFixed(2)
                                  : document.user_score > 0 
                                    ? ((document.user_score / document.template_score) * 100).toFixed(2) 
                                    : 0
                              } %
                            </td>

                            <td>{document.template_frequency }</td>
                            <td>{document.template_submission_status || 'No submission'}</td>
                            <td>{document.qa_status}</td>
                            <td>{document.qa_status_comment}</td>
                            
                          </tr>

                          
                          {manualUploadTemplate && manualUploadTemplate.template_id === document.template_id && document.template_reference === "Medical Certificate" && (
                            <tr>
                              <td colSpan="10">
                                <div className='manual-upload-main-container'>
                                  <h2>Manual Upload for <span className="secondary-color-paragraph">{document.template_name}</span></h2>
                                  <b>Template Reference : {document.template_reference}</b>
                                  
                                  <div className="manual-upload-employees-table-container">
                                    {loadingEmployees ? (
                                      <div className="loading-spinner-container">
                                        <LiaSpinnerSolid className="lia-spin-solid-icon" />
                                      </div>
                                    ) : (
                                      <table className="template-table-manual-upload">
                                        <colgroup>
                                          <col style={{width: "100px"}}/>{/* Save */}
                                          <col style={{width: "100px"}}/>{/* Submit */}
                                          <col style={{width: "150px"}}/>{/* Employee UID */}
                                          <col style={{width: "100px"}}/>{/* Employee UID */}
                                          <col style={{width: "300px"}}/>{/* Employee Full Name */}
                                          <col style={{width: "300px"}}/>{/* QA Status */}
                                          <col style={{width: "150px"}}/>{/* QA Comment */}
                                          <col style={{width: "150px"}}/>{/* Validity */}
                                          <col style={{width: "150px"}}/>{/* Validity */}
                                        </colgroup>

                                        <thead>
                                          <tr>
                                            <th>Save</th>
                                            <th>Submit</th>
                                            <th>User Item Score</th>
                                            <th>Item Template Score</th>
                                            <th>Percentage</th>
                                            <th>Emp ID</th>
                                            <th>Emp Name</th>
                                            <th>Submission Status</th>
                                            <th>QA Status</th>
                                            <th>File Validity</th>
                                            <th>File Upload Frequency</th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          {employees.length === 0 ? (
                                            <tr>
                                              <td colSpan="8" className="no-data-message">
                                                <i>No employees found for company {selectedCompanyName} and site {selectedDepartmentName}.</i> 
                                              </td>
                                            </tr>
                                          ) : (
                                            employees.map((employee, idx) => (
                                              
                                              <tr key={idx}>
                                                <td>
                                                  <FaRegSave 
                                                    onClick={() => handleEmployeeFileSubmit(document, employee)}
                                                    className='template-save-icon' 
                                                  />
                                                </td>
                                                <td>
                                                  <div className="file-upload-container">
                                                    <input 
                                                      type="file" 
                                                      id={`file-upload-${employee.id}-${idx}`}
                                                      className="submit-button hidden-file-input"
                                                      onChange={(e) => handleEmployeeFileChange(`${document.template_id}`, employee.id, e)}
                                                      accept=".png,.jpg,.jpeg,.pdf"
                                                    />
                                                    {previewPhotos[`${document.template_id}-${employee.id}`] && (
                                                      <div className='checklist-question-toaster-image-delete-button-container'>
                                                        <CiImageOn 
                                                          className='checklist-question-toaster-image-icon' 
                                                          onClick={() => openFullScreenImage(previewPhotos[`${document.template_id}-${employee.id}`])} 
                                                        />
                                                        <button 
                                                          type='button' 
                                                          className='delete-image-button' 
                                                          onClick={() => handleRemoveFile(`${document.template_id}-${employee.id}`)}
                                                        > 
                                                          <MdDeleteOutline /> 
                                                        </button>
                                                      </div>
                                                    )}
                                                  </div>
                                                </td>

                                                <td>
                                                  {userTemplateScore[`${document.template_id}-${employee.id}`] || 0}
                                                </td>

                                                <td>
                                                  {calculateEmployeeTemplateItemScore(document.template_score, employees.length)} 
                                                </td>

                                                <td>
                                                  {userTemplatePercentage[`${document.template_id}-${employee.id}`] || 0}%
                                                </td>

                                                <td>{employee.employee_id_no}</td>
                                                <td>{`${employee.employee_name} ${employee.employee_surname}`}</td>
                                                <td>{previewPhotos[`${document.template_id}-${employee.id}`] ? 'Saved' : 'Pending'}</td>
                                                <td>Not Started</td>
                                                <td>{userTemplatePercentage[`${document.template_id}-${employee.id}`] ? 'Valid' : 'Not Valid'}</td>
                                                <td>{document.template_frequency}</td>
                                              </tr>
                                            ))
                                          )}
                                        </tbody>
                                      </table>
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}

                          
                          </>
                          
                          
                        ))
                      )}
                    </tbody>
                  </table>
                </div>

                
              </div>

              
            )}
          </div>
        </div>
      </div>

      {/* */}
      {fullScreenImage && (
        <div 
          className="full-screen-image-overlay" 
          onClick={closeFullScreenImage}
        >
          <div className="full-screen-image-container">
            <img 
              src={fullScreenImage} 
              alt="Full Screen Preview" 
              className="full-screen-image"
              onClick={(e) => e.stopPropagation()} 
            />
            <button 
              className="close-image-modal-btn" 
              onClick={closeFullScreenImage}
            >
              &times;
            </button>
          </div>
        </div>
      )}



      {/** */}
      {showToaster.questionSaved && <ItemAddedToaster item={'Question Saved'} />}
      {showToaster.categorySaved && <ItemAddedToaster item={'Category Saved'} />}

      {showSuccess && 
        <CreatedSuccesFullyToaster
          heading='SHE File Filled Successfully'
        />
      }
    </div>
  );
};

export default ToasterConsultantTemplateFill;