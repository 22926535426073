/* ---------- Create Checklist toaster component ------------------

    -   This component is used for when the user wants to create a custom SHE Pack document. 

    API Routes 

    backend/
*/

// ------------------ React Components ------------------------
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// -------------------- API -----------------------------
import API_BASE_URL from "../../utils/api/apiConfig.js";

// --------------------- icons --------------------
import { MdDeleteOutline } from "react-icons/md";
import { FaSpinner } from "react-icons/fa";
import { GiConfirmed } from "react-icons/gi";


// ------------------- CSS ----------------------
import '../../../assets/css/ShePack.css'
import '../../../assets/css/Checklists.css'
import '../../../assets/css/ComplianceTracker.css'


// --------------------- Assests -------------------
import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png'; 

// ------------------- Application components --------------------
import ItemAddedToaster from "../../toasterscomponents/checklistToasters/ItemAddedToaster.js";
import ItemRemovedToaster from "../../toasterscomponents/checklistToasters/ItemRemovedToaster.js";

import CreatedSuccesFullyToaster from "../../toasterscomponents/shepacktoasters/CreatedSuccesFullyToaster.js"; // Toaster for when document is created successfully


// --------------------- Component - Create She Pack Documents -------------------
const CreatePeopleComplianceComponent = () => {

    // ------------------- State Managements ------------------

    //
    const navigate = useNavigate();

    //
    const user = JSON.parse(sessionStorage.getItem('user'));

    // State Management 
    const [isLoading, setIsLoading] = useState(true);
    const [editError, setEditError] = useState("");
    const [submitStatus, setSubmitStatus] = useState('idle');
    const [errors, setErrors] = useState([]);

    //
    const [viewSection, setViewSection] = useState({ A: true, C: false });
    
    // State Management - SHE Pack Documents
    const [employeeName, setEmployeeName] = useState("");

    // State Management - SHE Packs
    const [companies, setCompanies] = useState([]);
    const [departments, setDepartments] = useState([]);

    
    const [selectedDepartment, setSelectedDepartment] = useState("");
    const [selectedCompanies, setSelectedCompanies] = useState("");
    const [isCompanyAndSiteSelected, setIsCompanyAndSiteSelected] = useState(false);

    // state managment - section c questions
    const [employeeDetails, setEmployeeDetails] = useState([]);
   

    //
    const [isEditing, setIsEditing] = useState(false);

    
    
    const [columns, setColumns] = useState([]);
   
    const [optionError, setOptionError] = useState("");
    const [showToaster, setShowToaster] = useState(
        { 
            success: false, 
            categoryAdded: false, 
            rowAdded: false, 
            rowRemoved: false, 
            optionAdded: false, 
            editedCategory: false, 
            cancelEditedCategory: false , 
            CompanyAndSiteSelected : false 
        });

    


   // Modified useEffect to handle company selection
useEffect(() => {
    const fetchCompanies = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/compliance-tracker/people-management/create-employee/get-companies`);
            
            if (Array.isArray(response.data)) {
                setCompanies(response.data);
            } else {
                console.error('Invalid response format', response.data);
                setCompanies([]);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching companies:', error);
            setEditError(error.response?.data?.message || 'Failed to load companies');
            setIsLoading(false);
            setCompanies([]);
        }
    };

    fetchCompanies();
}, []); // This only runs once on component mount

// Add a new useEffect that watches for changes to selectedCompanies
useEffect(() => {
    // Only fetch departments if a company is selected
    if (selectedCompanies) {
        const fetchDepartmentsByCompany = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(
                    `${API_BASE_URL}/compliance-tracker/people-management/create-employee/get-departments-by-company/${selectedCompanies}`
                );
                
                if (Array.isArray(response.data)) {
                    setDepartments(response.data);
                } else {
                    console.error('Invalid response format', response.data);
                    setDepartments([]);
                }
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching departments:', error);
                setEditError(error.response?.data?.message || 'Failed to load departments');
                setIsLoading(false);
                setDepartments([]);
            }
        };

        fetchDepartmentsByCompany();
        // Reset the selected department when company changes
        setSelectedDepartment("");
    } else {
        // Clear departments if no company is selected
        setDepartments([]);
    }
}, [selectedCompanies]); // This runs whenever selectedCompanies changes

    
    // Handle edit button click
    const handleSelectCompanyAndSite = () => {

        if (!selectedCompanies || !selectedDepartment) {
            setEditError("Please select both a company and site");
            return;
        }

        setShowToaster(prev => ({ ...prev, CompanyAndSiteSelected: true }));
        setEditError("");
        setIsCompanyAndSiteSelected(true);
        console.log('slelcted jdhjf')
        
    };
    

    useEffect(() => {
       
        fetchQuestionBankData();
        
    }, []);

    useEffect(() => {
        const timers = Object.keys(showToaster).map(key => showToaster[key] && setTimeout(() => setShowToaster(prev => ({ ...prev, [key]: false })), 3000));
        return () => timers.forEach(timer => clearTimeout(timer));
    }, [showToaster]);

    const fetchQuestionBankData = async () => {
        try {
            const columnsResponse = await axios.get(`${API_BASE_URL}/columns/Question_Bank`);
            setColumns(columnsResponse.data);
        } catch (error) {
            console.error('Error fetching Question Bank data:', error);
        }
    };

    //
    const handleReset = () => {
        // Reset all form-related states to their initial state
        setEmployeeName("");
        
        // Instead of using initializePredefinedQuestions(), we'll start completely from scratch
        setEmployeeDetails([]);
        
       
        
        // Reset other related states
    
        setErrors([]);
        
        // Reset view sections
        setViewSection({ A: true, B: false, C: false });
    };

    const handleClearQuestions = () => {
        
        // Instead of using initializePredefinedQuestions(), we'll start completely from scratch
        setEmployeeDetails([]);

      
    
        // Reset other related states
        setErrors([]);
        
       
    };


    // Update handleInputChange to handle template_score correctly
const handleInputChange = (rowIndex, columnName, value) => {
    const updatedData = [...employeeDetails];
    if (columnName === '') {
        for (let i = 1; i <= 15; i++) updatedData[rowIndex][`option_${i}`] = '';
        if (value !== 'File') updatedData[rowIndex]['type_file_upload'] = '';
    }
    
    // 
    if (columnName === 'template_score') {
        updatedData[rowIndex] = { 
            ...updatedData[rowIndex], 
            [columnName]: value,
            template_score: value  // 
        };
    } else {
        updatedData[rowIndex] = { ...updatedData[rowIndex], [columnName]: value };
    }
    
    setEmployeeDetails(updatedData);
};

    const handleAddRow = () => {
        
        const newRow = {
            'Employee ID No': '',
            'Employee Name': '',
            'Employee Surname': '',
            'Employee Email': '',
            'Medical Certificate': '',
            'Competency Certificates': ''
        };

        setShowToaster(prev => ({ ...prev, rowAdded: true }));
        setEmployeeDetails([...employeeDetails, newRow]);
    };

   

    const handleRemoveRow = (rowIndex) => {
        // Remove row without any restrictions
        setShowToaster(prev => ({ ...prev, rowRemoved: true }));
        setEmployeeDetails(employeeDetails.filter((_, index) => index !== rowIndex));
    };

    

    const validateEmployeeData = (employees) => {
        const errors = [];
        
        if (employees.length === 0) {
          errors.push("No employees added. Please add at least one employee.");
          return errors;
        }

        if (!selectedCompanies || !selectedDepartment) {
            setEditError("Please select both a company and site");
            return;
        }



        
        employees.forEach((employee, index) => {
          if (!employee['Employee ID No'] || employee['Employee ID No'].trim() === '') {
            errors.push(`Employee : Employee ID No is required.`);
          }
          
          if (!employee['Employee Name'] || employee['Employee Name'].trim() === '') {
            errors.push(`Employee : Employee name is required.`);
          }
          
          if (!employee['Employee Surname'] || employee['Employee Surname'].trim() === '') {
            errors.push(`Employee : Employee surname is required.`);
          }
          
          if (!employee['Employee Email'] || employee['Employee Email'].trim() === '') {
            errors.push(`Employee : Employee email is required.`);
          } else if (!/\S+@\S+\.\S+/.test(employee['Employee Email'])) {
            errors.push(`Employee : Invalid email format.`);
          }
          
          if (!employee['Medical Certificate'] || employee['Medical Certificate'].trim() === '') {
            errors.push(`Employee : Medical Certificate status is required.`);
          }
          
          if (!employee['Competency Certificates'] || employee['Competency Certificates'].trim() === '') {
            errors.push(`Employee : Competency Certificates status is required.`);
          }
        });
        
        return errors;
      };
   
    const visibleColumnNames = [
        
        'Remove',
        'Employee ID No',
        'Employee Name',
        'Employee Surname',
        'Employee Email',
        'Medical Certificate',
        'Competency Certificates'
       
        //'is_mandatory',
    
       
        //'expires',
        //...Array.from({ length: visibleOptions }, (_, i) => `option_${i + 1}`),

    ];

    // Handle Submit all Questions
    const handleSubmit = async () => {
        setSubmitStatus('loading');
        setErrors([]);
        
        let newErrors = [];
        
        // Validate employee data
        const validationErrors = validateEmployeeData(employeeDetails);
        
        if (newErrors.length > 0 || validationErrors.length > 0) {
            setErrors(prevErrors => [...prevErrors, ...newErrors, ...validationErrors]);
            window.scrollTo(0, 0);
            setSubmitStatus('idle');
            return;
        }
        
        try {
            if (!user || !user.user_id) {
                throw new Error('User session data is missing');
            }
            
            // Find the selected company and department details
            const selectedCompanyObj = companies.find(company => company.company_id === selectedCompanies);
            const selectedDepartmentObj = departments.find(department => department.department_id === selectedDepartment);
            
            if (!selectedCompanyObj) {
                throw new Error('Selected company not found');
            } else if (!selectedDepartmentObj) {
                throw new Error('Selected department not found');
            }
            
            // Format employee details to match the expected structure in the backend
            const formattedEmployeeDetails = employeeDetails.map(employee => ({
                employee_id_No: employee['Employee ID No'],
                employee_name: employee['Employee Name'],
                employee_surname: employee['Employee Surname'],
                employee_email: employee['Employee Email'],
                required_document_medical: employee['Medical Certificate'],
                required_document_competency: employee['Competency Certificates']
            }));
            
            // Send data to the backend
            const response = await axios.post(
                `${API_BASE_URL}/compliance-tracker/create-employee/${selectedCompanies}/${selectedDepartment}`, 
                {
                    created_by: user.user_id,
                    company_id: selectedCompanyObj.company_id,
                    company_name: selectedCompanyObj.companyName,
                    department_id: selectedDepartmentObj.department_id,
                    department_name: selectedDepartmentObj.department_name,
                    employeeDetails: formattedEmployeeDetails
                }
            );
            
            console.log('Employees created successfully:', response.data);
            setShowToaster(prev => ({ ...prev, success: true }));
            setSubmitStatus('success');
            
            setTimeout(() => {
                setShowToaster(prev => ({ ...prev, success: false }));
                navigate('/compliance-tracker/compliance-tracker-management');
            }, 2000);
            
        } catch (error) {
            console.error('Error creating employees:', error);
            setErrors(prevErrors => [...prevErrors, `Error creating employees: ${error.message}`]);
            setSubmitStatus('error');
        }
    };

    return (

        <div className="add-question-custom-field-main-component-container">
            
            <div className="add-question-create-questions-checklist-custom-field-component-container">

                <div className="create-questions-custom-field-container">

                    <div className="add-question-view-checklist-component-container">
                        
                        <div className="add-question-view-checklist-header-container">

                            <div>
                                <img src={blueKbcLogo} alt="white-kbc-logo" className="add-quesiton-side-menu-logo" />
                            </div>

                            <div className="navigate-back-button-container">
                                <button className="navigate-back-button" onClick={() => navigate(-1)}>Back</button>
                            </div>

                        </div>

                        <div className="add-question-view-checklist-header-step-container">

                            <div>
                                <h2>Compliance tracker - Create Employee</h2>
                                
                                <p className="secondary-color-paragraph">To complete each section, click on the 'Maximize' button to expand the section.</p>
                            </div>

                        </div>

                        {['A', 'B'].map(section => (
                            <div key={section} className="create-checklist-section-a-information-main-container">
                                <div className="create-checklist-section-container-main-container">
                                    <div className="create-checklist-section-container">
                                        <div>
                                            <h3>Section {section} - {section === 'A' ? 'Company Information' : 'User Details'}</h3>
                                        </div>
                                        <div>
                                            <button className="confirm-button" onClick={() => setViewSection(prev => ({ ...prev, [section]: !prev[section] }))}>
                                                {viewSection[section] ? 'Minimize' : 'Maximize'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {viewSection[section] && (
                                    section === 'A' ? (
                                        <div className="create-checklist-section-a-information-container">
                                            <div className="create-checklist-section-a-information-container-container">

                                                <div>

                                                    <div>

                                                        <h3 className="category-secondary-color">Company Information Details</h3>
                                                    
                                                    </div>

                                                    <div>
                                                        
                                                        <p>Please provide the company details for the employee you wish to create.</p>

                                                    </div>

                                                </div>
                                                
                                            </div>
                                            

                                            <div className="create-checklist-section-a-information-container-container">

                                                <div className="duplicate-checklist-section-a-information-checklist-name-container-container">

                                                    <div className="duplicate-checklist-section-a-information-checklist-name-container">
                                        
                                                        
                                                        <div className="duplicate-fillin-select-checklist-section-a-information-checklist-name-container">

                                                            {/* Checklist Name */}
                                                                <div className="compliance-tracker-company-information-main-container">

                                                                    {/* Select Company */}
                                                                    <div className="create-checklist-section-a-information-container-container">
                                                                        
                                                                        <div className="duplicate-fillin-select-checklist-section-a-information-checklist-name-name-container">
                                                                            
                                                                            <div>
                                                                                <h4>1. Select Company</h4>

                                                                                <p>Select the company that you want the create employees for.</p>
                                                                            </div>

                                                                            <div>
                                                                                <select
                                                                                    value={selectedCompanies}
                                                                                    onChange={e => setSelectedCompanies(e.target.value)}
                                                                                    disabled={isEditing || isCompanyAndSiteSelected}
                                                                                >
                                                                                    <option value="" disabled> -- Select Company -- </option>
                                                                                    {companies.map(company => (
                                                                                        <option key={company.company_id} value={company.company_id}>
                                                                                            {company.companyName}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            </div>
                                                                            
                                                                        </div>

                                                                    </div>

                                                                    {/* Select Department */}
                                                                    <div className="create-checklist-section-a-information-container-container">
                                                                        
                                                                        <div className="duplicate-fillin-select-checklist-section-a-information-checklist-name-name-container">
                                                                            
                                                                            <div>
                                                                                <h4>2. Select Site</h4>

                                                                                <p>Select the site that you want to create employees for.</p>
                                                                            </div>

                                                                            <div>
                                                                                <select
                                                                                    value={selectedDepartment}
                                                                                    onChange={e => setSelectedDepartment(e.target.value)}
                                                                                    disabled={isEditing || isCompanyAndSiteSelected}
                                                                                >
                                                                                    <option value="" disabled>-- Select Site --</option>
                                                                                    {departments.map(department => (
                                                                                        <option key={department.department_id} value={department.department_id}>
                                                                                            {department.department_name}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            </div>
                                                                            
                                                                        </div>

                                                                    </div>
                                                                    

                                                                </div>

                                                            <div className="add-question-view-checklist-category-container">
                                    

                                                                {editError && <div className="error-text">{editError}</div>}

                                                                <div>
                                                                    <p className="secondary-color-paragraph" style={{ fontStyle: 'italic' }}>Important. Click "Select Company & Site" to select the company and site that you want to create employees for.</p>
                                                                </div>

                                                                {isCompanyAndSiteSelected && (

                                                                    <div>
                                                                        <div className="compliance-tracker-create-employee-selecetd-company-department-conainer">

                                                                            <div>
                                                                                <GiConfirmed />
                                                                            </div>

                                                                            <div>
                                                                                <p> You have selected the following company {companies.find(company => company.company_id === selectedCompanies)?.companyName } and site {departments.find(department => department.department_id === selectedDepartment)?.department_name }</p>
                                                                            </div>
                                                                            
                                                                        </div>
                
                                                                    </div>

                                                                )}

                                                                <div>
                                                                
                                                                <button 
                                                                        className="confirm-button" 
                                                                        onClick={handleSelectCompanyAndSite}
                                                                        disabled={isEditing || isLoading || isCompanyAndSiteSelected}
                                                                    >
                                                                        {isLoading ? (
                                                                            <>
                                                                                <FaSpinner className="fa-spin" />
                                                                                <span>Loading...</span>
                                                                            </>
                                                                        ) : isCompanyAndSiteSelected ? (
                                                                            'Company & Site Selected'
                                                                        ) : (
                                                                            'Select Company & Site'
                                                                        )}
                                                                    </button>
                                                                </div>
                                                            </div>

                                                                

                                                        </div>
                                                    </div>

                                    </div>
                                    
                                </div>
                                        </div>
                                    ) : (
                                        <div className="add-question-view-fillin--checklist-table-container">


                                            {/* Question Bank Table */}
                                            <div className="add-question-view-checklist-table-table-container">
                                                <table className="create-questions-view-fillin-checklist-table">
                                                    <colgroup>
                                                        <col style={{ width: "50px" }} /> {/* Template Reference */}
                                                        <col style={{ width: "150px" }} /> {/* Template Reference */}
                                                        <col style={{ width: "150px" }} /> {/* Template Name */}
                                                        <col style={{ width: "150px" }} /> {/* Template Frequency */}
                                                        <col style={{ width: "150px" }} /> {/* Template Score */}
                                                        <col style={{ width: "180px" }} /> {/* Submission Type */}
                                                        <col style={{ width: "180px" }} /> {/* Submission Type */}
                                                       
                                                       
                                                    </colgroup>
                                                    <thead>
                                                        <tr>
                                                            {visibleColumnNames.map((columnName, index) => (
                                                                <th key={index} className="table-header">
                                                                    {columnName === 'Remove' ? '' : columnName}
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody className="view-table-body">
                                                        {employeeDetails.length === 0 ? (
                                                            <tr>
                                                                <td colSpan={visibleColumnNames.length}>No employees found for this company and site :  {companies.find(pack => pack.shefile_id === selectedCompanies)?.shefile_name || selectedCompanies}, click 'add row' to add a template.</td>
                                                            </tr>
                                                        ) : (
                                                            employeeDetails.map((row, rowIndex) => (
                                                                <tr key={rowIndex}>
                                                                    <td>
                                                                        <button onClick={() => handleRemoveRow(rowIndex)} className="remove-row-button">
                                                                            <MdDeleteOutline className='delete-checklist-submition-icon' />
                                                                        </button>
                                                                    </td>
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            value={row['Employee ID No'] || ''}
                                                                            onChange={(e) => handleInputChange(rowIndex, 'Employee ID No', e.target.value)}
                                                                            placeholder="Enter employee name"
                                                                            className="create-questions-form-question-input-column"
                                                                        />
                                                                    </td>

                                                                    {/* Column - question name input field*/}
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            value={row['Employee Name'] || ''}
                                                                            onChange={(e) => handleInputChange(rowIndex, 'Employee Name', e.target.value)}
                                                                            placeholder="Enter employee name"
                                                                            className="create-questions-form-question-input-column"
                                                                        />
                                                                    </td>

                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            value={row['Employee Surname'] || ''}
                                                                            onChange={(e) => handleInputChange(rowIndex, 'Employee Surname', e.target.value)}
                                                                            placeholder="Enter employee surname"
                                                                            className="create-questions-form-question-input-column"
                                                                        />
                                                                    </td>

                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            value={row['Employee Email'] || ''}
                                                                            onChange={(e) => handleInputChange(rowIndex, 'Employee Email', e.target.value)}
                                                                            placeholder="Enter employee email"
                                                                            className="create-questions-form-question-input-column"
                                                                        />
                                                                    </td>

                                                                    <td>
                                                                        <select
                                                                            value={row['Medical Certificate'] || ''}
                                                                            onChange={(e) => handleInputChange(rowIndex, 'Medical Certificate', e.target.value)}
                                                                        >
                                                                            <option value="" disabled>-- Requires Medical Certificate-- </option>
                                                                            <option value="Yes">Yes</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </td>

                                                                    <td>
                                                                        <select
                                                                            value={row['Competency Certificates'] || ''}
                                                                            onChange={(e) => handleInputChange(rowIndex, 'Competency Certificates', e.target.value)}
                                                                        >
                                                                            <option value="" disabled>-- Requires Competency Certificate -- </option>
                                                                            <option value="Yes">Yes</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </td>

                                                                   

                                                                    
                                                                    
                                                                </tr>
                                                            ))
                                                        )}
                                                    </tbody>
                                                </table>
                                                <div>
                                                   <p className="secondary-color-paragraph"><i>Click the clear button to clear employee details</i></p>
                                                </div>
                                                {errors.length > 0 && (
                                                    <div className="error-text">
                                                        <ul>
                                                            {errors.map((error, index) => (
                                                                <li key={index}>{error}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}


                                                <div className="add-row-add-option-container">
                                                    <button onClick={handleAddRow} className="confirm-button-response">Add Employee</button>
                                              
                                                    <button onClick={handleClearQuestions} className="cancel-button">Clear</button>
                                                </div>
                                                
                                                {optionError && <div className="error-text">{optionError}</div>}
                                                
                                                <div style={{ textAlign: 'center' }}>
                                                    <p>By clicking 'Create Employee' , employee will be created for SHE file</p>
                                                </div>

                                                <div className="add-question-submit-clear-button-container">
                                                    <button onClick={handleSubmit} className="confirm-button" disabled={submitStatus === 'loading'}>
                                                        {submitStatus === 'loading' ? (
                                                            <>
                                                                <FaSpinner className="fa-spin" />
                                                                <span className="submitting-questions-text"> Create Employee...</span>
                                                            </>
                                                        ) : (
                                                            'Create Employee'
                                                        )}
                                                    </button>
                                                    <button onClick={handleReset} className="cancel-button">Reset</button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {showToaster.CompanyAndSiteSelected && <ItemAddedToaster item={'Company & Site Selected'} />}
            {showToaster.rowAdded && <ItemAddedToaster item={'Employee Added'} />}
            {showToaster.rowRemoved && <ItemRemovedToaster item={'Employee Deleted'} />}
            
           
            
            
            {/* Created Succesfully Toaster - Toaster shows when the user has created a document succesfully */}
            {showToaster.success && 
                <CreatedSuccesFullyToaster
                    heading='Template Created Successfully'
                />
            }

            
            
            
        </div>
    );
};

export default CreatePeopleComplianceComponent;