import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

// -------------------- API -----------------------------
import API_BASE_URL from "../../utils/api/apiConfig.js";

// ------------------- Application components --------------------


// --------------------- CSS File --------------------
import '../../../assets/css/Checklists.css';

// --------------------- icons --------------------
import { CiImageOn } from "react-icons/ci";
import { LiaSpinnerSolid } from "react-icons/lia";
import { BsFileEarmarkPdf } from "react-icons/bs";

import { TfiWrite } from "react-icons/tfi";
import { FaRegFolderOpen } from "react-icons/fa";

// --------------------- Assests -------------------
import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png';
import ToasterQaViewSubmissions from './ToasterQaViewSubmissions.js';

// --------------------- View SHE Pack component -------------------
const QATemplateSubmissionComponent = () => {

  // Retrieve user data from session storage
  const user = JSON.parse(sessionStorage.getItem('user'));

  const navigate = useNavigate();

  // --------------- State Managements ------------------------

  // New state for companies and departments
  const [consultantAssignments, setConsultantAssignments] = useState([]);

  const [shePackCompanies, setShePackCompanies] = useState({});
  const [selectedCompanies, setSelectedCompanies] = useState({});

  const [departmentsPerCompany, setDepartmentsPerCompany] = useState({});
  const [selectedDepartments, setSelectedDepartments] = useState({});

  // First, modify the state to store questions per document
  const [questionsPerDocument, setQuestionsPerDocument] = useState({});
  // Store submission status per document
  const [submissionStatusPerDocument, setSubmissionStatusPerDocument] = useState({});

  // State Management - Global 
  const [submissionStatus, setSubmissionStatus] = useState('Not Started');

  const [shePacks, setShePacks] = useState([]);

  // State Management - selected she pack
  const [selectedShePackName, setSelectedShePackName] = useState('');
  const [selectedShePack, setSelectedShePack] = useState(null);

  const [shePackDocuments, setShePackDocuments] = useState([]);
  const [selectedShePackId, setSelectedShePackId] = useState(null);

  // state management - slected document 
  // Add these state variables near your other state declarations
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [selectedDocumentName, setSelectedDocumentName] = useState('');
  const [selectedDocumentScore, setSelectedDocumentScore] = useState('');
  const [selectedDocumentUserPercentage, setSelectedDocumentUserPercentage] = useState('');

  //
  const [assignedShePacks, setAssignedShePacks] = useState([]);

  // state management - documnets
  const [showDocuments, setShowDocuments] = useState(false);
  
  //
  const [showTemplateToaster, setShowTemplateToaster] = useState(false);

  // state management - Questions toaster
  const [showQuestionToaster, setShowQuestionToaster] = useState(false);
  const [questions, setQuestions] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  // SHE Files dropdown state
  const [allSheFiles, setAllSheFiles] = useState([]);
  const [selectedSheFileId, setSelectedSheFileId] = useState('');

  // Company and department states
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedDepartment, setSelectedDepartment] = useState('');

  // Users
  const [departmentUsers, setDepartmentUsers] = useState([]);
const [selectedUser, setSelectedUser] = useState('');
  
  // ------------------- Functions ---------------------------

  // Fetch SHE files 
  useEffect(() => {
    const fetchSheFiles = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${API_BASE_URL}/api/quality-assurance/view-she-files`);
        setAllSheFiles(response.data);
      } catch (error) {
        console.error('Error fetching SHE files:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSheFiles();
  }, [user.user_id]);

  // Fetch companies when a SHE file is selected
  useEffect(() => {
    const fetchCompanies = async () => {
      if (!selectedSheFileId) return;
      
      setIsLoading(true);
      try {
        const companiesResponse = await axios.get(
          `${API_BASE_URL}/api/QA/qa-view-template-submissions/company/${selectedSheFileId}`
        );
        
        setShePackCompanies({
          [selectedSheFileId]: companiesResponse.data
        });

        // Store departments for each company
        const departmentsMap = {};
        companiesResponse.data.forEach(companyData => {
          departmentsMap[companyData.company] = companyData.departments;
        });
        
        setDepartmentsPerCompany({
          [selectedSheFileId]: departmentsMap
        });
      } catch (error) {
        console.error('Error fetching companies:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedSheFileId) {
      fetchCompanies();
    }
  }, [selectedSheFileId, user.user_id]);

  // Fetch users when department is selected
  // Fetch users when department is selected
useEffect(() => {
  const fetchUsers = async () => {
    if (!selectedDepartment) return;
    
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${API_BASE_URL}/api/template/view-template-submissions/users/${selectedDepartment}`,
        {
          params: { company: selectedCompany } // Pass company as a parameter
        }
      );
      
      setDepartmentUsers(response.data);
    } catch (error) {
      console.error('Error fetching users for department:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (selectedDepartment && selectedCompany) { // Only fetch if both are selected
    fetchUsers();
  } else {
    setDepartmentUsers([]);
    setSelectedUser('');
  }
}, [selectedDepartment, selectedCompany]); // Add selectedCompany as a dependency

  // Handle SHE file selection
  const handleSheFileChange = (sheFileId) => {
    setSelectedSheFileId(sheFileId);
    setSelectedCompany('');
    setSelectedDepartment('');
    setShePackDocuments([]);
  };
  
  // Handle company selection
  const handleCompanyChange = (company) => {
    setSelectedCompany(company);
    setSelectedDepartment('');
  };
   
  // Handle department selection
  const handleDepartmentChange = (department) => {
    setSelectedDepartment(department);
  };

  // Handle user selection
const handleUserChange = (userId) => {
  setSelectedUser(userId);
};

  // View SHE pack documents
  // View SHE pack documents
const handleViewDocuments = async () => {
  if (!selectedSheFileId || !selectedCompany || !selectedDepartment) {
    alert('Please select a SHE file, company, and site first');
    return;
  }
  
  setIsLoading(true);
  
  try {
    const documentsResponse = await axios.get(
      `${API_BASE_URL}/QA/quality-assurance-view-she-packs/template-submission/${selectedSheFileId}/${user.user_id}`,
      {
        params: {
          company: selectedCompany,
          department: selectedDepartment,
          userId: selectedUser || user.user_id // Use selected user if available, otherwise use current user
        }
      }
    );
    
    if (documentsResponse.status === 200 && Array.isArray(documentsResponse.data)) {
      setShePackDocuments(documentsResponse.data);
      setShowTemplateToaster(true);

      // Create an object to store questions for each document
      const allQuestionsPerDocument = {};
      const allSubmissionStatus = {};

      // Fetch questions for each document
      for (const document of documentsResponse.data) {
        const { shepack_document_id } = document;
        
        try {
          const questionsResponse = await axios.get(
            `${API_BASE_URL}/api/templates/a/consultant/she-pack-fill-in/assigned-she-packs/questions/${shepack_document_id}`,
            {
              params: { 
                userId: selectedUser || user.user_id, // Use selected user if available
                company: selectedCompany,
                department: selectedDepartment
              }
            }
          );

          if (questionsResponse.data.questions && Object.keys(questionsResponse.data.questions).length > 0) {
            const groupedQuestions = Object.keys(questionsResponse.data.questions).length > 0 
              ? questionsResponse.data.questions 
              : groupQuestionsByCategory(questionsResponse.data.questions);

            allQuestionsPerDocument[shepack_document_id] = groupedQuestions;
            allSubmissionStatus[shepack_document_id] = questionsResponse.data.submission_status;
          }
        } catch (error) {
          console.error(`Error fetching questions for document ${shepack_document_id}:`, error);
          allQuestionsPerDocument[shepack_document_id] = {};
          allSubmissionStatus[shepack_document_id] = 'Error';
        }
      }

      setQuestionsPerDocument(allQuestionsPerDocument);
      setSubmissionStatusPerDocument(allSubmissionStatus);
    }
  } catch (error) {
    console.error('Error fetching SHE Pack documents:', error);
  } finally {
    setIsLoading(false);
  }
};

  // Fucntions - Global
  const handleNavigateBack = () => {
    navigate(-1);
  };

  // Update the handleClick function to include company and department
  

  // Add this function before your ShePackFillIn component definition
  const groupQuestionsByCategory = (questions) => {
    return questions.reduce((grouped, question) => {
      const category = question.category || 'Uncategorized';
      if (!grouped[category]) {
        grouped[category] = [];
      }
      grouped[category].push(question);
      return grouped;
    }, {});
  };

  const handleCloseQuestionToaster = () => {
    setShowQuestionToaster(false);
  };

  const handleClickFillInDocument = async (shepack_document_id, shepack_document_name, shepack_document_score, user_percentage) => {
  
    setIsLoading(true);
    setSelectedDocumentId(shepack_document_id);
    setSelectedDocumentName(shepack_document_name);
    setSelectedDocumentScore(shepack_document_score);
    setSelectedDocumentUserPercentage(user_percentage);
    
    try {
      const response = await axios.get(`${API_BASE_URL}/api/templates/a/consultant/she-pack-fill-in/assigned-she-packs/questions/${shepack_document_id}`, {
        params: { 
          userId: selectedUser || user.user_id,  // Use selected user if available
          company: selectedCompany,
          department: selectedDepartment
        }
      });
  
      console.log('Selected Document Score:', shepack_document_score);
      console.log('Response data:', response.data);
  
      if (response.data.questions && Object.keys(response.data.questions).length > 0) {
        const groupedQuestions = Object.keys(response.data.questions).length > 0 
          ? response.data.questions 
          : groupQuestionsByCategory(response.data.questions);
  
        setQuestions(groupedQuestions);
        setSubmissionStatus(response.data.submission_status);
      } else {
        console.error('No questions found for this checklist');
      }
    } catch (error) {
      console.error('Error fetching questions:', error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="component-container">
      <div className="create-checklist-component-container">

        <div className="create-company-text-container">
          <div>
            <h1>Quality Assurance - Review Consultant Submissions</h1>
            <p>Review and manage completed template submissions that are prepared for the Quality Assurance (QA) process</p>
          </div>

          <div>
            <img src={blueKbcLogo} alt="white-kbc-logo" className="side-menu-logo" />
          </div>
        </div>

        <div className="navigate-back-button-main-container">
          <div className="navigate-back-button-container">
            <button className="navigate-back-button" onClick={handleNavigateBack}>Back</button>
          </div>
        </div>

        <div className="view-checklist-table-container">
          {isLoading ? (
            <div className="loading-spinner-container">
              <LiaSpinnerSolid className="lia-spin-solid-icon" />
            </div>
          ) : (
            <div className="view-checklist-table-table-container">
              <table className="view-checklist-table">
                <colgroup>
                  <col style={{width: "300px"}}/>
                  <col style={{width: "350px"}}/>
                  <col style={{width: "200px"}}/>
                  <col style={{width: "200px"}}/>
                  <col style={{width: "200px"}}/>
                </colgroup>

                <thead>
                  <tr>
                    <th className="table-header">SHE File Name</th>
                    <th className="table-header">Company</th>
                    <th className="table-header">Site</th>
                    <th className="table-header">User</th>
                    <th className="table-header">Select SHE File</th>
                  </tr>
                </thead>

                <tbody className="view-table-body">
                  <tr>
                    <td>
                      <select
                        className="she-file-dropdown"
                        value={selectedSheFileId}
                        onChange={(e) => handleSheFileChange(e.target.value)}
                      >
                        <option value="">Select SHE File</option>
                        {allSheFiles.map((sheFile) => (
                          <option key={sheFile.shepack_id} value={sheFile.shepack_id}>
                            {sheFile.shepack_name}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <select
                        className="company-dropdown"
                        value={selectedCompany}
                        onChange={(e) => handleCompanyChange(e.target.value)}
                        disabled={!selectedSheFileId}
                      >
                        <option value="">Select Company</option>
                        {shePackCompanies[selectedSheFileId]?.map((companyData, idx) => (
                          <option key={idx} value={companyData.company}>
                            {companyData.companyName}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <select
                        className="department-dropdown"
                        value={selectedDepartment}
                        onChange={(e) => handleDepartmentChange(e.target.value)}
                        disabled={!selectedCompany}
                      >
                        <option value="">Select Site</option>
                        {selectedCompany && departmentsPerCompany[selectedSheFileId]?.[selectedCompany]?.map((department, idx) => (
                          <option key={idx} value={department.id}>
                            {department.name}
                          </option>
                        ))}
                      </select>
                    </td>

                   

                      <td>
                        <select
                          className="department-dropdown"
                          value={selectedUser}
                          onChange={(e) => handleUserChange(e.target.value)}
                          disabled={!selectedDepartment}
                        >
                          <option value="">Select User</option>
                          {departmentUsers.map((userData, idx) => (
                            <option key={idx} value={userData.user_id}>
                              {userData.userName} {userData.userSurname}
                            </option>
                          ))}
                        </select>
                      </td>

                    

                    <td onClick={handleViewDocuments}
                          disabled={!selectedSheFileId || !selectedCompany || !selectedDepartment}>
                          <FaRegFolderOpen className='view-checklist-submition-icon' />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      <ToasterQaViewSubmissions

          isOpen={showTemplateToaster}
          onClose={() => setShowTemplateToaster(false)}
          selectedShePackName={selectedShePackName}
          shePackDocuments={shePackDocuments}
          isLoading={isLoading}
          onFillInDocument={handleClickFillInDocument}
          
          selectedCompany={selectedCompany} 
          selectedDepartment={selectedDepartment}
          selectedUser={selectedUser}  // Add this line

          shePackDocumentId={selectedDocumentId}      // She Pack Document Id
          shePackDocumentName={selectedDocumentName}   // SHe Pack Document Name
          shePackDocumentScore={selectedDocumentScore} // She Pack Document score
          shePackDocumentUserPercentage={selectedDocumentUserPercentage}
          questionsPerDocument={questionsPerDocument}
          questions={questions}
          submissionStatus={submissionStatus}
          
        />

    </div>
  );
};

export default QATemplateSubmissionComponent;