/* ---------- SHE Pack Fill In component ------------------

    -   This component is used for when the user is completing a checklist. 
*/

// ------------------ React Components ------------------------
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

// -------------------- API -----------------------------
import API_BASE_URL from "../../utils/api/apiConfig.js";

// ------------------- Application components --------------------
import ConfirmdeleteTableToaster from '../../toasterscomponents/checklistToasters/ConfirmdeleteTableToaster.js';

// --------------------- CSS File --------------------
import '../../../assets/css/Checklists.css';

// --------------------- icons --------------------
import { CiImageOn } from "react-icons/ci";
import { LiaSpinnerSolid } from "react-icons/lia";
import { BsFileEarmarkPdf } from "react-icons/bs";

import { TfiWrite } from "react-icons/tfi";
import { FaRegFolderOpen } from "react-icons/fa";

// --------------------- Assests -------------------
import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png';

import ToasterConsultantFill from './ToasterConsultantFill.js';



// --------------------- View SHE Pack component -------------------
const ConsultantFillInShepack = () => {

  // Retrieve user data from session storage
  const user = JSON.parse(sessionStorage.getItem('user'));

  const navigate = useNavigate();

  // --------------- State Managements ------------------------

  // New state for companies and departments
  const [consultantAssignments, setConsultantAssignments] = useState([]);

  const [shePackCompanies, setShePackCompanies] = useState({});
  const [selectedCompanies, setSelectedCompanies] = useState({});

  const [departmentsPerCompany, setDepartmentsPerCompany] = useState({});
  const [selectedDepartments, setSelectedDepartments] = useState({});

  //
  // First, modify the state to store questions per document
const [questionsPerDocument, setQuestionsPerDocument] = useState({});
// Store submission status per document
const [submissionStatusPerDocument, setSubmissionStatusPerDocument] = useState({});

  // State Management - Global 
  const [submissionStatus, setSubmissionStatus] = useState('Not Started');

  const [shePacks, setShePacks] = useState([]);

  // State Management - selected she pack
  const [selectedShePackName, setSelectedShePackName] = useState('');
  const [selectedShePack, setSelectedShePack] = useState(null);

  const [shePackDocuments, setShePackDocuments] = useState([]);
  const [selectedShePackId, setSelectedShePackId] = useState(null);

  // state management - slected document 
  // Add these state variables near your other state declarations
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [selectedDocumentName, setSelectedDocumentName] = useState('');
  const [selectedDocumentScore, setSelectedDocumentScore] = useState('');
  const [selectedDocumentUserPercentage, setSelectedDocumentUserPercentage] = useState('');


  //
  const [assignedShePacks, setAssignedShePacks] = useState([]);

  // state management - documnets
  const [showDocuments, setShowDocuments] = useState(false);
  
  //
  const [showTemplateToaster, setShowTemplateToaster] = useState(false);

  // state management - Questions toaster
  const [showQuestionToaster, setShowQuestionToaster] = useState(false);
  const [questions, setQuestions] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  
  

  // ------------------- Functions ---------------------------

  // Fetch assigned SHE packs and their companies
 // Fetch assigned SHE packs and their companies
 useEffect(() => {

    const fetchAssignedShePacks = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${API_BASE_URL}/api/she-pack/consultant/she-pack-fill-in/assigned-she-packs/${user.user_id}`);
        const processedShepacks = response.data.map(shepack => ({
          ...shepack,
          total_submissions: shepack.total_submissions 
            ? parseInt(shepack.total_submissions, 10) 
            : 0
        }));
        setAssignedShePacks(processedShepacks);

        // Fetch companies and their departments for each SHE pack
        for (const shepack of processedShepacks) {
          const companiesResponse = await axios.get(
            `${API_BASE_URL}/api/she-pack/she-pack-fill-in/consultant-assignments/company/${shepack.shepack_id}/${user.user_id}`
          );
          
          setShePackCompanies(prev => ({
            ...prev,
            [shepack.shepack_id]: companiesResponse.data
          }));

          // Store departments for each company
          const departmentsMap = {};
          companiesResponse.data.forEach(companyData => {
            departmentsMap[companyData.company] = companyData.departments;
          });
          
          setDepartmentsPerCompany(prev => ({
            ...prev,
            [shepack.shepack_id]: departmentsMap
          }));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAssignedShePacks();
  }, [user.user_id]);

  // Handle company selection
  // Modify handleCompanyChange to reset department when company changes
  const handleCompanyChange = (shePackId, company) => {
    setSelectedCompanies(prev => ({
      ...prev,
      [shePackId]: company
    }));
  
    // Reset selected department when company changes
    setSelectedDepartments(prev => ({
      ...prev,
      [shePackId]: ''
    }));
  };

  // Add handleDepartmentChange function
  const handleDepartmentChange = (shePackId, department) => {
    setSelectedDepartments(prev => ({
      ...prev,
      [shePackId]: department
    }));
  };

  // Fucntions - Global
  const handleNavigateBack = () => {
    navigate(-1);
  };

  

  // Update the handleClick function to include company and department
const handleClick = async (shePackId, shePackName) => {
    setIsLoading(true);
    setSelectedShePackId(shePackId);
    setSelectedShePackName(shePackName);
  
    // Get the selected company and department for this SHE pack
    const selectedCompany = selectedCompanies[shePackId];
    const selectedDepartment = selectedDepartments[shePackId];
  
    // Check if both company and department are selected
    if (!selectedCompany || !selectedDepartment) {
      alert('Please select both company and department first');
      setIsLoading(false);
      return;
    }
  
    try {
      console.log('Fetching documents for shePackId:', shePackId);
      const documentsResponse = await axios.get(
        `${API_BASE_URL}/she-pack/consultant/view-she-packs/view-assigned-documents/${shePackId}/${user.user_id}`,
        {
          params: {
            company: selectedCompany,
            department: selectedDepartment
          }
        }
      );
      
      console.log('Documents API Response:', documentsResponse.data);
      
      if (documentsResponse.status === 200) {
        if (Array.isArray(documentsResponse.data)) {
          setShePackDocuments(documentsResponse.data);
          setShowTemplateToaster(true);
  
          // Create an object to store questions for each document
          const allQuestionsPerDocument = {};
          const allSubmissionStatus = {};
  
          // Fetch questions for each document
          for (const document of documentsResponse.data) {
            const { shepack_document_id, shepack_document_name, shepack_document_score } = document;
            
            try {
              const questionsResponse = await axios.get(
                `${API_BASE_URL}/api/she-pack/consultant/she-pack-fill-in/assigned-she-packs/questions/${shepack_document_id}`,
                {
                  params: { 
                    userId: user.user_id,
                    company: selectedCompany,
                    department: selectedDepartment
                  }
                }
              );
  
              if (questionsResponse.data.questions && Object.keys(questionsResponse.data.questions).length > 0) {
                const groupedQuestions = Object.keys(questionsResponse.data.questions).length > 0 
                  ? questionsResponse.data.questions 
                  : groupQuestionsByCategory(questionsResponse.data.questions);
  
                allQuestionsPerDocument[shepack_document_id] = groupedQuestions;
                allSubmissionStatus[shepack_document_id] = questionsResponse.data.submission_status;
              }
            } catch (error) {
              console.error(`Error fetching questions for document ${shepack_document_id}:`, error);
              allQuestionsPerDocument[shepack_document_id] = {};
              allSubmissionStatus[shepack_document_id] = 'Error';
            }
          }
  
          setQuestionsPerDocument(allQuestionsPerDocument);
          setSubmissionStatusPerDocument(allSubmissionStatus);
        }
      }
    } catch (error) {
      console.error('Error fetching SHE Pack documents:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Add this function before your ShePackFillIn component definition
const groupQuestionsByCategory = (questions) => {
  return questions.reduce((grouped, question) => {
    const category = question.category || 'Uncategorized';
    if (!grouped[category]) {
      grouped[category] = [];
    }
    grouped[category].push(question);
    return grouped;
  }, {});
};
  /* */
  const handleCloseQuestionToaster = () => {
    setShowQuestionToaster(false);
  };

  //
  const handleClickFillInDocument = async (shepack_document_id, shepack_document_name, shepack_document_score, user_percentage) => {
    
    setIsLoading(true);
    setSelectedDocumentId(shepack_document_id);
    setSelectedDocumentName(shepack_document_name);
    setSelectedDocumentScore(shepack_document_score); // Make sure this gets the correct value
    setSelectedDocumentUserPercentage(user_percentage)
    
    try {
      const response = await axios.get(`${API_BASE_URL}/api/she-pack/consultant/she-pack-fill-in/assigned-she-packs/questions/${shepack_document_id}`, {
        params: { userId: user.user_id }
      });
  
      // Add console.log to debug
      console.log('Selected Document Score:', shepack_document_score);
      console.log('Response data:', response.data);
  
      if (response.data.questions && Object.keys(response.data.questions).length > 0) {
        const groupedQuestions = Object.keys(response.data.questions).length > 0 
          ? response.data.questions 
          : groupQuestionsByCategory(response.data.questions);
  
        setQuestions(groupedQuestions);
        setSubmissionStatus(response.data.submission_status);
       // setShowQuestionToaster(true);
      } else {
        console.error('No questions found for this checklist');
      }
    } catch (error) {
      console.error('Error fetching questions:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Function - Submissions 
  
  
  return (
    <div className="component-container">
      <div className="create-checklist-component-container">

        <div className="create-company-text-container">

          <div>
            <h1> Fill In SHE File </h1>
          </div>

          <div>
              <img src={blueKbcLogo} alt="white-kbc-logo" className="side-menu-logo" />
          </div>
          
        </div>

        <div className="navigate-back-button-main-container">
          <div className="navigate-back-button-container">
            <button className="navigate-back-button" onClick={handleNavigateBack}>Back</button>
          </div>
        </div>

        <div className="view-checklist-table-container">
          {isLoading ? (
            <div className="loading-spinner-container">
              <LiaSpinnerSolid className="lia-spin-solid-icon" />
            </div>
          ) : (
            <div className="view-checklist-table-table-container">
              <table className="view-checklist-table">

                <colgroup>
                  {/* Remove whitespace between col elements */}
                  <col style={{width: "300px"}}/>{/* Select SHE Pack */}
                  <col style={{width: "350px"}}/>{/* SHE Pack Name */}       
                  <col style={{width: "200px"}}/>{/* SHE Pack Score */} 
                  <col style={{width: "200px"}}/>{/* Company */} 
                  <col style={{width: "200px"}}/>{/* Department */} 
                       
                </colgroup>

                <thead>
                  <tr>
                    
                    <th className="table-header">SHE File Name</th>
                    {/* <th className="table-header">SHE Pack Score</th>*/}
                    <th className="table-header">Company</th>
                    <th className="table-header">Site</th>
                    <th className="table-header">Select SHE File</th>
                    
                    
                    
                  </tr>
                </thead>

                <tbody className="view-table-body">

                  {assignedShePacks && assignedShePacks.length < 0 ? ( 
                    <tr>
                      <td colSpan="5">
                        <p>Currently no SHE Packs created. Click "Create SHE Pack" button to create a SHE Pack.</p>
                        
                        <Link to='/she-pack/create-she-pack'>
                          <div className='view-checklist-create-checklist-button-contianer'>
                            <p>Create SHE Pack</p>
                          </div>
                        </Link>

                      </td>
                    </tr>
                  ) : (
                    assignedShePacks.map((shePack, index) => (

                      <tr key={index}>

                        

                        {/* column - SHE Pack name */}
                        <td>{shePack.shepack_name}</td>

                        {/* column - SHE Pack score  
                        <td >
                          <p> {shePack.shepack_score !== null ? shePack.shepack_score : 'No Score'}</p>
                        </td>
                        */}

                        {/* column - Compnay Name  */}
                        <td >
                        <select
                          className="company-dropdown"
                          value={selectedCompanies[shePack.shepack_id] || ''}
                          onChange={(e) => handleCompanyChange(shePack.shepack_id, e.target.value)}
                      >
                          <option value="">Select Company</option>
                          {shePackCompanies[shePack.shepack_id]?.map((companyData, idx) => (
                              <option key={idx} value={companyData.company}>
                                  {companyData.companyName}
                              </option>
                          ))}
                      </select>
                        </td>

                        {/* column - Department name  */}
                        <td>
                        <select
                          className="department-dropdown"
                          value={selectedDepartments[shePack.shepack_id] || ''}
                          onChange={(e) => handleDepartmentChange(shePack.shepack_id, e.target.value)}
                          disabled={!selectedCompanies[shePack.shepack_id]}
                      >
                          <option value="">Select Site</option>
                          {selectedCompanies[shePack.shepack_id] && 
                              departmentsPerCompany[shePack.shepack_id]?.[selectedCompanies[shePack.shepack_id]]?.map((department, idx) => (
                                  <option key={idx} value={department.id}>
                                      {department.name}
                                  </option>
                          ))}
                      </select>
                        </td>

                        {/* column - view SHE pack */}
                        <td onClick={() => handleClick(shePack.shepack_id, shePack.shepack_name)}>
                          <FaRegFolderOpen className='view-checklist-submition-icon' />
                        </td>

                      
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>

      
      </div>

     

        <ToasterConsultantFill
          isOpen={showTemplateToaster}
          onClose={() => setShowTemplateToaster(false)}
          selectedShePackName={selectedShePackName}
          shePackDocuments={shePackDocuments}
          isLoading={isLoading}
          onFillInDocument={handleClickFillInDocument}
          
          selectedCompany={selectedCompanies[selectedShePackId]} // Pass specific company
          selectedDepartment={selectedDepartments[selectedShePackId]} // Pass specific department

          shePackDocumentId={selectedDocumentId}      // She Pack Document Id
          shePackDocumentName={selectedDocumentName}   // SHe Pack Document Name
          shePackDocumentScore={selectedDocumentScore} // She Pack Document score
          shePackDocumentUserPercentage={selectedDocumentUserPercentage}
          questionsPerDocument={questionsPerDocument}
          questions={questions}
          submissionStatus={submissionStatus}
          
        />

        

      

      

     
     
      
        
      


    </div>
  );
};

export default ConsultantFillInShepack;