// ------------------ React Components ------------------------
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CiImageOn, CiSearch } from "react-icons/ci";
import { LiaSpinnerSolid } from "react-icons/lia";
import { MdDeleteOutline } from "react-icons/md";
import { FaRegSave, FaClipboardList, FaSpinner } from 'react-icons/fa';
import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png';
import ItemAddedToaster from '../../toasterscomponents/checklistToasters/ItemAddedToaster';
import FilledInChecklistToaster from '../../toasterscomponents/FilledInChecklistToaster';
import CreatedSuccesFullyToaster from '../../toasterscomponents/shepacktoasters/CreatedSuccesFullyToaster';
import QARejectToaster from './QARejectToaster';

const ToasterQaViewSubmissions = ({ 
  isOpen, 
  onClose, 
  selectedShePackName,
  shePackDocuments,
  isLoading,
  questionsPerDocument,

  selectedCompany,
  selectedDepartment// Add this prop

}) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const [showToaster, setShowToaster] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [answers, setAnswers] = useState({});
  const [previewPhoto, setPreviewPhoto] = useState(null);
  const [isPreviewExpanded, setIsPreviewExpanded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [questionErrors, setQuestionErrors] = useState({});
  const [questionCategoryErrors, setQuestionCategoryErrors] = useState({});
  const [expiryDateInput, setExpiryDateInput] = useState({});
  const [expandedDocuments, setExpandedDocuments] = useState({});
  const [comments, setComments] = useState({});
  const [questionScores, setQuestionScores] = useState({});

  // Add this state to track the current document ID
  const [currentDocumentId, setCurrentDocumentId] = useState(null);
  const [showQARejectToaster, setShowQARejectToaster] = useState(false)

  const user = JSON.parse(sessionStorage.getItem('user'));

  //
  const calculateUpdatedDocumentScore = (documentId) => {
    let totalDocumentScore = 0;
    const documentQuestions = questionsPerDocument[documentId];
    
    if (documentQuestions) {
      Object.values(documentQuestions).forEach(categoryQuestions => {
        categoryQuestions.forEach(question => {
          const score = questionScores[question.question_id];
          // Only include question score if it's not marked as Not Applicable
          if (score !== 'Not Applicable') {
            totalDocumentScore += parseInt(question.question_score);
          }
        });
      });
    }
    
    return totalDocumentScore;
  };
  

  useEffect(() => {
    // Initialize previous responses when questions are loaded
    if (questionsPerDocument) {
      Object.entries(questionsPerDocument).forEach(([documentId, categories]) => {
        Object.values(categories).forEach(questions => {
          questions.forEach(question => {
            // Handle previous comments
            if (question.previous_comment) {
              setComments(prev => ({
                ...prev,
                [question.question_id]: question.previous_comment
              }));
            }

            // Initialize scores with "Not Selected" if no previous score exists
            if (question.previous_user_question_score === undefined) {
              setQuestionScores(prev => ({
                ...prev,
                [question.question_id]: "Not Selected"
              }));
            } else {
              setQuestionScores(prev => ({
                ...prev,
                [question.question_id]: question.previous_user_question_score
              }));
            }
          });
        });
      });
    }

  }, [questionsPerDocument]);

  
  useEffect(() => {
    const timers = Object.keys(showToaster).map(key => showToaster[key] && setTimeout(() => setShowToaster(prev => ({ ...prev, [key]: false })), 3000));
    return () => timers.forEach(timer => clearTimeout(timer));
  }, [showToaster]);

  const toggleDocument = (documentId) => {
    setExpandedDocuments(prev => ({ ...prev, [documentId]: !prev[documentId] }));
  };

  const handleAnswerChange = (questionId, answer, questionType) => {
    setAnswers(prev => {
      if (questionType === 'Multiple Select') {
        const currentAnswers = Array.isArray(prev[questionId]) ? prev[questionId] : [];
        return { ...prev, [questionId]: currentAnswers.includes(answer) ? currentAnswers.filter(item => item !== answer) : [...currentAnswers, answer] };
      } else {
        return { ...prev, [questionId]: answer };
      }
    });
  };

  const handleFileChange = (questionId, event) => {
    const file = event.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'application/pdf') && file.size <= 10 * 1024 * 1024) {
      handleAnswerChange(questionId, file);
      setPreviewPhoto(file.type.startsWith('image/') ? URL.createObjectURL(file) : null);
    } else {
      setErrorMessage('Only PNG, JPEG, and PDF files less than 10MB are allowed.');
    }
  };

  const handleRemoveFile = (questionId) => {
    handleAnswerChange(questionId, '');
    setPreviewPhoto(null);
    document.querySelectorAll('input[type="file"]').forEach(input => input.value = '');
  };

  const handleCommentChange = (questionId, comment) => {
    setComments(prev => ({
      ...prev,
      [questionId]: comment
    }));
  };


  const handleIsExpiryDateChange = (questionId, isExpiryDate) => {
    setExpiryDateInput(prev => ({ ...prev, [questionId]: isExpiryDate }));
  };

  const handleScoreChange = (questionId, score) => {
    setQuestionScores(prev => ({
      ...prev,
      [questionId]: score
    }));
  };

  const generateScoreOptions = (maxScore) => {
    const options = [<option key="not-selected" value="Not Selected">Not Selected</option>];
    for (let i = 0; i <= maxScore; i += 5) {
      options.push(<option key={i} value={i.toString()}>{i}</option>);
    }
    options.push(<option key="not-applicable" value="Not Applicable">Not Applicable</option>);
    return options;
  };

  const isOptionSelected = (questionId, option) => {
    const answer = answers[questionId];
    return Array.isArray(answer) ? answer.includes(option) : answer === option;
  };

  //
  // Add this new function in the ToasterQaViewSubmissions component
const handleReject = (documentId) => {
  setCurrentDocumentId(documentId); // Store the current document ID
  setShowQARejectToaster(true); // Show the reject toaster
};

// Add this new function to process the rejection
const processRejection = async (reason, documentId) => {
  setSubmitStatus('loading');
  try {
    const currentDocument = shePackDocuments.find(doc => doc.shepack_document_id === documentId);
    if (!currentDocument) throw new Error('Document not found');

    const formData = new FormData();
    formData.append('shePackDocumentId', documentId);
    formData.append('shePackDocumentName', currentDocument.shepack_document_name);
    formData.append('userId', user.user_id);
    formData.append('userName', user.userName);
    formData.append('userSurname', user.userSurname);
    formData.append('userType', user.userType);
    formData.append('companyId', selectedCompany);
    formData.append('departmentId', selectedDepartment);
    formData.append('rejectionReason', reason);

    // Make API call to reject the submission
    await axios.post(
      '/api/quality-assurance/view-template-submissions/reject-submission',
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    );

    setSubmitStatus('success');
    // Show success message
    setShowToaster(prev => ({ ...prev, rejectionSubmitted: true }));
    // Close the toaster after a delay
    setSubmitStatus('success');
      setShowSuccess(true);
      setTimeout(() => {
      
        navigate('/she-pack/she-pack-management');
      }, 2000);
  } catch (error) {
    setSubmitStatus('idle');
    const errorMsg = error.response?.data?.message 
      ? `Server error: ${error.response.data.message}`
      : error.request 
        ? 'No response from server. Please check your connection.'
        : `Error: ${error.message}`;
    setErrorMessage(errorMsg);
  }
};

  
  
  

  const handleSubmit = async (documentId) => {
    setErrorMessage('');
    setSubmitStatus('loading');

    const currentDocument = shePackDocuments.find(doc => doc.shepack_document_id === documentId);
  if (!currentDocument) {
    setErrorMessage('Document not found');
    setSubmitStatus('idle');
    return;
  }

  const documentQuestions = questionsPerDocument[documentId];
  if (!documentQuestions) {
    setErrorMessage('No questions found for this document');
    setSubmitStatus('idle');
    return;
  }

    // Validate all questions have valid scores
    const invalidScores = [];
    Object.entries(documentQuestions).forEach(([category, questions]) => {
      questions.forEach(question => {
        const score = questionScores[question.question_id];
        if (!score || score === 'Not Selected') {
          invalidScores.push(question.question_name);
        }
      });
    });

    if (invalidScores.length > 0) {
      setSubmitStatus('error');
      setErrorMessage(`Please select a score or mark as Not Applicable for the following questions: ${invalidScores.join(', ')}`);
      return; // Prevent submission if any question has Not Selected
    }
  
    try {
      // Calculate updated document score excluding Not Applicable questions
    let updatedDocumentScore = 0;
    let userTotalScore = 0;

      const currentDocument = shePackDocuments.find(doc => doc.shepack_document_id === documentId);
      if (!currentDocument) throw new Error('Document not found');
      const documentQuestions = questionsPerDocument[documentId];
      if (!documentQuestions) throw new Error('No questions found for this document');
      const unansweredMandatoryQuestions = [];

      Object.entries(documentQuestions).forEach(([category, questions]) => {
        questions.forEach(q => {
          const questionScore = questionScores[q.question_id];
          // Only include in updated document score if not marked as Not Applicable
          if (questionScore !== 'Not Applicable') {
            updatedDocumentScore += parseInt(q.question_score);
          }
          // Calculate user's total score
          if (questionScore !== 'Not Applicable' && questionScore !== 'Not Selected') {
            userTotalScore += parseInt(questionScore);
          }
        });
      });

      if (unansweredMandatoryQuestions.length > 0) {
        setSubmitStatus('error');
        setErrorMessage(`Please answer all mandatory questions: ${unansweredMandatoryQuestions.join(', ')}`);
        return;
      }
      const formData = new FormData();
      formData.append('shePackDocumentId', documentId);
      formData.append('shePackDocumentName', currentDocument.shepack_document_name);
      formData.append('userId', user.user_id);
      formData.append('userName', user.userName);
      formData.append('userSurname', user.userSurname);
      formData.append('userType', user.userType);
      formData.append('companyId', selectedCompany);
      formData.append('departmentId', selectedDepartment);

      formData.append('userTotalScore', currentDocument.user_score );
      formData.append('shepackDocumentscore', currentDocument.shepack_document_score );
      formData.append('updatedShePackDocumentScore', updatedDocumentScore.toString());
      
      formData.append('totalUserScorePercentage', currentDocument.user_percentage );

      const allAnswers = [];
      Object.entries(documentQuestions).forEach(([category, questions]) => {
        questions.forEach(question => {
          const answer = answers[question.question_id];
          const answerData = {
            questionId: question.question_id,
            questionName: question.question_name,
            questionType: question.question_type,
            category: category,
            answer: '',
            questionScore: questionScores[question.question_id],
            comment: comments[question.question_id] || '',
            expiry_date: expiryDateInput[question.question_id] || '',
            companyId: selectedCompany,  // Add this
            departmentId: selectedDepartment,  // Add this
          };
          if (question.question_type === 'Multiple Select') {
            answerData.answer = Array.isArray(answer) ? answer.join(', ') : answer || '';
          } else if ((question.question_type === 'File' || question.question_type === 'Photo') && answer instanceof File) {
            formData.append(`files[${question.question_id}]`, answer);
            answerData.answer = answer.name;
          } else {
            answerData.answer = answer || '';
          }
          allAnswers.push(answerData);
        });
      });
      formData.append('answers', JSON.stringify(allAnswers));
      await axios.post('/api/quality-assurance/consultant/submit-approved-template', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setSubmitStatus('success');
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        navigate('/she-pack/she-pack-management');
      }, 2000);
    } catch (error) {
      setSubmitStatus('idle');
      const errorMsg = error.response?.data?.message 
        ? `Server error: ${error.response.data.message}`
        : error.request 
          ? 'No response from server. Please check your connection.'
          : `Error: ${error.message}`;
      setErrorMessage(errorMsg);
    }
  };

  const renderQuestionOptions = (question) => {
    const options = ['option_1', 'option_2', 'option_3', 'option_4', 'option_5', 'option_6', 'option_7', 'option_8', 'option_9', 'option_10', 'option_11', 'option_12', 'option_13', 'option_14', 'option_15'];
    switch (question.question_type) {
      
     
      case 'File':
      case 'Photo':
        return (
          <div className='file-upload-container'>
            <p className='secondary-color-paragraph'>Please upload a {question.question_type}. File should not exceed 10MB</p>
            <input
              className='file-upload-input'
              type="file"
              accept={question.question_type === 'File' ? ".png,.jpg,.jpeg,.pdf" : "image/png, image/jpeg"}
              onChange={(e) => handleFileChange(question.question_id, e)}
            />
            {answers[question.question_id] && (
              <div>
                {answers[question.question_id].type.startsWith('image/') && (
                  <div className='checklist-question-toaster-image-delete-button-container'>
                    <CiImageOn className='checklist-question-toaster-image-icon' onClick={() => setIsPreviewExpanded(!isPreviewExpanded)} />
                    <button type='button' className='delete-image-button' onClick={() => handleRemoveFile(question.question_id)}> <MdDeleteOutline /> </button>
                  </div>
                )}
                {isPreviewExpanded && previewPhoto && (
                  <img onClick={() => setIsPreviewExpanded(false)} src={previewPhoto} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                )}
              </div>
            )}
          </div>
        );
      case 'Short Text':
        return (
          <div className='file-upload-container'>
            <input
              className="create-questions-form-name-input"
              type="text"
              value={answers[question.question_id] || ''}
              onChange={(e) => handleAnswerChange(question.question_id, e.target.value)}
              placeholder="Please enter your response"
            />
          </div>
        );
      case 'Time':
        return (
          <div className='file-upload-container'>
            <input
              type="time"
              value={answers[question.question_id] || ''}
              onChange={(e) => handleAnswerChange(question.question_id, e.target.value)}
            />
          </div>
        );
      case 'Date':
        return (
          <div className='file-upload-container'>
            <input
              type="date"
              value={answers[question.question_id] || ''}
              onChange={(e) => handleAnswerChange(question.question_id, e.target.value)}
              max={new Date().toISOString().split('T')[0]}
              className='checklist-question-toaster-date'
            />
          </div>
        );
      case 'Drop Down':
        return (
          <div className='file-upload-container'>
            <select
              value={answers[question.question_id] || ''}
              onChange={(e) => handleAnswerChange(question.question_id, e.target.value)}
            >
              <option value="">Select an option</option>
              {options.map((option, optionIndex) => (
                question[option] && (
                  <option key={optionIndex} value={question[option]}>
                    {question[option]}
                  </option>
                )
              ))}
            </select>
          </div>
        );
      default:
        return null;
    }
  };

  if (!isOpen) return null;

  return (

    <div className="checklist-question-user-fill-in-checklist-main-container">
        
        <div className="template-question-fill-in-checklist-container">
        <div className="template-fill-in-elements-container">
          <div className="user-fill-in-back-button-time-container">
            <div className='user-fill-in-back-button-container'>
              <img src={blueKbcLogo} alt="white-kbc-logo" className="add-quesiton-side-menu-logo" />
              <button type="button" onClick={onClose} className="user-fill-in-back-button"> Back</button>
            </div>
          </div>
          
          <div className="Template-fill-in-header-main-container">
            <div className="Template-fill-in-header-text-and-search-main-container">

              <div className="Template-fill-in-header-text-main-container">
                <h2>Quality Assurance - Review Consultant Submissions </h2>
                
                <h2>SHE File: <span style={{ color: 'black' }}>{selectedShePackName} </span></h2>
                <p>Selected Company {selectedCompany} , Selected Department {selectedDepartment}</p>
              </div>

              {/*

                <div className="Template-fill-in-header-search-main-container">

                  <div>
                    <h4 className='secondary-color-text'>Quick Questions Search</h4>
                    <div className="Template-fill-in-header-search-search-bar-container">
                      <input 
                        placeholder='Enter question name'
                        className='template-fill-in-search-bar'
                      />
                      <button className='confirm-button'>
                        <CiSearch />
                      </button>
                    </div>
                  </div>

                </div>
               
              
              */}

              
            </div>
            {isLoading ? (
              <div className="loading-spinner-container">
                <LiaSpinnerSolid className="lia-spin-solid-icon" />
              </div>
            ) : (
              <div className='template-questions-user-fill-in-main-container'>
                <div className="template-table-main-container">
                  <div className='template-table-main-header-container'>
                    <div className='template-table-header-container'>
                      <div className="template-table-header">View Questions</div>
                      <div className="template-table-header">Document Name</div>
                      <div className="template-table-header">Document Score</div>
                      <div className="template-table-header">Percentage</div>
                      <div className="template-table-header">Status</div>
                      <div className="template-table-header">QA Status</div>
                      <div className="template-table-header">QA Status Comment</div>
                    </div>
                  </div>
                  <div className='template-table-body'>
                    {shePackDocuments.length === 0 ? (
                      <div className='template-table-body-row-main-container'>
                        <div className='template-table-body-row-container'>
                          <i className='template-table-body-row-container-text'>There are no documents submitted for this SHE Pack that are currently ready for QA review.</i>
                        </div>
                      </div>
                    ) : (
                      shePackDocuments.map((document, index) => (
                        <div className='template-table-body-row-main-container' key={index}>
                          <div className='template-table-body-row-container'>

                            <div className='template-table-body-row-td'>
                              <FaClipboardList 
                                className='view-checklist-submition-icon' 
                                onClick={() => toggleDocument(document.shepack_document_id)}
                                
                              />
                            </div>


                            <div className='template-table-body-row-td'>{document.shepack_document_name}</div>
                            <div className='template-table-body-row-td'>  {document.user_score} / {document.effective_document_score || document.shepack_document_score}</div>
                            <div className='template-table-body-row-td'>{document.user_percentage } %</div>
                            <div className='template-table-body-row-td'>{document.submission_status || 'No submission'}</div>
                            <div className='template-table-body-row-td'>{document.qa_status }</div>
                            <div className='template-table-body-row-td'>{document.qa_status_comment }</div>
                          </div>
                          {expandedDocuments[document.shepack_document_id] && (
  <div className='template-questions-fill-in-main-container'>
    {questionsPerDocument[document.shepack_document_id] && 
      Object.entries(questionsPerDocument[document.shepack_document_id]).map(([category, categoryQuestions]) => (
        <div key={category}>  
          <div className='template-questions-fill-in-container'>
            <div className='checklist-question-toaster-user-fill-in-checklist-element-category-save-container'>
              <h2>Category {category}</h2>

              {/*
              <div 
                className='save-questions-category-category-button-container'
                onClick={() => handleSaveCategory(category, document.shepack_document_id)}
              >
                <div>Approve Category</div>
                <div>
                  <FaRegSave className='checklist-fillin-button-question-name-and-save-icon-rendering-save-category-icon' />
                </div>
              </div>
              
              */}
              
            </div>
            
            {questionCategoryErrors[category] && (
              <div className='error-text'>
                <ul>
                  {questionCategoryErrors[category].map((error, index) => (
                    <li key={index} className='category-error-item'>{error}</li>
                  ))}
                </ul>
              </div>
            )}
            
            <div className="checklist-question-toaster-user-fill-in-checklist-category-container">
  <div className='template-questions-fill-in-individal-questions-main-container'>
    <table className="question-table">

    


      <thead>
        <tr>
          <th>Question Name</th>
          <th>Question Reference</th>
          <th>Consultants Score</th>
          <th>Consusltants Additional Info</th>
          <th>Consultants File Upload</th>

          {/*<th>Actions</th> */}
          
        </tr>
      </thead>
      <tbody>
        {categoryQuestions.map((question, index) => (
          <React.Fragment key={index}>
            {questionErrors[question.question_id] && (
              <tr>
                <td colSpan={6} className='error-text'>
                  <p>{questionErrors[question.question_id]}</p>
                </td>
              </tr>
            )}
            <tr className='question-table-row'>
              <td>
                <p>{question.question_name}</p>
                <p className='confidential' style={{ fontSize: '10px' }}>{question.question_id}</p>
                <p className='confidential' style={{ fontSize: '10px' }}>{question.checklist_id}</p>
                <div className='classNamechecklist-question-toaster-user-fill-in-checklist-question-element-options-container'>
                  {renderQuestionOptions(question)}
                </div>
              </td>
              <td>
                <span className='secondary-color-text'>{question.question_reference}</span>
                <p style={{ color: 'red', fontSize: '12px' }}>* Mandatory</p>
              </td>
              <td>
                <div className='she-pack-fill-in-question-score-container'>
                  <select
                    value={questionScores[question.question_id] || 'Not Selected'}
                    onChange={(e) => handleScoreChange(question.question_id, e.target.value)}
                    className={questionErrors[question.question_id] ? 'error-border' : ''}
                  >
                    {generateScoreOptions(parseInt(question.question_score))}
                  </select>
                  <span>/{question.question_score}</span>
                </div>
              </td>
              <td>
                {question.comment === 'Yes' && (
                  <div className="additional-info-container">
                    <p className='secondary-color-paragraph'><i>Consultants Comment:</i></p>
                    <textarea
                      placeholder='No comments provided from the consultant'
                      rows={2}
                      cols={20}
                      maxLength={200}
                      value={comments[question.question_id] || ''}
                      onChange={(e) => handleCommentChange(question.question_id, e.target.value)}
                    />
                    <p><i>Max 1000 chars</i></p>
                  </div>
                )}
                {question.expires === 'Yes' && (
                  <div>
                    <p className='secondary-color-paragraph'><i>Expiry Date:</i></p>
                    <input
                      type="date"
                      onChange={(e) => handleIsExpiryDateChange(question.question_id, e.target.value)}
                      value={expiryDateInput[question.question_id] || ''}
                    />
                  </div>
                )}
              </td>
              <td>
                <p className='secondary-color-paragraph'>Upload {question.question_type}</p>
                <input
                  className='file-upload-input'
                  type="file"
                  accept={question.question_type === 'File' ? ".png,.jpg,.jpeg,.pdf" : "image/png, image/jpeg"}
                  onChange={(e) => handleFileChange(question.question_id, e)}
                />
                {answers[question.question_id] && (
                  <div>
                    {answers[question.question_id].type.startsWith('image/') && (
                      <div className='checklist-question-toaster-image-delete-button-container'>
                        <CiImageOn className='checklist-question-toaster-image-icon' onClick={() => setIsPreviewExpanded(!isPreviewExpanded)} />
                        <button type='button' className='delete-image-button' onClick={() => handleRemoveFile(question.question_id)}><MdDeleteOutline /></button>
                      </div>
                    )}
                    {isPreviewExpanded && previewPhoto && (
                      <img onClick={() => setIsPreviewExpanded(false)} src={previewPhoto} alt="Preview" style={{ maxWidth: '150px', maxHeight: '150px' }} />
                    )}
                  </div>
                )}
              </td>

              {/* <td>
                <div className='save-questions-category-button-container' onClick={() => handleSaveIndividualQuestion(question.question_id)}>
                  <div>Approve Question</div>
                  <FaRegSave className='checklist-fillin-button-question-name-and-save-icon-rendering-save-category-icon' />
                </div>
              </td>*/}
              
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
    <div className='Total_document_score_div'>
      <p>Total Document Score {document.user_score} / {document.effective_document_score || document.shepack_document_score}</p>
    </div>
  </div>
</div>
          </div>

          <div>
            {errorMessage && (
              <div className='error-text'>
                <p>{errorMessage}</p>
              </div>
            )}
            <p>By clicking 'Submit Approved Template,' you confirm that you have reviewed and approved the template after completing the QA process.</p>
            
            <div className='checklist-question-toaster-button-cancel-container'>
              <button 
                onClick={() => handleSubmit(document.shepack_document_id)}
                className="confirm-button" disabled={submitStatus === 'loading'}>
                {submitStatus === 'loading' ? (
                  <>
                    <FaSpinner className="fa-spin" />
                    <span className="submitting-questions-text"> Submitting questions...</span>
                  </>
                ) : (
                  'Submit Approved Template'
                )}
              </button>

              <button 
                onClick={() => handleReject(document.shepack_document_id)}
                className="reject-button" 
                disabled={submitStatus === 'loading'}
              >
                {submitStatus === 'loading' ? (
                  <>
                    <FaSpinner className="fa-spin" />
                    <span className="submitting-questions-text"> Processing...</span>
                  </>
                ) : (
                  'Reject Template'
                )}
              </button>

            
            </div>
          </div>
        </div>
      ))}
  </div>
)}
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {showToaster.questionSaved && <ItemAddedToaster item={'Question Saved'} />}
      {showToaster.categorySaved && <ItemAddedToaster item={'Category Saved'} />}

      {showSuccess && 
        
        <CreatedSuccesFullyToaster
          heading='Template Qauality Assure Review Successfull'
        />
      }

      {showQARejectToaster && (
        <QARejectToaster
          onClose={() => setShowQARejectToaster(false)}
          onSubmit={processRejection}
          documentId={currentDocumentId}
        />
      )}
        
    </div>
  );
};

export default ToasterQaViewSubmissions;