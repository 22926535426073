/* ---------- Create SHE Pack component ------------------

    -   This component is used for when the user wants to create a custom SHE Pack. 
*/


// ------------------ React Components ------------------------
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// -------------------- API -----------------------------
import API_BASE_URL from "../../utils/api/apiConfig.js";

// --------------------- icons --------------------
import { FaSpinner } from "react-icons/fa";


// ----------------------- CSS File -------------
import '../../../assets/css/ShePack.css'


// --------------------- Assests -------------------
import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png'; 

// ------------------- Application components --------------------
import ItemAddedToaster from "../../toasterscomponents/checklistToasters/ItemAddedToaster.js";
import ItemRemovedToaster from "../../toasterscomponents/checklistToasters/ItemRemovedToaster.js";
import CreatedSuccesFullyToaster from "../../toasterscomponents/shepacktoasters/CreatedSuccesFullyToaster.js";


// --------------------- Create SHE Pack component -------------------
const CreateSheFileComponent = () => {

    //
    const user = JSON.parse(sessionStorage.getItem('user'));

    // --------------- State Managements ------------------------

    // state management - shePackName 
    const [shePackName, setShePackName] = useState("");


    // state management - section Create questions 
    const [submitStatus, setSubmitStatus] = useState('idle');
    const [errors, setErrors] = useState([]);
    
    const [showToaster, setShowToaster] = useState({ success: false, categoryAdded: false, rowAdded: false, rowRemoved: false, optionAdded: false, editedCategory: false, cancelEditedCategory: false });

    //
    const navigate = useNavigate();

    

    useEffect(() => {
        const timers = Object.keys(showToaster).map(key => showToaster[key] && setTimeout(() => setShowToaster(prev => ({ ...prev, [key]: false })), 3000));
        return () => timers.forEach(timer => clearTimeout(timer));
    }, [showToaster]);

    
    const handleReset = () => {
        // Reset all form-related states to their initial state
        setShePackName("");
        setErrors([]);
        setSubmitStatus('idle')
        
    };

    // Handle Submit all Questions
    const handleSubmit = async () => {

        setSubmitStatus('loading');
        setErrors([]);

        let newErrors = [];

        if (!shePackName.trim()) {
            newErrors.push("Empty Field - SHE pack name cannot be empty.");
            setErrors(newErrors);
            setSubmitStatus('error');
            return;
        }

        try {
            if (!user || !user.user_id) {
                throw new Error('User session data is missing');
            }
            
            const response = await axios.post(`${API_BASE_URL}/compliance-tracker/create-she-file`, {
                formName: shePackName,
                created_by: user.user_id, 
            });

            console.log('She pack created and data submitted successfully:', response.data);

            setShowToaster(prev => ({ ...prev, success: true }));
            setSubmitStatus('success');
            setTimeout(() => {
                
                setShowToaster(prev => ({ ...prev, success: false }));
                navigate('/compliance-tracker/compliance-tracker-management');

            }, 2000);

        } catch (error) {

            console.error('Error submitting data:', error);

            setErrors(prevErrors => [
                ...prevErrors, 
                error.response && error.response.status === 409 
                    ? 'Error - Duplicate SHE Pack Name. There\'s already a she pack with the existing name.' 
                    : `Error submitting data: ${error.message}`
            ]);

            setSubmitStatus('error');
        }
    };

    

    return (

        <div className="create-she-pack-main-component-container">
            
            <div className="she-pack-custom-field-component-container">

                <div className="create-she-pack-custom-field-container">

                    <div className="create-she-pack-view-she-pack-component-container">
                        
                        <div className="create-she-pack-view-she-pack-header-container">

                            <div>
                                <img src={blueKbcLogo} alt="white-kbc-logo" className="add-quesiton-side-menu-logo" />
                            </div>

                            <div className="navigate-back-button-container">
                                <button 
                                    className="navigate-back-button" 
                                    onClick={() => navigate(-1)}
                                >
                                    Back
                                </button>
                            </div>

                        </div>

                        <div className="create-she-pack-view-she-pack-header-step-container">
                            <div>
                                <h2>Compiance Tracker <span className="secondary-color">Create SHE File</span></h2>
                                <p>Create a custom she file tailored to your needs.</p>
                                <p className="secondary-color-paragraph">Please fill the SHE file name.</p>
                            </div>
                        </div>

                        <div className="create-she-pack-name-main-container">

                            <div className="create-she-pack-name-container">

                                <div>
                                    <p >SHE File Name</p>
                                </div>

                                <div>
                                    <input
                                        value={shePackName}
                                        onChange={(e) => setShePackName(e.target.value)}
                                        placeholder="Enter she file name"
                                        className="create-she-pack-input-field"
                                    />
                                </div>

                            </div>

                        </div>

                        {/* Erros */}
                        <div>
                            {errors.map((error, index) => (
                                <div className="error-text-main-container">
                                <   p key={index} >{error}</p>
                                </div>
                            ))}
                        </div>

                        {/* Button and text cont */}
                        <div>

                            <div style={{ textAlign: 'center' }}>
                                <p>By clicking 'Create SHE File' SHE File will be created.</p>
                            </div>
                            
                            <div className="add-question-submit-clear-button-container">
                                <button onClick={handleSubmit} className="confirm-button" disabled={submitStatus === 'loading'}>
                                    {submitStatus === 'loading' ? (
                                        <>
                                            <FaSpinner className="fa-spin" />
                                            <span className="submitting-questions-text"> Create SHE File...</span>
                                        </>
                                    ) : (
                                        'Create SHE File'
                                    )}
                                </button>
                                <button onClick={handleReset} className="cancel-button">Reset</button>
                            </div>

                        </div>

                        
                    </div>
                </div>

            </div>

            
            {showToaster.rowAdded && <ItemAddedToaster item={'Question Row Added'} />}

            {showToaster.rowRemoved && <ItemRemovedToaster item={'Question Row Deleted'} />}

            {showToaster.optionAdded && <ItemAddedToaster item={'Option Added'} />}

            {showToaster.categoryAdded && <ItemAddedToaster item={'Category Added'} />}

            {showToaster.editedCategory && <ItemAddedToaster item={'Category name edited'} />}

            {showToaster.cancelEditedCategory && <ItemAddedToaster item={'Category Edit Cancelled'} />}

            {/* Created Succesfully Toaster - Toaster shows when the user has created a document succesfully */}
            {showToaster.success && 
                <CreatedSuccesFullyToaster
                    heading='SHE File Created Successfully'
                />
            }
            
        </div>
    );
};

export default CreateSheFileComponent;