// ------------------- React Components --------------------
import React, { useState, useEffect, lazy } from 'react';
import { Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';


// -------------- Application Components ----------------------------
import LoadingComponents from './components/loadingComponents/LoadingComponents';
import DataAnalytics from './components/DataAnalyticsComponents/DataAnalytics.js/DataAnayltics';
import AddMSPowerBIComponent from './components/DataAnalyticsComponents/AddMSPowerBiLink.js/AddMSPowerBIComponent';
import ViewPowerBiLinks from './components/DataAnalyticsComponents/ViewPowerBiLink/ViewPowerBiLink';
import AddDepartmentComponent from './components/manageuserscomponents/addDepartmentComponent/AddDepartmentComponent';

import CreateShePackComponent from './components/shepackmanagementcomponents/createshepackcomponents/CreateShePack';
import CreateShePackDocuments from './components/shepackmanagementcomponents/createshepackdocumentcomponents/CreateShePackDocuments';
import ViewShePack from './components/shepackmanagementcomponents/viewshepackcomponent/ViewShePack';
import ShePackFillIn from './components/shepackmanagementcomponents/shepackfillin/ShePackFillIn';
import SheManagement from './components/shepackmanagementcomponents/shepack';
import AssignConsultantComponent from './components/manageuserscomponents/assignuserscomponents copy/AssignConsultantComponent';
import ConsultantFillInShepack from './components/shepackmanagementcomponents/ConsultantFillInShepack/ConsultantFillInShepack';
import ViewTemplateSubmissionComponent from './components/shepackmanagementcomponents/ViewTemplateSubmissions/ViewTemplateSubComponent';
import ToasterViewSHESubmissionComponents from './components/shepackmanagementcomponents/ViewSheSubmissions/ToasterViewSHESubmissions';
import ViewSHESubmissionComponent from './components/shepackmanagementcomponents/ViewSheSubmissions/ViewSHESubComponent';

import DuplicateTemplateComponent from './components/shepackmanagementcomponents/DuplicateTemplateComponents/DuplicateTemplateComponent';
import EditTemplateComponent from './components/shepackmanagementcomponents/EditTemplateComponents/EditTemplateComponents';
import ViewSHESubmissionAdminComponent from './components/shepackmanagementcomponents/ViewSheSubmissonsAdministrator/ViewSheSubmissonAdminComponent';
import ViewTemplateSubmissionAdminComponent from './components/shepackmanagementcomponents/ViewTemplateSubmissionsAdmininstrator/ViewTemplateSubAdminComponent';
import QATemplateSubmissionComponent from './components/shepackmanagementcomponents/QualityAssuranceComponets/QATemplateSubmisson';
import QAToasterViewSubmissionAdminComponent from './components/shepackmanagementcomponents/QualityAssuranceComponets/QAViewTemplateSubmissions/QAViewTemplateSubAdminComponent';
import ComplianceManagementComponent from './components/complicancetrackercomponents/compliancemanagement';
import CreateSheFileComponent from './components/complicancetrackercomponents/createshefilecomponent/CreateSheFile';
import CreateTemplatesComponent from './components/complicancetrackercomponents/createtemplatescomponent.js/CreateTemplatesComponent';
import CreatePeopleComplianceComponent from './components/complicancetrackercomponents/peoplemanagementcompliance/CreatePeopleComponent';
import AssignConsultantToSheFileComponent from './components/complicancetrackercomponents/peoplemanagementcompliance/AssignConsultantToSheFile';
import ConsultantTempateFillInComponent from './components/complicancetrackercomponents/consultanttempatefillIncomponents/ConsultantTempateFillInComponent';


// Lazy load components

// ---------- Header Components ----------------------
const Header = lazy(() => import('./components/Header'));
const UserInfoHeader = lazy(() => import('./components/UserInfoHeader'));

// ------------------- Login component ---------------
const Login = lazy(() => import('./components/logincomponents/Login'));

// -------------------- Dashbaord Component -------------------------
const Dashboard = lazy(() => import('./components/dashboardcomponents/Dashboard'));

// ------------------------- Checklist Management Components ------------------------dd



// Checklists
const ViewChecklist = lazy(() => import('./components/shepackmanagementcomponents/viewchecklistcomponents/ViewCheckList'));

// ------------------------ People Management COmponents ------------------


const ManageUsers = lazy(() => import('./components/manageuserscomponents/ManagerUsers'));

// People Management 
const CreateCompanyComponent = lazy(() => import('./components/manageuserscomponents/createcompanycomponents/CreateCompanyComponent'));
const CreateUserComponent = lazy(() => import('./components/manageuserscomponents/createusercomponents/CreateUserComponent'));
const ViewUsersComponent = lazy(() => import('./components/manageuserscomponents/viewuserscomponents/Viewuserscomponent'));
const AssignUserComponent = lazy(() => import('./components/manageuserscomponents/assignuserscomponents/AssignUserComponent'));

// ------------------- Data Analytics Components ------------------------
const DataAnalyticsAssignUser = lazy(() => import('./components/DataAnalyticsComponents/dataanalyticscomponent/DataAnalyticsAssign'));
const ProfileComponents = lazy(() => import('./components/Profilecomponents/ProfileComponents'));
const HelpComponents = lazy(() => import('./components/helpcomponents/HelpComponents'));

const RoleBasedRoute = lazy(() => import('./components/utils/Authentication/RoleBasedRoute'));

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [userRole, setUserRole] = useState('');

  useEffect(() => {
    const authStatus = JSON.parse(sessionStorage.getItem('isAuthenticated'));
    const user = JSON.parse(sessionStorage.getItem('user'));
    if (authStatus && user && user.userRole) {
      setIsAuthenticated(authStatus);
      setUserRole(user.userRole);
    } else {
      if (window.location.pathname !== '/') {
        sessionStorage.removeItem('isAuthenticated');
        sessionStorage.removeItem('user');
        setIsAuthenticated(false);
        setUserRole('');
        window.location.replace('/');
      }
    }
  }, []);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  const handleAuthentication = (status, user) => {
    setIsAuthenticated(status);
    if (status && user && user.userRole) {
      setUserRole(user.userRole);
      sessionStorage.setItem('isAuthenticated', JSON.stringify(status));
      sessionStorage.setItem('user', JSON.stringify(user));
    } else {
      sessionStorage.removeItem('isAuthenticated');
      sessionStorage.removeItem('user');
      setUserRole('');
      setIsAuthenticated(false);
    }
  };

  return (

    <div className='main-container'>

<BrowserRouter className='main-div'>
      
      <Suspense fallback={<LoadingComponents />}>
        <div className="application-component-container">
          <div className="application-container">
            {isAuthenticated ? (
              <div className="application-layout">
                <div className={`app-container ${isCollapsed ? 'header-collapsed' : ''}`}>
                  <Header
                    className='header-container'
                    isAuthenticated={isAuthenticated}
                    onAuthentication={handleAuthentication}
                    isCollapsed={isCollapsed}
                    toggleCollapse={toggleCollapse}
                  />
                </div>
                <div className="main-content-container">
                  <div className="app-user-info-header-container">
                    <UserInfoHeader className='user-info-header-container' isAuthenticated={isAuthenticated} onAuthentication={handleAuthentication} />
                  </div>
                  <div className="app-routes-main-container">
                    <Routes>
                      <Route
                        path="/dashboard"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin', 'user']}>
                            <Dashboard />
                          </RoleBasedRoute>
                        }
                      />

                      {/* -------------------------------------------
                                  SHE File Management  
                        --------------------------------------------- */}
                      <Route
                        path="/she-pack/she-pack-management"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin', 'user']}>
                            <SheManagement />
                          </RoleBasedRoute>
                        }
                      />

                      {/* --------------------- File and Template Management ---------------------- */}

                      {/* Create She File */}
                      <Route
                        path="/she-pack/create-she-pack"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            < CreateShePackComponent/>
                          </RoleBasedRoute>
                        }
                      />

                      {/* Create template */}
                      <Route
                        path="/she-pack/create-she-pack-document"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            < CreateShePackDocuments/>
                          </RoleBasedRoute>
                        }
                      />

                      {/* Duplicate Template */}
                      <Route
                        path="/she_file/duplicate_template"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <DuplicateTemplateComponent />
                          </RoleBasedRoute>
                        }
                      />

                      {/* Edit Template */}
                      <Route
                        path="/she_file/edit_template"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <EditTemplateComponent />
                          </RoleBasedRoute>
                        }
                      />

                      {/* SHE Pack Managment - View She Pack */}
                      <Route
                        path="/she-pack/view-she-pack"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            < ViewShePack/>
                          </RoleBasedRoute>
                        }
                      />

                      

                      {/* ------------------- SHE Pack Document Fill In Management ------------------ */}
                      <Route
                        path="/she-pack/she-pack-fill-in"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            < ShePackFillIn/>
                          </RoleBasedRoute>
                        }
                      />

                      {/* ---------------------- Consultant SHE Pack Fill In ---------------------- */}
                      <Route
                        path="/she-pack/consultant/she-pack-fill-in"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            < ConsultantFillInShepack/>
                          </RoleBasedRoute>
                        }
                      />

                      {/* ---------------------- Template View Submissions ---------------------- */}

                      {/* view submissons */}
                      <Route
                        path="/she-pack/template/view_submissions"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            < ViewTemplateSubmissionComponent/>
                          </RoleBasedRoute>
                        }
                      />

                      {/* view she file submissons */}
                      <Route
                        path="/she-pack/she_file/view_she_submissions"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            < ViewSHESubmissionComponent/>
                          </RoleBasedRoute>
                        }
                      />

                      {/* view administator submissons */}
                      <Route
                        path="/she-pack/she_file/administrator/view_she_submissions"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            < ViewSHESubmissionAdminComponent/>
                          </RoleBasedRoute>
                        }
                      />

                      {/* view administatror template submissons */}
                      <Route
                        path="/she-pack/she_file/administrator/view_template_she_submissions"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            < ViewTemplateSubmissionAdminComponent/>
                          </RoleBasedRoute>
                        }
                      />

                      {/* ------------------- Quality Assurance ------------------ */}

                      {/* QA Template Submissons */}
                      <Route
                        path="/she-pack/quality_assurance/view_template_she_submissions"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            < QATemplateSubmissionComponent/>
                          </RoleBasedRoute>
                        }
                      />

                      {/* QA Template Submissons */}
                      <Route
                        path="/she-pack/quality_assurance/qa-view_template_she_submissions"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            < QAToasterViewSubmissionAdminComponent/>
                          </RoleBasedRoute>
                        }
                      />

                      {/*-------------------------------------------------------------
                                                  Compiance Tracker  
                        ---------------------------------------------------------------*/}

                        {/* ------------------- File and Templates Management ------------------ */}

                      {/* Compliance Tracker  */}
                      <Route
                        path="/compliance-tracker/compliance-tracker-management"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            < ComplianceManagementComponent/>
                          </RoleBasedRoute>
                        }
                      />

                      <Route
                        path="/compliance-tracker/compliance-tracker-management/create-she-file"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            < CreateSheFileComponent/>
                          </RoleBasedRoute>
                        }
                      />


                      <Route
                        path="/compliance-tracker/compliance-tracker-management/create-templates"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            < CreateTemplatesComponent/>
                          </RoleBasedRoute>
                        }
                      />

                      {/* ------------------ SHE File & Template Submissions ---------------- */}

                      <Route
                        path="/compliance-tracker/compliance-tracker-management/consultant-template-fill-in"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            < ConsultantTempateFillInComponent/>
                          </RoleBasedRoute>
                        }
                      />


                      {/* ------------------- People Management ------------------ */}

                      {/* ------- Create Employees -------- */}
                      <Route
                        path="/compliance-tracker/people-management/create-employee"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            < CreatePeopleComplianceComponent/>
                          </RoleBasedRoute>
                        }
                      />

                      {/* -------------  Assign Consultants ------------- */}
                      <Route
                        path="/compliance-tracker/people-management/assign-consultant"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            < AssignConsultantToSheFileComponent />
                          </RoleBasedRoute>
                        }
                      />

                      {/* */}
                      
                      <Route
                        path="/viewchecklist"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <ViewChecklist />
                          </RoleBasedRoute>
                        }
                      />

                      <Route
                        path="/managerusers"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <ManageUsers />
                          </RoleBasedRoute>
                        }
                      />

                      <Route
                        path='/createcompany'
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin']}>
                            <CreateCompanyComponent />
                          </RoleBasedRoute>
                        }
                      />


                    <Route
                        path='/people-management/add-department'
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin']}>
                            <AddDepartmentComponent />
                          </RoleBasedRoute>
                        }
                      />
                      
                      <Route
                        path='/createuser'
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin']}>
                            <CreateUserComponent />
                          </RoleBasedRoute>
                        }
                      />
                      <Route
                        path='/viewusers'
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <ViewUsersComponent />
                          </RoleBasedRoute>
                        }
                      />

                      {/* */}
                      <Route
                        path='/assignusers'
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <AssignUserComponent />
                          </RoleBasedRoute>
                        }
                      />

                      <Route
                        path='/manage-users/she-pack-management'
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            < AssignConsultantComponent/>
                          </RoleBasedRoute>
                        }
                      />

                      <Route
                        path='/data-analytics-assign-user'
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <DataAnalyticsAssignUser />
                          </RoleBasedRoute>
                        }
                      />

                      <Route
                        path="/data-analytics"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <DataAnalytics />
                          </RoleBasedRoute>
                        }
                      />

                      <Route
                        path="/data-analytics-add-MS-Power-Bi-Link"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <AddMSPowerBIComponent />
                          </RoleBasedRoute>
                        }
                      />

                      <Route
                        path="/data-analytics-view-MS-Power-Bi-Link"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin']}>
                            <ViewPowerBiLinks />
                          </RoleBasedRoute>
                        }
                      />

                      <Route
                        path="/profile"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin', 'user']}>
                            <ProfileComponents />
                          </RoleBasedRoute>
                        }
                      />
                      <Route
                        path="/help"
                        element={
                          <RoleBasedRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['super admin', 'admin', 'user']}>
                            <HelpComponents />
                          </RoleBasedRoute>
                        }
                      />
                      <Route path="*" element={<Navigate to="/dashboard" />} />
                    </Routes>
                  </div>
                </div>
              </div>
            ) : (
              <Routes>
                <Route path="/" element={<Login onAuthentication={handleAuthentication} />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            )}
          </div>
        </div>
      </Suspense>
    </BrowserRouter>

    </div>
    
  );
}

export default App;
