import React from "react";

import { Link } from "react-router-dom";

import { FaRegBuilding } from "react-icons/fa";
import { FaUserPlus } from "react-icons/fa";
import { HiMagnifyingGlass } from "react-icons/hi2";

import { BsDatabaseAdd } from "react-icons/bs";


import { MdOutlineConstruction } from "react-icons/md";

import { CiLink } from "react-icons/ci";
import { CiDatabase } from "react-icons/ci";

// styles - css 
import '../../../assets/css/ManageUsers.css';



const DataAnalytics = () => {

    const user = JSON.parse(sessionStorage.getItem('user'));

    return (

        <div className="component-container">

            <div className="checklist-component-container">

                <div className="checklist-text-container">

                    <div>
                        <h1>Data Analytics Management </h1>
                    </div>

                    <div>
                        <p> To add, assign and view MS Power BI reports.  </p>
                    </div>

                </div>

                {/* Navigations to applicaiton pages */}
                <div className="checklist-feature-main-container">

                    {/* View Form */}
                    <Link className="link" to='/data-analytics-add-MS-Power-Bi-Link'>

                    {/* View form */}
                    <div className='application-feature-container'>

                        <div className="feature-img-container">

                            <BsDatabaseAdd className="feature-img"/>

                        </div>

                        <div className="feature-name-container">

                            <h4>Add MS Power BI Report Link</h4>

                        </div>

                        <div className="feature-description-container">

                            <p>
                                To add MS Power BI reports links to the system. 
                            </p>

                        </div>

                    </div>
                    
                    </Link>


            
                    <Link className="link" to='/data-analytics-assign-user'>

                    
                    <div className='application-feature-container'>

                        <div className="feature-img-container">

                            <CiDatabase  className="feature-img"/>

                        </div>

                        <div className="feature-name-container">

                            <h4>Assign MS Power BI reports</h4>

                        </div>

                        <div className="feature-description-container">

                            <p>
                                To assign a MS Power BI report to a user.
                            </p>

                        </div>

                    </div>
                    
                    </Link>

                    <Link className="link" to='/data-analytics-view-MS-Power-Bi-Link'>

                    
                    <div className='application-feature-container'>

                        <div className="feature-img-container">

                            <HiMagnifyingGlass  className="feature-img"/>

                        </div>

                        <div className="feature-name-container">

                            <h4>View MS Power Bi Reports Links</h4>

                        </div>

                        <div className="feature-description-container">

                            <p>
                                To view and manage all MS Power Bi Links.
                            </p>

                        </div>

                    </div>
                    
                    </Link>
                
                </div>



            </div>

        </div>
    )
}

export default DataAnalytics