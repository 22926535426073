/* ---------- Assign User  component ------------------

    -   This component is used to assign a user to a SHE Pack. 
*/

// ------------------- React Component --------------------------
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// ------------------------- Application Components/functions -----------------



// Application component - 
import ToastError from "../../toasterscomponents/ToastError";
import CreatedSuccessfullToaster from "../../toasterscomponents/manageusersToaster/createdSuccessfullToaster";

// --------------------------- Styles --------------------------------

// styles - css 
import '../../../assets/css/ManageUsers.css';

// styles - icons 
import { MdAssignmentAdd } from "react-icons/md";

import { LiaSpinnerSolid } from "react-icons/lia";

import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png';



// --------------- Assign User Component -------------
const AssignConsultantToSheFileComponent = () => {

  //
  const user = JSON.parse(sessionStorage.getItem('user'));

  // ----------------------- State Management -----------------------------
  // Common state for companies and departments data
  const [companies, setCompanies] = useState([]);
  const [departments, setDepartments] = useState([]);

  // Update the departments state to handle multiple selections
  const [selectedDepartments, setSelectedDepartments] = useState([]);

 // Consultant states
 const [selectedConsultantCompany, setSelectedConsultantCompany] = useState("");
 const [selectedConsultantDepartment, setSelectedConsultantDepartment] = useState("");
 const [consultantUsers, setConsultantUsers] = useState([]);
 const [selectedConsultantUser, setSelectedConsultantUser] = useState("");
 const [filteredConsultantDepartments, setFilteredConsultantDepartments] = useState([]);


  // Client states
  const [selectedClientCompany, setSelectedClientCompany] = useState("");
  const [selectedClientDepartment, setSelectedClientDepartment] = useState("");
  const [filteredClientDepartments, setFilteredClientDepartments] = useState([]);

  // State Management - Loading spinner 
  const [isLoading, setIsLoading] = useState(true);


  // State Management - SHE Files 
  const [shefile, setSheFile] = useState([]);
  const [selectedShefile, setSelectedShefile] = useState(''); // Changed to single string

  

  // State Management - Companies 
  
  const [selectedCompany, setSelectedCompany] = useState("");

  // Update the departments filtering logic

const [selectedDepartment, setSelectedDepartment] = useState("");

const [filteredDepartments, setFilteredDepartments] = useState([]);

  // State Management - Users
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  const [companyCreatedSuccessfullyToaster, setCompanyCreatedSuccessfullyToaster] = useState(false);
  const [showTextEmptyNameError, setShowTextEmptyNameError] = useState(false);
  const [showAssignUserError, setShowAssignUserError] = useState(false);

  const navigate = useNavigate();

  //const filteredDepartments = departments.filter(department => department.companyId === selectedCompany);
  

  //-------------------------------- Functions -------------------------

  const handleDepartmentChange = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    setSelectedDepartments((prev) => {
      if (isChecked) {
        return [...prev, value];
      } else {
        return prev.filter((item) => item !== value);
      }
    });
  };

  // Fetch initial data
  useEffect(() => {
    fetchCompanies();
    fetchDepartments();
  }, []);

  // Functions - fetches SHE Pack
  // Fetch companies - using original API
  const fetchCompanies = async () => {
    try {
      const response = await fetch('/api/companies');
      const data = await response.json();
      if (Array.isArray(data)) {
        setCompanies(data);
      } else {
        console.error('Invalid data format for companies:', data);
      }
    } catch (error) {
      console.error('Error fetching companies:', error);
    }
  };

  // Fetch departments - using original API
  const fetchDepartments = async () => {
    try {
      const response = await fetch('/api/departments');
      const data = await response.json();
      if (Array.isArray(data)) {
        setDepartments(data);
      } else {
        console.error('Invalid data format for departments:', data);
      }
    } catch (error) {
      console.error('Error fetching departments:', error);
    }
  };

  // Filter consultant departments when consultant company changes
  useEffect(() => {
    if (selectedConsultantCompany) {
      const filtered = departments.filter(dept => dept.company_id === selectedConsultantCompany);
      setFilteredConsultantDepartments(filtered);
      setSelectedConsultantDepartment(""); // Reset department selection
      setConsultantUsers([]); // Reset users
    } else {
      setFilteredConsultantDepartments([]);
    }
  }, [selectedConsultantCompany, departments]);

  // Filter client departments when client company changes
  useEffect(() => {
    if (selectedClientCompany) {
      const filtered = departments.filter(dept => dept.company_id === selectedClientCompany);
      setFilteredClientDepartments(filtered);
      setSelectedClientDepartment(""); // Reset department selection
    } else {
      setFilteredClientDepartments([]);
    }
  }, [selectedClientCompany, departments]);

  // Fetch users when consultant department changes
  useEffect(() => {
    if (selectedConsultantDepartment) {
      fetchUsers(selectedConsultantDepartment);
    } else {
      setConsultantUsers([]);
    }
  }, [selectedConsultantDepartment]);

  const fetchUsers = async (departmentId) => {
    try {
      const response = await fetch(`/api/users/${departmentId}`);
      const data = await response.json();
      if (Array.isArray(data)) {
        setConsultantUsers(data);
      } else {
        console.error('Invalid data format for users:', data);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };






  
  
  // Function - fetches she packs
  useEffect(() => {

    const fetchShefile= async () => {

      setIsLoading(true)

      try {

        const response = await axios.get('/compliance-tracker/people-management/assign-consultant');
        setSheFile(response.data);

      } catch (error) {

        console.error('Error fetching SHE Packs :', error);

      } finally {

        setIsLoading(false)

      }
    };

    fetchShefile();
  }, []);

  
  

  useEffect(() => {
    if (selectedDepartment) {
      fetchUsers(selectedDepartment);
    } else {
      setUsers([]);
    }
  }, [selectedDepartment]);

  

  const handleSheFileChange = (e) => {
    setSelectedShefile(e.target.value);
    
  };

  
  const handleNavigateBack = () => {
    navigate('/managerusers');
  };

  //
  const handleAssignUser = async (e) => {
    e.preventDefault();
  
    if (!selectedConsultantUser || !selectedShefile || !selectedClientCompany || selectedDepartments.length === 0) {
      setShowTextEmptyNameError(true);
      return;
    }
  
    try {
      const shefiles = shefile.find((c) => c.shefile_id === selectedShefile);
  
      const requestBody = {
        userId: selectedConsultantUser,
        company: selectedClientCompany,
        departments: selectedDepartments, // Array of selected departments
        shefile: {
          shefile_id: selectedShefile,
          shefile_name: shefiles ? shefiles.shefile_name : '',
        },
        created_by: user.user_id,
      };
  
      const response = await axios.post(
        '/api/compliance-tracker/people-management/assign-consultant/assign-consultant-to-she-pack',
        requestBody
      );
  
      if (response.status === 200) {
        setCompanyCreatedSuccessfullyToaster(true);
        setTimeout(() => {
          navigate('/she-pack/she-pack-management');
        }, 3000);
      } else {
        setShowAssignUserError(true);
      }
    } catch (error) {
      console.error('Error assigning user to SHE pack:', error);
      setShowAssignUserError(true);
    }
  };

  const clearForm = () => {
    setSelectedConsultantCompany('');
    setSelectedConsultantDepartment('');
    setSelectedConsultantUser('');
    setSelectedClientCompany('');
    setSelectedClientDepartment('');
    setSelectedShefile('');
    setShowTextEmptyNameError(false);
  };

  return (
    <div className="component-container">
      <div className="create-checklist-component-container">
        <div className="create-company-text-container">

          <div>
            <h1> Assign Consultant to a Template </h1>

            <p>
              Assign a client to a template, enabling them to complete and submit the assigned tasks efficiently.
            </p>

            <p>
              <i>
              Select a she pack to assign, then specify the company, department, and user to ensure accurate task delegation.
              </i>
            </p>
          </div>

          
          <div>
              <img src={blueKbcLogo} alt="white-kbc-logo" className="side-menu-logo" />
          </div>
          
          
        </div>

        <div className="navigate-back-button-main-container">
          <div className="navigate-back-button-container">
            <button className="navigate-back-button" onClick={handleNavigateBack}>Back</button>
          </div>
        </div>

      

        <div className="view-checklist-table-container">
          <div className="view-checklist-table-table-container">

            
            <table className="view-checklist-table">

                <colgroup>
                  {/* Remove whitespace between col elements */}
                  <col style={{width: "200px"}}/>{/* Column - SHE File Name */}
                  <col style={{width: "200px"}}/>{/* Column - SHE Template Name */}
                  <col style={{width: "180px"}}/>{/* Column - Company */}
                  <col style={{width: "200px"}}/>{/* Column - Company */}
                  <col style={{width: "300px"}}/>{/* Delete icon column */}
                  <col style={{width: "200px"}}/>{/* Delete icon column */}
                  <col style={{width: "250px"}}/>{/* Delete icon column */}
                  
                  <col style={{width: "100px"}}/>{/* Delete icon column */}
                        
                </colgroup>

              <thead>
                <tr>
                  <th className="table-header">Consultant's Company</th>
                  <th className="table-header">Consultant's Department</th>
                  <th className="table-header">Consultants Name</th>

                  <th className="table-header">Assign to Company</th>
                  <th className="table-header">Assign to sites</th>
                  


                  <th className="table-header">SHE File Name</th>
                  
                  
                  <th className="table-header">Assign</th>
                </tr>
              </thead>

              <tbody className="view-table-body">
                <tr>

                  {/* Column - Select company  */}
                  <td>
                    <select
                      value={selectedConsultantCompany}
                      onChange={e => setSelectedConsultantCompany(e.target.value)}
                      className="assign-user-checkbox-item"
                    >
                      <option value="">-- Select Company --</option>
                      {companies.map(company => (
                        <option key={company.company_id} value={company.company_id}>
                          {company.companyName}
                        </option>
                      ))}
                    </select>
                  </td>
                  
                  {/* Column - Department */}
                  <td>
                    <select
                      value={selectedConsultantDepartment}
                      onChange={e => setSelectedConsultantDepartment(e.target.value)}
                      disabled={!selectedConsultantCompany}
                    >
                      <option value="">-- Select Department --</option>
                      {filteredConsultantDepartments.map(department => (
                        <option key={department.department_id} value={department.department_id}>
                          {department.department_name}
                        </option>
                      ))}
                    </select>
                  </td>

                  {/*Column - Select User */}
                  <td>

                    <select
                      value={selectedConsultantUser}
                      onChange={e => setSelectedConsultantUser(e.target.value)}
                      disabled={!selectedConsultantDepartment}
                    >
                      <option value="">-- Select User --</option>

                        {consultantUsers.map(user => (

                          <option key={user.user_id} value={user.user_id}>
                            {user.userName} {user.userSurname}
                          </option>

                        ))}

                    </select>

                  </td>

                  {/* Column - Select company  */}
                  <td>

                    <select
                      value={selectedClientCompany}
                      onChange={e => setSelectedClientCompany(e.target.value)}
                    >
                      <option value="">-- Select Company --</option>

                      {companies.map(company => (
                        <option key={company.company_id} value={company.company_id}>
                          {company.companyName}
                        </option>
                      ))}
                      
                    </select>

                  </td>
                  
                  {/* Column - Department */}
                  <td>

                    {filteredClientDepartments.map((department) => (

                      <div key={department.department_id}>

                        <input
                          type="checkbox"
                          value={department.department_id}
                          checked={selectedDepartments.includes(department.department_id)}
                          onChange={handleDepartmentChange}
                        />

                        <label>{department.department_name}</label>

                      </div>

                    ))}

                  </td>

                  

                  {/* Table Row - She file  */}
                  <td>      
                    <select
                      value={selectedShefile}
                      onChange={handleSheFileChange}
                    >
                      <option value="">-- Select SHE file --</option>
                      {shefile.map(shefile => (
                        <option key={shefile.shefile_id} value={shefile.shefile_id}>
                          {shefile.shefile_name}
                        </option>
                      ))}
                    </select>    
                                 
                  </td>

                 

                  {/* Column - Assign user */}
                  <td>
                    <MdAssignmentAdd onClick={handleAssignUser} className="assign-user-assign-icon" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {showTextEmptyNameError && (
            <p className="error-text">Error! Empty fields, please fill in all fields and select at least one checklist.</p>
          )}

          <div className="assign-user-button-container-container">
            <div className="assign-user-button-container">
              <button className="assign-user-button" type='button' onClick={clearForm}>Reset</button>
            </div>
          </div>
        </div>
      </div>

      {showAssignUserError && 
        <ToastError 
          toasterError='Error Assigning User'
        />
      }

      {companyCreatedSuccessfullyToaster && 
        <CreatedSuccessfullToaster 
          successToasterTitle="Assigned Successfully" 
          successToasterMessage="client assigned to template successfully. One moment while we redirect you to view users page"
        />
      }
    </div>
  );
};

export default AssignConsultantToSheFileComponent;