/* ---------- Create Checklist toaster component ------------------

    -   This component is used for when the user wants to create a custom checklist. 
*/


// ------------------ React Components ------------------------
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// -------------------- API -----------------------------
import API_BASE_URL from "../../utils/api/apiConfig.js";

// --------------------- icons --------------------
import { MdDeleteOutline } from "react-icons/md";
import { FaSpinner } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { MdOutlineCancel } from "react-icons/md";
import { CiEdit } from "react-icons/ci";


// --------------------- Assests -------------------
import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png'; 

// ------------------- Application components --------------------

import ItemAddedToaster from "../../toasterscomponents/checklistToasters/ItemAddedToaster.js";
import ItemRemovedToaster from "../../toasterscomponents/checklistToasters/ItemRemovedToaster.js";
import CreatedSuccesFullyToaster from "../../toasterscomponents/shepacktoasters/CreatedSuccesFullyToaster.js";


// --------------------- Component - Add Questions Create Checklist -------------------
const DuplicateTemplateComponent = ({ formName, checklistUniqueIdentifier }) => {

    //
    const [templateLoaded, setTemplateLoaded] = useState(false);

    const [showSuccess, setShowSuccess] = useState(false);

    // State Management - SHE Packs
    const [shePacks, setShePacks] = useState([]);
    const [selectedSHEPack, setSelectedSHEPack] = useState("");

    // State Management - SHE Pack Documents
    const [isPackSelected, setIsPackSelected] = useState(false);

    //
    const [selectedDocument, setSelectedDocument] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    //
    const [documents, setDocuments] = useState([]);
     // State Management - Loading spinner 
  const [isLoading, setIsLoading] = useState(true);
  const [editError, setEditError] = useState("");
    
    

    //
    const user = JSON.parse(sessionStorage.getItem('user'));

    const [viewSection, setViewSection] = useState({ A: true, B: false, C: false });

    // --------------- State Managements ------------------------

    // state management - Section A checklist information
    const [checklistName, setDocumentName] = useState("");
    const [newDuplicateName, setNewDuplicateName] = useState("");
    

    // state management - Section categories
    const [editingCategory, setEditingCategory] = useState(null);
    const [editedCategoryName, setEditedCategoryName] = useState("");

    // state management - section Create questions 
    const [visibleOptions, setVisibleOptions] = useState(3);
    const [submitStatus, setSubmitStatus] = useState('idle');
    const [errors, setErrors] = useState([]);
    
    const [questionTypes] = useState(['Free Text']);
    const [questionBankData, setQuestionBankData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [categories, setCategories] = useState(['General']);
    const [newCategory, setNewCategory] = useState("");
    const [categoryError, setCategoryError] = useState("");
    const [optionError, setOptionError] = useState("");
    const [showToaster, setShowToaster] = useState({ success: false, categoryAdded: false, rowAdded: false, rowRemoved: false, optionAdded: false, editedCategory: false, cancelEditedCategory: false });
    const [totalScore, setTotalScore] = useState(0);
    //
    const navigate = useNavigate();

    // Handle edit button click
    const handleSelectSHEpack = () => {
        if (!selectedSHEPack) {
            setEditError("Please select a SHE File.");
            return;
        }
    
        setShowToaster(prev => ({ ...prev, fileSelected: true }));
        setEditError("");
        setIsPackSelected(true);
    };
    

    useEffect(() => {

        const fetchEditableDocuments = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/she-file/template/get-templates`);
                
                // Only set checklists if data is an array
                if (Array.isArray(response.data)) {
                    setDocuments(response.data);
                } else {
                    console.error('Invalid response format', response.data);
                    setDocuments([]);
                }
                
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching editable checklists:', error);
                setEditError(error.response?.data?.message || 'Failed to load checklists');
                setIsLoading(false);
                setDocuments([]);
            }
        };
    
        fetchEditableDocuments();
    }, []); // Empty dependency array means this runs once on component mount

    // Function to fetch checklist data
    const fetchChecklistData = async (documentId) => {

        console.log("Fetching checklist data for checklistId:", documentId);

        setIsLoading(true);
        setEditError("");
        
        try {
            // Check if a checklist is selected
            if (!documentId) {
                
                setIsLoading(false);
                return;
            }
    
            const response = await axios.get(`${API_BASE_URL}/duplicate-template/get-questions/question-bank/${documentId}`);
            
            if (response.data && response.data.length > 0) {

                // Update state with fetched data
                setQuestionBankData(response.data);
                setDocumentName(response.data[0].shepack_document_name);
                
                // Extract and set categories
                const uniqueCategories = [...new Set(response.data.map(item => item.category))];
                setCategories(uniqueCategories.filter(Boolean) || ['General']);
    
                // Find maximum number of options used
                const maxOptions = Math.max(...response.data.map(row => {
                    let maxUsed = 0;
                    for (let i = 1; i <= 15; i++) {
                        if (row[`option_${i}`]) maxUsed = i;
                    }
                    return maxUsed;
                }));
                
                setVisibleOptions(Math.max(maxOptions, 3)); // Ensure at least 3 options are visible
                
                setIsEditing(true);
            } else {
                setEditError("No data found for this checklist or checklist might have been already filled");
            }
        } catch (error) {
            console.error('Error fetching checklist data:', error);
            setEditError(`Error loading checklist: ${error.response?.data?.message || error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
            calculateTotalScore();
        }, [questionBankData]);

    // Function to calculate total score
    const calculateTotalScore = () => {
        const total = questionBankData.reduce((sum, question) => {
            const score = parseFloat(question.question_score) || 0;
            return sum + score;
        }, 0);
        setTotalScore(total);
    };

    // Handle edit button click
    const handleEditClick = () => {
        if (!selectedDocument) {
            setEditError("Please select a checklist to edit");
            return;
        }
        setEditError("");
        fetchChecklistData(selectedDocument);
        setTemplateLoaded(true); // Set template loaded to true instead of using isEditing
    };
    

    useEffect(() => {
        fetchChecklistData()
        fetchQuestionBankData();
        
    }, []);

    useEffect(() => {

        const fetchShePacks = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/she-file/duplicate-template/get-she-packs`);
                
                // Only set checklists if data is an array
                if (Array.isArray(response.data)) {
                    setShePacks(response.data);
                } else {
                    console.error('Invalid response format', response.data);
                    setShePacks([]);
                }
                
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching SHE File:', error);
                setEditError(error.response?.data?.message || 'Failed to load File');
                setIsLoading(false);
                setShePacks([]);
            }
        };
    
        fetchShePacks();
    }, []); // Empty dependency array means this runs once on component mount


    useEffect(() => {
        const timers = Object.keys(showToaster).map(key => showToaster[key] && setTimeout(() => setShowToaster(prev => ({ ...prev, [key]: false })), 3000));
        return () => timers.forEach(timer => clearTimeout(timer));
    }, [showToaster]);

    const fetchQuestionBankData = async () => {
        try {
            const columnsResponse = await axios.get(`${API_BASE_URL}/columns/Question_Bank`);
            setColumns(columnsResponse.data);
        } catch (error) {
            console.error('Error fetching Question Bank data:', error);
        }
    };

    //
    

    const handleReset = () => {
        // Reset all form-related states to their initial state
        setDocumentName("");
        
        // Instead of using initializePredefinedQuestions(), we'll start completely from scratch
        setQuestionBankData([]);
        
        // Reset categories to default
        setCategories(['General']);
        
        // Reset other related states
        setNewCategory("");
        setCategoryError("");
        setErrors([]);
        
        // Reset view sections
        setViewSection({ A: true, B: false, C: false });
    };

    const handleClearQuestions = () => {
        
        // Instead of using initializePredefinedQuestions(), we'll start completely from scratch
        setQuestionBankData([]);

        // Reset categories to default
        setCategories(['General']);
        
    
        // Reset other related states
        setNewCategory("");
        setCategoryError("");
        setErrors([]);
        
       
    };


    const handleInputChange = (rowIndex, columnName, value) => {
        const updatedData = [...questionBankData];
        if (columnName === 'question_type' && value !== 'Radio' && value !== 'Drop Down' && value !== 'Multiple Select') {
            for (let i = 1; i <= 15; i++) updatedData[rowIndex][`option_${i}`] = '';
            if (value !== 'File') updatedData[rowIndex]['type_file_upload'] = '';
        }
        updatedData[rowIndex] = { ...updatedData[rowIndex], [columnName]: value };
        setQuestionBankData(updatedData);
    };

    

    const handleAddRow = () => {
        
        const newRow = {
            shepack_document_id: checklistUniqueIdentifier,
            shepack_document_name: formName,
            question_type: 'Radio'
        };

        setShowToaster(prev => ({ ...prev, rowAdded: true }));
        setQuestionBankData([...questionBankData, newRow]);
    };

    // Handle Submit all Questions
    const handleSubmit = async () => {
        setSubmitStatus('loading');
        setErrors([]);
        
        let newErrors = [];
      
        if (!newDuplicateName.trim()) {
            newErrors.push("Empty Field -  Template name cannot be empty.");
        }
    
        if (!selectedSHEPack) {
            newErrors.push("SHE File Required - Please select a SHE File for this template.");
        }
      
        if (questionBankData.length === 0) {
            newErrors.push("No Questions - Add at least one question row to the template.");
        }
    
        if (newErrors.length > 0 || !validateData()) {
            setErrors(prevErrors => [...prevErrors, ...newErrors]);
            window.scrollTo(0, 0);
            setSubmitStatus('idle');
            return;
        }
      
        try {
            if (!user || !user.user_id) {
                throw new Error('User session data is missing');
            }
    
            // Find the selected SHE Pack details
            const selectedSHEPackDetails = shePacks.find(pack => pack.shepack_id === selectedSHEPack);
            if (!selectedSHEPackDetails) {
                throw new Error('Selected SHE Pack not found');
            }
    
            // Prepare question data without shepack_id and shepack_name fields
            const preparedQuestionData = questionBankData.map(question => {
                // Exclude fields not in the database schema
                const { shepack_id, shepack_name, ...validFields } = question;
                return validFields;
            });
    
            const response = await axios.put(`${API_BASE_URL}/duplicate-template-new-template/${selectedDocument}`, {
                formName: newDuplicateName,
                shepack_id: selectedSHEPackDetails.shepack_id,
                shepack_name: selectedSHEPackDetails.shepack_name,
                shepack_document_score: totalScore,
                created_by: user.user_id,
                formSections: categories.map(category => ({ name: category, inputField: 'custom' })),
                questionBankData: preparedQuestionData
            });
    
            console.log('Template duplicated successfully:', response.data);
            setShowToaster(prev => ({ ...prev, success: true }));
            setSubmitStatus('success');
            setShowSuccess(true)
    
            setTimeout(() => {
                setShowToaster(prev => ({ ...prev, success: false }));
                navigate('/checklistfillin');
            }, 2000);
    
        } catch (error) {
            console.error('Error duplicating template:', error);
            setErrors(prevErrors => [...prevErrors, `Error duplicating template: ${error.message}`]);
            setSubmitStatus('error');
        }
    };

    const handleAddCategory = () => {
        if (newCategory.trim() === "") {
            setCategoryError("Empty Field - To save category, please enter a category name.");
            return;
        }
        if (!categories.includes(newCategory)) {
            setCategories([...categories, newCategory]);
            setNewCategory("");
            setShowToaster(prev => ({ ...prev, categoryAdded: true }));
            setCategoryError("");
        } else {
            setCategoryError("Duplicate Entry - This category already exists.");
        }
    };

    const handleRemoveRow = (rowIndex) => {
        // Remove row without any restrictions
        setShowToaster(prev => ({ ...prev, rowRemoved: true }));
        setQuestionBankData(questionBankData.filter((_, index) => index !== rowIndex));
    };

    

    const validateData = () => {
        const newErrors = [];
        questionBankData.forEach((row) => {
            if (!row.question_name || row.question_name.trim() === '') newErrors.push("Error - Question Name : Question name cannot be empty.");
            
        });

        setErrors(newErrors);
        return newErrors.length === 0;

    };

    const handleAddOption = () => {
        if (visibleOptions < 15) {
            setVisibleOptions(prevVisible => prevVisible + 1);
            setShowToaster(prev => ({ ...prev, optionAdded: true }));
            setOptionError("");
        } else {
            setOptionError("Error: Maximum of 15 options allowed.");
        }
    };

    

    

    const visibleColumnNames = [
        
        'Remove',
        'question_type',
        'question_name',
        'question_reference',
        'question_score',
        'category',
        'file_required',
        'comment'
        

    ];

    return (

        <div className="add-question-custom-field-main-component-container">
            
            <div className="add-question-create-questions-checklist-custom-field-component-container">

                <div className="create-questions-custom-field-container">

                    <div className="add-question-view-checklist-component-container">
                        
                        <div className="add-question-view-checklist-header-container">

                            <div>
                                <img src={blueKbcLogo} alt="white-kbc-logo" className="add-quesiton-side-menu-logo" />
                            </div>

                            <div className="navigate-back-button-container">
                                <button className="navigate-back-button" onClick={() => navigate(-1)}>Back</button>
                            </div>

                        </div>

                        <div className="add-question-view-checklist-header-step-container">
                            <div>
                                <h2>Duplicate Template</h2>
                                <p>To create a new template by duplicating and editing existing one.</p>
                                <p className="secondary-color-paragraph">To complete each section, click on the 'Maximize' button to expand the section.</p>
                            </div>
                        </div>

                        {['A', 'B', 'C'].map(section => (
                            <div key={section} className="create-checklist-section-a-information-main-container">
                                <div className="create-checklist-section-container-main-container">
                                    <div className="create-checklist-section-container">
                                        <div>
                                            <h3>Section {section} - {section === 'A' ? 'Information' : section === 'B' ? 'Categories' : 'Edit Questions'}</h3>
                                        </div>
                                        <div>
                                            <button className="confirm-button" onClick={() => setViewSection(prev => ({ ...prev, [section]: !prev[section] }))}>
                                                {viewSection[section] ? 'Minimize' : 'Maximize'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                
                                {/* View sections  */}
                                {viewSection[section] && (
                                    section === 'A' ? (
                                        <div className="create-checklist-section-a-information-container">
                                            <div className="create-checklist-section-a-information-container-container">

                                                <div>

                                                    <div>

                                                        <h3 className="category-secondary-color">Template Details</h3>
                                                    
                                                    </div>

                                                    <div>
                                                        
                                                        <p>Please fill in the template details below.</p>

                                                    </div>

                                                </div>
                                                
                                            </div>
                                            

                                            <div className="create-checklist-section-a-information-container-container">

                                                <div className="duplicate-checklist-section-a-information-checklist-name-container-container">

                                                    <div className="duplicate-checklist-section-a-information-checklist-name-container">
                                        
                                                        {/* Information - template detials */}
                                                        <div className="duplicate-section-information-template-detail-main-container">

                                                            {/* SHE File Details */}
                                                            <div className="duplicate-section-information-template-name-select-template-detail-main-container">

                                                                {/* Select Template*/}
                                                                <div className="add-question-view-checklist-category-container">
                                                                    
                                                                    <div>
                                                                        <h4>1. Select SHE File</h4>

                                                                        <p>Select the SHE File that you want the template to belong to.</p>
                                                                    </div>

                                                                    <div>
                                                                        <select
                                                                            value={selectedSHEPack}
                                                                            onChange={e => setSelectedSHEPack(e.target.value)}
                                                                            disabled={isLoading || selectedSHEPack} // Remove isEditing dependency
                                                                        >
                                                                            <option value="">-- Select SHE Pack --</option>
                                                                            {shePacks.map(shePack => (
                                                                                <option key={shePack.checklist_id} value={shePack.shepack_id}>
                                                                                    {shePack.shepack_name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                
                                                                    {editError && <div className="error-text">{editError}</div>}
                                                                
                                                                        <div>
                                                                            <p className="secondary-color-paragraph" style={{ fontStyle: 'italic' }}>Important. Click "Select SHE File" to select the SHE File that you want the template to be saved to.</p>
                                                                        </div>
                                                                
                                                                        {isPackSelected && (
                                                                
                                                                         <div className="duplicate-template-loaded-confirmation">

                                                                            <TiTick />
                                                                            <div>
                                                                                <p>You select the following she file : {shePacks.find(pack => pack.shepack_id === selectedSHEPack)?.shepack_name || selectedSHEPack}</p>
                                                                            </div>
                                                                        </div>
                                                                
                                                                        )}
                                                                
                                                                        <div>
                                                                                                                                
                                                                            <button 
                                                                                    className="confirm-button" 
                                                                                    onClick={handleSelectSHEpack}
                                                                                    disabled={isLoading}
                                                                                >
                                                                                    {isLoading ? (
                                                                                        <>
                                                                                        <FaSpinner className="fa-spin" />
                                                                                        <span>Loading...</span>
                                                                                     </>
                                                                                ) : (
                                                                                    'Select SHE File'
                                                                                )}
                                                                            </button>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            {/* Template Name and Select Template */}
                                                            <div className="duplicate-section-information-template-name-select-template-detail-main-container">

                                                                {/* Template Name */}
                                                                <div className="create-checklist-section-a-information-container-container">
                                                                        
                                                                    <div className="duplicate-fillin-select-checklist-section-a-information-checklist-name-name-container">

                                                                        <div>
                                                                            <h4>Fill In Template Name</h4>
                                                                            <p>Please enter the name of the new template that you are creating.</p>
                                                                        </div>
                                                                            
                                                                        <div>
                                                                            <input
                                                                                className="create-checklist-questions-form-name-input"
                                                                                placeholder="Enter Template Name"
                                                                                value={newDuplicateName}
                                                                                onChange={(e) => setNewDuplicateName(e.target.value)}
                                                                                disabled={isLoading}
                                                                            />

                                                                         </div>

                                                                         {!newDuplicateName.trim() && <div className="info-text">*Required</div>}
                                                                         
                                                                            
                                                                    </div>

                                                                </div>

                                                                {/* Select Template*/}
                                                                <div className="add-question-view-checklist-category-container">
                                    
                                                                <div>
                                                                    <h4>Select Template</h4>

                                                                    <p>Select the template you would like to use as the basis for creating your new template</p>
                                                                </div>

                                                                <div>
                                                                    <select
                                                                        value={selectedDocument}
                                                                        onChange={e => setSelectedDocument(e.target.value)}
                                                                        disabled={templateLoaded || isLoading}
                                                                    >
                                                                        <option value="">-- Select Template --</option>
                                                                        {documents.map(document => (
                                                                            <option key={document.shepack_document_id} value={document.shepack_document_id}>
                                                                                {document.shepack_document_name}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>

                                                                {editError && <div className="error-text">{editError}</div>}

                                                                <div>
                                                                    <p className="secondary-color-paragraph" style={{ fontStyle: 'italic' }}>Important. Click "Duplicate Template" to generate a copy of the template with its associated questions</p>
                                                                </div>


                                                                {isPackSelected && !selectedDocument && <div className="info-text">*Required</div>}
                                                                
                                                                <div>
                                                                
                                                                    <button 
                                                                            className="confirm-button" 
                                                                            onClick={handleEditClick}
                                                                            disabled={templateLoaded || isLoading}
                                                                        >
                                                                            {isLoading ? (
                                                                                <>
                                                                                    <FaSpinner className="fa-spin" />
                                                                                    <span>Loading...</span>
                                                                                </>
                                                                            ) : (
                                                                                'Load Template'
                                                                            )}
                                                                        </button>
                                                                    </div>
                                                                    {isEditing && (
                                                                        <div className="duplicate-template-loaded-confirmation">
                                                                            <TiTick className="duplicate-template-selected-template-confirmation-icon" />

                                                                            <div>
                                                                                <p>Template loaded : {documents.find(doc => doc.shepack_document_id === selectedDocument)?.shepack_document_name}</p>
                                                                                <p className="template-info">You can now edit the template in Sections B and C.</p>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    )}
                                                                </div>

                                                            </div>

                                                        </div>
                                                    
                                                    </div>

                                        

                                        

                                        

                                        

                                    </div>

                                    

                                    
                                </div>
                                        </div>
                                    ) : section === 'B' ? (
                                        <div className="create-checklist-section-a-information-container">
                                            <div className="create-checklist-section-a-information-container-container">
                                                {categoryError && <p className="error-text">{categoryError}</p>}
                                            </div>
                                            <div className="create-checklist-section-a-information-container-container">
                                                <div className="create-checklist-section-a-information-checklist-category-container">

                                                    <div>
                                                        <p className="category-secondary-color">Create Question Category</p>
                                                        <p>To create a category, enter the category name in the input field and click the "Save Category" button to save it.<br /><br /> Saved categories will appear in the saved categories box</p>

                                                    </div>

                                                    <div className="create-checklist-section-a-information-container">

                                                        <div>
                                                            <input
                                                                className="create-checklist-questions-form-name-input"
                                                                placeholder="Enter Category name"
                                                                value={newCategory}
                                                                onChange={(e) => {
                                                                    setNewCategory(e.target.value);
                                                                    setCategoryError("");
                                                                }}
                                                            />

                                                        </div>

                                                        <div>

                                                            <button type="button" onClick={handleAddCategory} className="confirm-button">Save Category</button>

                                                        </div>

                                                        
                                                        

                                                    </div>
                                                    
                                                    
                                                    
                                                </div>
                                                <div className="create-checklist-section-a-information-checklist-saved-category-container">
                                                    <h4>Saved Categories</h4>
                                                    <p>To edit a category, click on the pen icon. <br /><br />Please remember to save your changes by clicking the green tick.</p>
                                                    <div className="create-checklist-section-a-information-checklist-saved-category-list-container">
                                                        {categories.length === 0 ? (
                                                            <div className="add-question-view-checklist-no-categories-message">
                                                                <p style={{ fontStyle: 'italic' }}>No categories have been added yet.</p>
                                                            </div>
                                                        ) : (
                                                            categories.map((category, index) => (
                                                                <div key={index} className="add-question-view-checklist-category-list-item-main-container">
                                                                    {editingCategory === category ? (
                                                                        <div className="add-question-view-checklist-category-list-item-container">
                                                                            <input
                                                                                value={editedCategoryName}
                                                                                onChange={(e) => setEditedCategoryName(e.target.value)}
                                                                                className="add-question-view-checklist-category-list-input"
                                                                            />
                                                                            <div className="add-question-view-checklist-icon-container">
                                                                                <TiTick className="add-question-view-checklist-tick-icon-container" onClick={() => {
                                                                                    if (editedCategoryName.trim() === "") {
                                                                                        setCategoryError("Category name cannot be empty");
                                                                                        return;
                                                                                    }
                                                                                    if (categories.includes(editedCategoryName) && editedCategoryName !== editingCategory) {
                                                                                        setCategoryError("This category name already exists");
                                                                                        return;
                                                                                    }
                                                                                    setCategories(prevCategories => prevCategories.map(cat => cat === editingCategory ? editedCategoryName : cat));
                                                                                    setQuestionBankData(prevData => prevData.map(row => ({ ...row, category: row.category === editingCategory ? editedCategoryName : row.category })));
                                                                                    setEditingCategory(null);
                                                                                    setEditedCategoryName("");
                                                                                    setCategoryError("");
                                                                                    setShowToaster(prev => ({ ...prev, editedCategory: true }));
                                                                                }} />
                                                                                <MdOutlineCancel className="add-question-view-checklist-cancel-icon-container" onClick={() => {
                                                                                    setEditingCategory(null);
                                                                                    setEditedCategoryName("");
                                                                                    setCategoryError("");
                                                                                    setShowToaster(prev => ({ ...prev, cancelEditedCategory: true }));
                                                                                }} />
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="add-question-view-checklist-category-list-item-container">
                                                                            <span>{category}</span>
                                                                            <CiEdit className="add-question-view-checklist-category-list-item-edit-icon" onClick={() => {
                                                                                setEditingCategory(category);
                                                                                setEditedCategoryName(category);
                                                                            }} />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ))
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="add-question-view-fillin--checklist-table-container">
                                            <div className="add-question-view-checklist-table-table-container">
                                                <table className="create-questions-view-fillin-checklist-table">
                                                    <colgroup>
                                                        <col style={{ width: "50px" }} />
                                                        <col style={{ width: "150px" }} />
                                                        <col style={{ width: "300px" }} />
                                                        <col style={{ width: "150px" }} />
                                                        <col style={{ width: "150px" }} />
                                                        <col style={{ width: "150px" }} />
                                                        <col style={{ width: "150px" }} />
                                                        {Array.from({ length: visibleOptions }, (_, i) => <col key={i} style={{ width: "150px" }} />)}
                                                    </colgroup>
                                                    <thead>
                                                        <tr>
                                                            {visibleColumnNames.map((columnName, index) => (
                                                                <th key={index} className="table-header">
                                                                    {columnName === 'Remove' ? '' : columnName}
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody className="view-table-body">
                                                        {questionBankData.length === 0 ? (
                                                            <tr>
                                                                <td colSpan={visibleColumnNames.length}>No Questions added to checklist, click 'add row' to add a question.</td>
                                                            </tr>
                                                        ) : (
                                                            questionBankData.map((row, rowIndex) => (
                                                                <tr key={rowIndex}>
                                                                    <td>
                                                                        <button onClick={() => handleRemoveRow(rowIndex)} className="remove-row-button">
                                                                            <MdDeleteOutline className='delete-checklist-submition-icon' />
                                                                        </button>
                                                                    </td>
                                                                    <td>
                                                                        <select
                                                                            value={row['question_type'] || 'Radio'}
                                                                            onChange={(e) => handleInputChange(rowIndex, 'question_type', e.target.value)}
                                                                        >
                                                                            {questionTypes.map((type, index) => (
                                                                                <option key={index} value={type}>{type}</option>
                                                                            ))}
                                                                        </select>
                                                                    </td>

                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            value={row['question_name'] || ''}
                                                                            onChange={(e) => handleInputChange(rowIndex, 'question_name', e.target.value)}
                                                                            placeholder="Enter question name"
                                                                            className="create-questions-form-question-input-column"
                                                                        />
                                                                    </td>

                                                                    {/* Column - question reference input field*/}
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            value={row['question_reference'] || ''}
                                                                            onChange={(e) => handleInputChange(rowIndex, 'question_reference', e.target.value)}
                                                                            placeholder="Enter question reference"
                                                                            className="create-questions-form-question-input-column"
                                                                        />
                                                                    </td>

                                                                    <td>
                                                                        <input
                                                                            type="number"
                                                                            value={row['question_score'] || ''}
                                                                            onChange={(e) => handleInputChange(rowIndex, 'question_score', e.target.value)}
                                                                            placeholder="Score"
                                                                            className="create-questions-form-question-number-input-column"
                                                                        />
                                                                    </td>

                                                                    <td>
                                                                        <select
                                                                            value={row['category'] || ''}
                                                                            onChange={(e) => handleInputChange(rowIndex, 'category', e.target.value)}
                                                                        >
                                                                            <option value="">Select a category</option>
                                                                            {categories.map((category, index) => (
                                                                                <option key={index} value={category}>{category}</option>
                                                                            ))}
                                                                        </select>
                                                                    </td>

                                                                    <td>
                                                                        <select
                                                                            value={row['file_required'] || 'No'}
                                                                            onChange={(e) => handleInputChange(rowIndex, 'file_required', e.target.value)}
                                                                        >
                                                                            <option value="Yes">Yes</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </td>

                                                                    {/*
                                                                    <td>
                                                                        <select
                                                                            value={row['is_mandatory'] || 'No'}
                                                                            onChange={(e) => handleInputChange(rowIndex, 'is_mandatory', e.target.value)}
                                                                        >
                                                                            <option value="Yes">Yes</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </td>
                                                                    
                                                                    */}
                                                                    

                                                                    <td>
                                                                        <select
                                                                            value={row['comment'] || 'No'}
                                                                            onChange={(e) => handleInputChange(rowIndex, 'comment', e.target.value)}
                                                                        >
                                                                            <option value="Yes">Yes</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </td>

                                                                    {/*

                                                                    <td>
                                                                        <select
                                                                            value={row['expires'] || 'No'}
                                                                            onChange={(e) => handleInputChange(rowIndex, 'expires', e.target.value)}
                                                                        >
                                                                            <option value="Yes">Yes</option>
                                                                            <option value="No">No</option>
                                                                        </select>
                                                                    </td>
                                                                    
                                                                    
                                                                    */}

                                                                    
                                                                    
                                                                </tr>
                                                            ))
                                                        )}
                                                    </tbody>
                                                </table>
                                                <div>
                                                   
                                                    <p className="secondary-color-paragraph"><i>Click the clear options button to reset all remove all the questions.</i></p>
                                                </div>
                                                {errors.length > 0 && (
                                                    <div className="error-text">
                                                        <ul>
                                                            {errors.map((error, index) => (
                                                                <li key={index}>{error}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                                <div className="add-row-add-option-container">
                                                    <button onClick={handleAddRow} className="confirm-button-response">Add Question</button>
                                                    <button onClick={handleClearQuestions} className="cancel-button">Clear Questions</button>
                                                </div>
                                                {optionError && <div className="error-text">{optionError}</div>}
                                                <div style={{ textAlign: 'center' }}>
                                                    <p>By clicking 'Create Template' tempplate will be created.</p>
                                                </div>
                                                <div className="add-question-submit-clear-button-container">
                                                    <button onClick={handleSubmit} className="confirm-button" disabled={submitStatus === 'loading'}>
                                                        {submitStatus === 'loading' ? (
                                                            <>
                                                                <FaSpinner className="fa-spin" />
                                                                <span className="submitting-questions-text"> Creating Template...</span>
                                                            </>
                                                        ) : (
                                                            'Create Template'
                                                        )}
                                                    </button>
                                                    <button onClick={handleReset} className="cancel-button">Reset Template</button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            
            {showToaster.rowAdded && <ItemAddedToaster item={'Question Row Added'} />}
            {showToaster.rowRemoved && <ItemRemovedToaster item={'Question Row Deleted'} />}
            {showToaster.optionAdded && <ItemAddedToaster item={'Option Added'} />}
            {showToaster.categoryAdded && <ItemAddedToaster item={'Category Added'} />}
            {showToaster.editedCategory && <ItemAddedToaster item={'Category name edited'} />}
            {showToaster.cancelEditedCategory && <ItemAddedToaster item={'Category Edit Cancelled'} />}
            
            {showSuccess && 
        
                <CreatedSuccesFullyToaster
                heading='Template Created Successfully'
                />
            }
            
            
        </div>
    );
};

export default DuplicateTemplateComponent;