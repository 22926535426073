/* SHE Pack Component

    This component consists of all the she pack compoents that the user can user to naviagte to different options of the SHE Pack
*/

// -------------- React Components ----------
import React from "react";
import { Link } from "react-router-dom";



// ------------- Icons --------------

import { IoDuplicateOutline } from "react-icons/io5"; // Icon - Duplicate SHE File

import { AiOutlineFolderAdd } from "react-icons/ai"; // Icon -  Create File
import { AiOutlineFileAdd } from "react-icons/ai"; // Icon - create Template

import { CiEdit } from "react-icons/ci";
import { SlGraph } from "react-icons/sl";

import { LuFolderTree } from "react-icons/lu";

import { VscGraphLeft } from "react-icons/vsc";


import { TfiWrite } from "react-icons/tfi";

import { CiPen } from "react-icons/ci";

import { VscGraph } from "react-icons/vsc";
import { GoGraph } from "react-icons/go";
import { VscGraphScatter } from "react-icons/vsc";

import { TbReportSearch } from "react-icons/tb";



// ------------ CSS File ------------ 
import '../../assets/css/Checklists.css';

const SheManagement = () => {

    const user = JSON.parse(sessionStorage.getItem('user'));

    

    return (

        <div className="component-container">

            <div className="manage-users-component-container">

                <div className="manage-users-header-component-container">

                    <div>
                        <h1>SHE Management</h1>
                    </div>

                </div>

                {/* Section - File and Template Manager   */}
                {(user.userRole === 'super admin' || user.userRole === 'user')  && (
                
                    <div className="manage-users-company-main-container">

                        <div className="manage-users-text-container">
                            <div>
                                <h2>File and Templates Management</h2>
                                <p>Create, edit, and manage files and templates efficiently.</p>
                            </div>
                        
                        </div>

                        {/* Items - file and Template Manager */}
                        <div className="manage-users-feature-main-container">

                            {/* Link - Create SHE File */}
                            <Link className="link" to='/she-pack/create-she-pack'>

                                <div className='manage-users-feature-container'>

                                    <div className="feature-img-container">
                                        <AiOutlineFolderAdd className="feature-img"/>
                                    </div>

                                    <div className="feature-name-container">

                                        <h4>Create SHE File</h4>

                                    </div>

                                    <div className="feature-description-container">

                                        <p>
                                            To create a she file tailored to your requirements
                                        </p>

                                    </div>

                                </div>
                                
                                </Link>

                                <Link className="link" to='/she-pack/create-she-pack-document'>

                                <div className='manage-users-feature-container'>

                                    <div className="feature-img-container">
                                        <AiOutlineFileAdd className="feature-img"/>
                                    </div>

                                    <div className="feature-name-container">

                                        <h4>Create Template</h4>

                                    </div>

                                    <div className="feature-description-container">

                                        <p>
                                            To create a template tailored to your requirements
                                        </p>

                                    </div>

                                </div>
                                
                                </Link>

                                <Link className="link" to='/she_file/duplicate_template'>

                                    <div className='manage-users-feature-container'>

                                        <div className="feature-img-container">
                                            <IoDuplicateOutline className="feature-img"/>
                                        </div>

                                        <div className="feature-name-container">

                                            <h4>Duplicate Template</h4>

                                        </div>

                                        <div className="feature-description-container">

                                            <p>
                                                To duplciate an exisiting template.
                                            </p>

                                        </div>

                                    </div>
                                
                                </Link>

                                <Link className="link" to='/she_file/edit_template'>

                                    <div className='manage-users-feature-container'>

                                        <div className="feature-img-container">
                                            <CiEdit className="feature-img"/>
                                        </div>

                                        <div className="feature-name-container">

                                            <h4>Edit Template</h4>

                                        </div>

                                        <div className="feature-description-container">

                                            <p>
                                                To edit an exisiting template.
                                            </p>

                                        </div>

                                    </div>
                                
                                </Link>
                        
                        
                        </div>
                    </div>
                )}

                
                {/* Section - File and Template Viewer */}
                {(user.userRole === 'super admin' || user.userRole === 'user')  && (

                    <div className="manage-users-company-main-container">

                        <div className="manage-users-text-container">
                            <div>
                                <h2>File and Template Viewer</h2>
                                <p>View files and documents with ease for quick access and reference.</p>
                            </div>
                        
                        </div>

                        {/* Items - file and Template Manager */}
                        <div className="manage-users-feature-main-container">

                            {/* Link - Create SHE File */}
                            <Link className="link" to='/she-pack/template/view_submissions'>

                                    
                                    <div className='manage-users-feature-container'>

                                        <div className="feature-img-container">

                                            <VscGraph className="feature-img"/>

                                        </div>

                                        <div className="feature-name-container">

                                            <h4>View Template Submissions</h4>

                                        </div>

                                        <div className="feature-description-container">

                                            <p>
                                                View all responses, percentages, and scores for completed templates.
                                            </p>

                                        </div>

                                    </div>
                                
                                </Link>

                                {/* View SHE File Submissions */}
                                <Link className="link" to='/she-pack/she_file/view_she_submissions'>

                                    {/* View SHE File Submissions */}
                                    <div className='manage-users-feature-container'>

                                        <div className="feature-img-container">

                                            <GoGraph  className="feature-img"/>

                                        </div>

                                        <div className="feature-name-container">

                                            <h4>View SHE File Submissions</h4>

                                        </div>

                                        <div className="feature-description-container">

                                            <p>
                                                View the summary of the SHE File submissions.
                                            </p>

                                        </div>

                                    </div>
                                
                                </Link>

                                {/* Consultant Fill In */}
                                <Link className="link" to='/she-pack/she_file/administrator/view_she_submissions'>

                                    {/* Fill Checklist */}
                                    <div className='manage-users-feature-container'>

                                        <div className="feature-img-container">

                                            <VscGraphLeft  className="feature-img"/>

                                        </div>

                                        <div className="feature-name-container">

                                            <h4>Administrator View SHE File Submissions </h4>

                                        </div>

                                        <div className="feature-description-container">

                                            <p>
                                                View the summary of the SHE FIle submisisons as Administrator.
                                            </p>

                                        </div>

                                    </div>
                                
                                </Link>

                                {/* Consultant Fill In */}
                                <Link className="link" to='/she-pack/she_file/administrator/view_template_she_submissions'>

                                    {/* Fill Checklist */}
                                    <div className='manage-users-feature-container'>

                                        <div className="feature-img-container">

                                            <VscGraphScatter  className="feature-img"/>

                                        </div>

                                        <div className="feature-name-container">

                                            <h4>Administrator View Template Submissions </h4>

                                        </div>

                                        <div className="feature-description-container">

                                            <p>
                                                View the summary of the SHE Template submisisons as Administrator.
                                            </p>

                                        </div>

                                    </div>
                                
                                </Link>
                                
                            
                        
                        </div>
                    </div>
                    )}


                {/* Dev Section  */}
                {(user.userRole === 'super admin' || user.userRole === 'user')  && (
                    <div className="manage-users-company-main-container">

                        <div className="manage-users-text-container">

                            <div>
                                <h2>Quality Assurance</h2>
                                <p>Review and manage file submissions that are prepared for the Quality Assurance (QA) process. </p>
                            </div>

                        
                        </div>

                        {/* Navigations to applicaiton pages */}
                        <div className="manage-users-feature-main-container">

                        
                                {/* User Fill In */}
                                <Link className="link" to='/she-pack/quality_assurance/view_template_she_submissions'>

                                    {/* Fill Checklist */}
                                    <div className='manage-users-feature-container'>

                                        <div className="feature-img-container">

                                            <TbReportSearch className="feature-img"/>

                                        </div>

                                        <div className="feature-name-container">

                                            <h4>Quality Assurance - Review Consultant Submissions</h4>

                                        </div>

                                        <div className="feature-description-container">

                                            <p>
                                                Review and manage template submissions.
                                            </p>

                                        </div>

                                    </div>
                                
                                </Link>

                                {/* User Fill In */}
                                <Link className="link" to='/she-pack/quality_assurance/qa-view_template_she_submissions'>

                                    {/* Fill Checklist */}
                                    <div className='manage-users-feature-container'>

                                        <div className="feature-img-container">

                                            <SlGraph className="feature-img"/>

                                        </div>

                                        <div className="feature-name-container">

                                            <h4>View QA Template Submissions</h4>

                                        </div>

                                        <div className="feature-description-container">

                                            <p>
                                            View all quality-assured reviewed responses and scores for completed templates.
                                            </p>

                                        </div>

                                    </div>
                                
                                </Link>

                               
                        
                        </div>

                    </div>

                )}

                {/* SHE File Templates submissions Section  */}
                {(user.userRole === 'super admin' || user.userRole === 'user')  && (
                    <div className="manage-users-company-main-container">

                        <div className="manage-users-text-container">

                            <div>
                                <h2>SHE File & Template Submissions</h2>
                                <p>Complete and submit SHE files and templates.</p>
                            </div>

                        
                        </div>

                        {/* Navigations to applicaiton pages */}
                        <div className="manage-users-feature-main-container">

                        
                                {/* User Fill In */}
                                

                                {/*
                                    <Link className="link" to='/she-pack/she-pack-fill-in'>

                                    
                                    <div className='manage-users-feature-container'>

                                        <div className="feature-img-container">

                                            <TfiWrite className="feature-img"/>

                                        </div>

                                        <div className="feature-name-container">

                                            <h4>SHE File Fill In</h4>

                                        </div>

                                        <div className="feature-description-container">

                                            <p>
                                                To fill in available she packs.
                                            </p>

                                        </div>

                                    </div>
                                
                                </Link>
                                */}

                             
                                <Link className="link" to='/she-pack/consultant/she-pack-fill-in'>

                                    
                                    <div className='manage-users-feature-container'>

                                        <div className="feature-img-container">

                                            <CiPen className="feature-img"/>

                                        </div>

                                        <div className="feature-name-container">

                                            <h4>Consultant SHE File Fill In</h4>

                                        </div>

                                        <div className="feature-description-container">

                                            <p>
                                                To complete she file and Template.
                                            </p>

                                        </div>

                                    </div>
                                
                                </Link>

                              
                        
                        </div>

                    </div>

                )}

            </div>

        </div>

    )
}

export default SheManagement