/* ---------- Create Checklist toaster component ------------------

    -   This component is used for when the user wants to create a custom SHE Pack document. 
*/

// ------------------ React Components ------------------------
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// -------------------- API -----------------------------
import API_BASE_URL from "../../utils/api/apiConfig.js";

// --------------------- icons --------------------
import { MdDeleteOutline } from "react-icons/md";
import { FaSpinner } from "react-icons/fa";


// ------------------- CSS ----------------------
import '../../../assets/css/ShePack.css'
import '../../../assets/css/Checklists.css'


// --------------------- Assests -------------------
import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png'; 

// ------------------- Application components --------------------
import ItemAddedToaster from "../../toasterscomponents/checklistToasters/ItemAddedToaster.js";
import ItemRemovedToaster from "../../toasterscomponents/checklistToasters/ItemRemovedToaster.js";

import CreatedSuccesFullyToaster from "../../toasterscomponents/shepacktoasters/CreatedSuccesFullyToaster.js"; // Toaster for when document is created successfully


// --------------------- Component - Create She Pack Documents -------------------
const CreateTemplatesComponent = ({ formName, checklistUniqueIdentifier }) => {

    // ------------------- State Managements ------------------

    //
    const navigate = useNavigate();

    //
    const user = JSON.parse(sessionStorage.getItem('user'));

    // State Management 
    const [isLoading, setIsLoading] = useState(true);
    const [editError, setEditError] = useState("");
    const [submitStatus, setSubmitStatus] = useState('idle');
    const [errors, setErrors] = useState([]);

    //
    const [viewSection, setViewSection] = useState({ A: true, C: false });
    
    // State Management - SHE Pack Documents
    const [templateName, setTemplateName] = useState("");

    // State Management - SHE Packs
    const [sheFiles, setShePacks] = useState([]);
    const [selectedSHEFiles, setSelectedSHEFile] = useState("");
    const [isPackSelected, setIsPackSelected] = useState(false);

    // state managment - section c questions
    const [templatesDetails, setTemplatesDetails] = useState([]);
    const [totalScore, setTotalScore] = useState(0);

    //
    const [isEditing, setIsEditing] = useState(false);

    
    // state management - section Create questions 
    
    
    const [templateReference] = useState(['Certificate','Checklist' , 'Document', 'Inspection Sheet', 'Proof of communication', 'Medical Certificate', 'List' , 'Register', 'Report']);

    
    const [columns, setColumns] = useState([]);

   
    
    const [optionError, setOptionError] = useState("");
    const [showToaster, setShowToaster] = useState({ success: false, categoryAdded: false, rowAdded: false, rowRemoved: false, optionAdded: false, editedCategory: false, cancelEditedCategory: false , fileSelected : false });

    

    useEffect(() => {
        calculateTotalScore();
    }, [templatesDetails]);

    // Function to calculate total score
    const calculateTotalScore = () => {
        const total = templatesDetails.reduce((sum, question) => {
            const score = parseFloat(question.question_score) || 0;
            return sum + score;
        }, 0);
        setTotalScore(total);
    };



    useEffect(() => {

        const fetchShePacks = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/complaince-tracker/create-templates/get-she-files`);
                
                // Only set checklists if data is an array
                if (Array.isArray(response.data)) {
                    setShePacks(response.data);
                } else {
                    console.error('Invalid response format', response.data);
                    setShePacks([]);
                }
                
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching SHE File:', error);
                setEditError(error.response?.data?.message || 'Failed to load File');
                setIsLoading(false);
                setShePacks([]);
            }
        };
    
        fetchShePacks();
    }, []); // Empty dependency array means this runs once on component mount

    

    
    // Handle edit button click
    const handleSelectSHEpack = () => {

        if (!selectedSHEFiles) {
            setEditError("Please select a SHE File.");
            return;
        }

        setShowToaster(prev => ({ ...prev, fileSelected: true }));
        setEditError("");
        setIsPackSelected(true);
        
    };
    

    useEffect(() => {
       
        fetchQuestionBankData();
        
    }, []);

    useEffect(() => {
        const timers = Object.keys(showToaster).map(key => showToaster[key] && setTimeout(() => setShowToaster(prev => ({ ...prev, [key]: false })), 3000));
        return () => timers.forEach(timer => clearTimeout(timer));
    }, [showToaster]);

    const fetchQuestionBankData = async () => {
        try {
            const columnsResponse = await axios.get(`${API_BASE_URL}/columns/Question_Bank`);
            setColumns(columnsResponse.data);
        } catch (error) {
            console.error('Error fetching Question Bank data:', error);
        }
    };

    //
    const handleReset = () => {
        // Reset all form-related states to their initial state
        setTemplateName("");
        
        // Instead of using initializePredefinedQuestions(), we'll start completely from scratch
        setTemplatesDetails([]);
        
       
        
        // Reset other related states
    
        setErrors([]);
        
        // Reset view sections
        setViewSection({ A: true, B: false, C: false });
    };

    const handleClearQuestions = () => {
        
        // Instead of using initializePredefinedQuestions(), we'll start completely from scratch
        setTemplatesDetails([]);

      
    
        // Reset other related states
        setErrors([]);
        
       
    };


    // Update handleInputChange to handle template_score correctly
const handleInputChange = (rowIndex, columnName, value) => {
    const updatedData = [...templatesDetails];
    if (columnName === '') {
        for (let i = 1; i <= 15; i++) updatedData[rowIndex][`option_${i}`] = '';
        if (value !== 'File') updatedData[rowIndex]['type_file_upload'] = '';
    }
    
    // 
    if (columnName === 'template_score') {
        updatedData[rowIndex] = { 
            ...updatedData[rowIndex], 
            [columnName]: value,
            template_score: value  // 
        };
    } else {
        updatedData[rowIndex] = { ...updatedData[rowIndex], [columnName]: value };
    }
    
    setTemplatesDetails(updatedData);
};

    const handleAddRow = () => {
        const newRow = {
            checklist_id: checklistUniqueIdentifier,
            checklist_name: formName,
            
            category: '' // Set default category to empty string
        };

        setShowToaster(prev => ({ ...prev, rowAdded: true }));
        setTemplatesDetails([...templatesDetails, newRow]);
    };

   

    const handleRemoveRow = (rowIndex) => {
        // Remove row without any restrictions
        setShowToaster(prev => ({ ...prev, rowRemoved: true }));
        setTemplatesDetails(templatesDetails.filter((_, index) => index !== rowIndex));
    };

    

    const validateData = () => {
        const newErrors = [];
        
        templatesDetails.forEach((row) => {
            if (!row.template_name || row.template_name.trim() === '') newErrors.push("Error - Template Name : Template name cannot be empty.");
            
        });

        setErrors(newErrors);
        return newErrors.length === 0;

    };

   
    const visibleColumnNames = [
        
        'Remove',
        'template_reference',
        'template_name',
        'template_frequency',
        'template_score',
        'submission_type'
       
        //'is_mandatory',
    
       
        //'expires',
        //...Array.from({ length: visibleOptions }, (_, i) => `option_${i + 1}`),

    ];

    // Handle Submit all Questions
    const handleSubmit = async () => {
        setSubmitStatus('loading');
        setErrors([]);
        
        let newErrors = [];
        
        if (templatesDetails.length === 0) {
            newErrors.push("No Templates Added - Add at least one template.");
        }
    
        if (newErrors.length > 0 || !validateData()) {
            setErrors(prevErrors => [...prevErrors, ...newErrors]);
            window.scrollTo(0, 0);
            setSubmitStatus('idle');
            return;
        }
    
        try {
            if (!user || !user.user_id) {
                throw new Error('User session data is missing');
            }
    
            // Find the selected SHE File details
            const selectedSHEFileDetails = sheFiles.find(pack => pack.shefile_id === selectedSHEFiles);
    
            if (!selectedSHEFileDetails) {
                throw new Error('Selected SHE File not found');
            }
    
            // Prepare template details with correct property names
            const formattedTemplateDetails = templatesDetails.map(template => ({
                template_name: template.template_name,
                template_reference: template.templateReference,
                submission_type: template.submission_type,
                template_score: template.question_score,  // Use question_score as template_score
                template_frequency: template.template_frequency
            }));
    
            // Match the data structure expected by the backend
            const response = await axios.post(`${API_BASE_URL}/compliance-tracker/create-templates/${selectedSHEFiles}`, {
                created_by: user.user_id,
                shefile_id: selectedSHEFileDetails.shefile_id,
                shefile_name: selectedSHEFileDetails.shefile_name,
                template_score: totalScore,
                templatesDetails: formattedTemplateDetails
            });
    
            console.log('Template created successfully:', response.data);
            setShowToaster(prev => ({ ...prev, success: true }));
            setSubmitStatus('success');
    
            setTimeout(() => {
                setShowToaster(prev => ({ ...prev, success: false }));
                navigate('/she-pack/she-pack-management');
            }, 2000);
    
        } catch (error) {
            console.error('Error creating template:', error);
            setErrors(prevErrors => [...prevErrors, `Error creating template: ${error.message}`]);
            setSubmitStatus('error');
        }
    };

    return (

        <div className="add-question-custom-field-main-component-container">
            
            <div className="add-question-create-questions-checklist-custom-field-component-container">

                <div className="create-questions-custom-field-container">

                    <div className="add-question-view-checklist-component-container">
                        
                        <div className="add-question-view-checklist-header-container">

                            <div>
                                <img src={blueKbcLogo} alt="white-kbc-logo" className="add-quesiton-side-menu-logo" />
                            </div>

                            <div className="navigate-back-button-container">
                                <button className="navigate-back-button" onClick={() => navigate(-1)}>Back</button>
                            </div>

                        </div>

                        <div className="add-question-view-checklist-header-step-container">
                            <div>
                                <h2>Compliance tracker - Create Templates</h2>
                                
                                <p className="secondary-color-paragraph">To complete each section, click on the 'Maximize' button to expand the section.</p>
                            </div>
                        </div>

                        {['A', 'B'].map(section => (
                            <div key={section} className="create-checklist-section-a-information-main-container">
                                <div className="create-checklist-section-container-main-container">
                                    <div className="create-checklist-section-container">
                                        <div>
                                            <h3>Section {section} - {section === 'A' ? 'Information' : 'Create Templates'}</h3>
                                        </div>
                                        <div>
                                            <button className="confirm-button" onClick={() => setViewSection(prev => ({ ...prev, [section]: !prev[section] }))}>
                                                {viewSection[section] ? 'Minimize' : 'Maximize'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {viewSection[section] && (
                                    section === 'A' ? (
                                        <div className="create-checklist-section-a-information-container">
                                            <div className="create-checklist-section-a-information-container-container">

                                                <div>

                                                    <div>

                                                        <h3 className="category-secondary-color">SHE File Details</h3>
                                                    
                                                    </div>

                                                    <div>
                                                        
                                                        <p>Please fill in the SHE File details below.</p>

                                                    </div>

                                                </div>
                                                
                                            </div>
                                            

                                            <div className="create-checklist-section-a-information-container-container">

                                                <div className="duplicate-checklist-section-a-information-checklist-name-container-container">

                                                    <div className="duplicate-checklist-section-a-information-checklist-name-container">
                                        
                                                        
                                                        <div className="duplicate-fillin-select-checklist-section-a-information-checklist-name-container">

                                                            {/* Checklist Name */}
                                                                <div >

                                                                    <div className="create-checklist-section-a-information-container-container">
                                                                        
                                                                        <div className="duplicate-fillin-select-checklist-section-a-information-checklist-name-name-container">
                                                                            
                                                                            <div>
                                                                                <h4>1. Select SHE File</h4>

                                                                                <p>Select the SHE File that you want the template to belong to.</p>
                                                                            </div>

                                                                            <div>
                                                                                <select
                                                                                    value={selectedSHEFiles}
                                                                                    onChange={e => setSelectedSHEFile(e.target.value)}
                                                                                    disabled={isEditing}
                                                                                >
                                                                                    <option value="">-- Select SHE Pack --</option>
                                                                                    {sheFiles.map(sheFile => (
                                                                                        <option key={sheFile.shefile_id} value={sheFile.shefile_id}>
                                                                                            {sheFile.shefile_name}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            </div>
                                                                            
                                                                        </div>

                                                                    </div>

                                                                </div>

                                                            <div className="add-question-view-checklist-category-container">
                                    

                                                                {editError && <div className="error-text">{editError}</div>}

                                                                <div>
                                                                    <p className="secondary-color-paragraph" style={{ fontStyle: 'italic' }}>Important. Click "Select SHE File" to select the SHE File that you want the template to be saved to.</p>
                                                                </div>

                                                                {isPackSelected && (

                                                                    <div>
                                                                        <div>
                                                                            <p className="secondary-color-paragraph">Selected SHE File: {sheFiles.find(pack => pack.shefile_id === selectedSHEFiles)?.shefile_name || selectedSHEFiles}</p>
                                                                        </div>
                
                                                                    </div>

                                                                )}

                                                                <div>
                                                                
                                                                    <button 
                                                                            className="confirm-button" 
                                                                            onClick={handleSelectSHEpack}
                                                                            disabled={isEditing || isLoading}
                                                                        >
                                                                            {isLoading ? (
                                                                                <>
                                                                                    <FaSpinner className="fa-spin" />
                                                                                    <span>Loading...</span>
                                                                                </>
                                                                            ) : (
                                                                                'Select SHE File'
                                                                            )}
                                                                        </button>
                                                                </div>
                                                            </div>

                                                                

                                                        </div>
                                                    </div>

                                    </div>
                                    
                                </div>
                                        </div>
                                    ) : (
                                        <div className="add-question-view-fillin--checklist-table-container">

                                            {/* SHE Pack Sore */}
                                            <div className="create-she-pack-document-quesiton-bank-table-total-score-main-container">

                                                <div className="create-she-pack-document-quesiton-bank-table-total-score-container">
                                                    <div>
                                                        <h4 className="secondary-color-text">Current Document Score</h4>
                                                    </div>

                                                    <div>
                                                        <div>
                                                            <p>{totalScore}</p>
                                                        </div>
                                                    </div>
                                                </div>
                            
                                            </div>

                                            {/* Question Bank Table */}
                                            <div className="add-question-view-checklist-table-table-container">
                                                <table className="create-questions-view-fillin-checklist-table">
                                                    <colgroup>
                                                        <col style={{ width: "50px" }} /> {/* Template Reference */}
                                                        <col style={{ width: "200px" }} /> {/* Template Reference */}
                                                        <col style={{ width: "300px" }} /> {/* Template Name */}
                                                        <col style={{ width: "150px" }} /> {/* Template Frequency */}
                                                        <col style={{ width: "200px" }} /> {/* Template Score */}
                                                        <col style={{ width: "150px" }} /> {/* Submission Type */}
                                                       
                                                       
                                                    </colgroup>
                                                    <thead>
                                                        <tr>
                                                            {visibleColumnNames.map((columnName, index) => (
                                                                <th key={index} className="table-header">
                                                                    {columnName === 'Remove' ? '' : columnName}
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody className="view-table-body">
                                                        {templatesDetails.length === 0 ? (
                                                            <tr>
                                                                <td colSpan={visibleColumnNames.length}>No Templates added to selected she file {sheFiles.find(pack => pack.shefile_id === selectedSHEFiles)?.shefile_name || selectedSHEFiles}, click 'add row' to add a template.</td>
                                                            </tr>
                                                        ) : (
                                                            templatesDetails.map((row, rowIndex) => (
                                                                <tr key={rowIndex}>
                                                                    <td>
                                                                        <button onClick={() => handleRemoveRow(rowIndex)} className="remove-row-button">
                                                                            <MdDeleteOutline className='delete-checklist-submition-icon' />
                                                                        </button>
                                                                    </td>
                                                                    <td>
                                                                        <select
                                                                            value={row['templateReference'] || 'Radio'}
                                                                            onChange={(e) => handleInputChange(rowIndex, 'templateReference', e.target.value)}
                                                                        >
                                                                            {templateReference.map((type, index) => (
                                                                                <option key={index} value={type}>{type}</option>
                                                                            ))}

                                                                        </select>
                                                                    </td>

                                                                    {/* Column - question name input field*/}
                                                                    <td>
                                                                        <input
                                                                            type="text"
                                                                            value={row['template_name'] || ''}
                                                                            onChange={(e) => handleInputChange(rowIndex, 'template_name', e.target.value)}
                                                                            placeholder="Enter template name"
                                                                            className="create-questions-form-question-input-column"
                                                                        />
                                                                    </td>

                                                                    <td>
                                                                        <select
                                                                            value={row['template_frequency'] || ''}
                                                                            onChange={(e) => handleInputChange(rowIndex, 'template_frequency', e.target.value)}
                                                                        >
                                                                            <option value="" disabled>-- Select Frequency --</option>
                                                                            <option value="Weekly">Weekly</option>
                                                                            <option value="Monthly">Monthly</option>
                                                                            <option value="Quarterly">Quarterly</option>
                                                                            <option value="Bi-Annually">Bi-Annually</option>
                                                                            <option value="Annually">Annually</option>
                                                                            <option value="2-Yearly">2 Yearly</option>
                                                                            <option value="3-Yearly">3 Yearly</option>
                                                                            <option value="When-Required">When Required</option>


                                                                        </select>
                                                                    </td>

                                                                    <td>
                                                                        <input
                                                                            type="number"
                                                                            value={row['question_score'] || ''}
                                                                            onChange={(e) => handleInputChange(rowIndex, 'question_score', e.target.value)}
                                                                            placeholder="Score"
                                                                            className="create-questions-form-question-number-input-column"
                                                                        />
                                                                    </td>

                                                                    <td>
                                                                        <select
                                                                            value={row['submission_type'] || ''}
                                                                            onChange={(e) => handleInputChange(rowIndex, 'submission_type', e.target.value)}
                                                                        >
                                                                            <option value="" disabled>-- Select Submission type -- </option>
                                                                            <option value="Manual">Manual</option>
                                                                            <option value="Upload">Upload</option>
                                                                        </select>
                                                                    </td>

                                                                    
                                                                    
                                                                </tr>
                                                            ))
                                                        )}
                                                    </tbody>
                                                </table>
                                                <div>
                                                   <p className="secondary-color-paragraph"><i>Click the clear options button to reset all remove all the questions.</i></p>
                                                </div>
                                                {errors.length > 0 && (
                                                    <div className="error-text">
                                                        <ul>
                                                            {errors.map((error, index) => (
                                                                <li key={index}>{error}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                                <div className="add-row-add-option-container">
                                                    <button onClick={handleAddRow} className="confirm-button-response">Add Template</button>
                                              
                                                    <button onClick={handleClearQuestions} className="cancel-button">Clear</button>
                                                </div>
                                                {optionError && <div className="error-text">{optionError}</div>}
                                                
                                                <div style={{ textAlign: 'center' }}>
                                                    <p>By clicking 'Create Template' templates will be created for SHE file {selectedSHEFiles}.</p>
                                                </div>

                                                <div className="add-question-submit-clear-button-container">
                                                    <button onClick={handleSubmit} className="confirm-button" disabled={submitStatus === 'loading'}>
                                                        {submitStatus === 'loading' ? (
                                                            <>
                                                                <FaSpinner className="fa-spin" />
                                                                <span className="submitting-questions-text"> Create template...</span>
                                                            </>
                                                        ) : (
                                                            'Create Templates'
                                                        )}
                                                    </button>
                                                    <button onClick={handleReset} className="cancel-button">Reset template</button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {showToaster.fileSelected && <ItemAddedToaster item={'SHE File Selected'} />}
            {showToaster.rowAdded && <ItemAddedToaster item={'Template Row Added'} />}
            {showToaster.rowRemoved && <ItemRemovedToaster item={'Template Row Deleted'} />}
            
           
            
            {/* Created Succesfully Toaster - Toaster shows when the user has created a document succesfully */}
            {showToaster.success && 
                <CreatedSuccesFullyToaster
                    heading='Template Created Successfully'
                />
            }

            
            
            
        </div>
    );
};

export default CreateTemplatesComponent;