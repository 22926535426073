/* ---------- View SHE Pack component ------------------

    -   This component is used for when user wants to view the SHE Packs. 
*/


// ------------------ React Components ------------------------
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

// -------------------- API -----------------------------
import API_BASE_URL from "../../utils/api/apiConfig.js";

// ------------------- Application components --------------------
import ConfirmdeleteTableToaster from '../../toasterscomponents/checklistToasters/ConfirmdeleteTableToaster';

// --------------------- CSS File --------------------
import '../../../assets/css/Checklists.css';

// --------------------- icons --------------------
import { FaRegEye } from 'react-icons/fa';
import { LuUsers } from 'react-icons/lu';
import { MdDeleteOutline } from "react-icons/md";
import { CiImageOn } from "react-icons/ci";
import { LiaSpinnerSolid } from "react-icons/lia";
import { BsFileEarmarkPdf } from "react-icons/bs";

import { FiEdit } from "react-icons/fi";

// --------------------- Assests -------------------
import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png';


// --------------------- View SHE Pack component -------------------
const ViewShePack = () => {

  const navigate = useNavigate();

  // --------------- State Managements ------------------------
  const [shePacks, setShePacks] = useState([]);

  // State Management - selected she pack
  const [selectedShePackName, setSelectedShePackName] = useState('');
  const [selectedShePack, setSelectedShePack] = useState(null);

  const [shePackDocuments, setShePackDocuments] = useState([]);
  const [selectedShePackId, setSelectedShePackId] = useState(null);


  const [isLoading, setIsLoading] = useState(true);
  
  
  const [checklistToDelete, setChecklistToDelete] = useState(null);
  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [showDeleteToaster, setShowDeleteToaster] = useState(false);
  const [tableToDelete, setTableToDelete] = useState(null);
  const [expandedImageIndex, setExpandedImageIndex] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [deletionCount, setDeletionCount] = useState(0);
  const [uniqueUsers, setUniqueUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedColumns, setSelectedColumns] = useState([]);

  

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = rows.slice(indexOfFirstRow, indexOfLastRow);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [uniqueSubmissionIds, setUniqueSubmissionIds] = useState([]);
  const [selectedSubmissionId, setSelectedSubmissionId] = useState('');

  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  // ------------------- Functions ---------------------------

  // Functions - Fetches all the SHE Pack names
  const fetchShePackNames = async () => {

    setIsLoading(true);

    try {
      const user = JSON.parse(sessionStorage.getItem('user'));
      const response = await axios.get(`${API_BASE_URL}/she-pack/view-she-packs/get-she-packs`, {
        params: { userId: user.user_id }
      });
      
      if (response.status === 200) {
        setShePacks(response.data);
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching checklist names:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchShePackNames();
  }, []);


  const handleColumnSelection = (columnName) => {
    setSelectedColumns(prevColumns => {
      if (prevColumns.includes(columnName)) {
        return prevColumns.filter(col => col !== columnName);
      } else {
        return [...prevColumns, columnName];
      }
    });
  };

  useEffect(() => {
    if (columns.length > 0 && selectedColumns.length === 0) {
      const initialSelectedColumns = columns
        .filter(column => !['checklist_id', 'question_id', 'user_surname', 'user_id', 'submission_id'].includes(column.name))
        .map(column => column.name);
      setSelectedColumns(initialSelectedColumns);
    }
  }, [columns]);

  // Update the filtering logic to include category
  const filteredRows = rows.filter(row => {
    const userMatch = !selectedUser || row.user_id === selectedUser;
    const submissionMatch = !selectedSubmissionId || row.submission_id === selectedSubmissionId;
    const categoryMatch = !selectedCategory || row.category === selectedCategory;
    return userMatch && submissionMatch && categoryMatch;
  });

  const handleDeleteClick = (shePackId) => {
    setChecklistToDelete(shePackId);
    setShowDeleteToaster(true);
  };

 

  const navigateToManageUsers = () => {
    navigate('/assignusers');
  };

  // Function - Handle click to view all the she pack documents that are linked to SHE Pack
  const handleClick = async (shePackId, shePackName) => {
    setIsLoading(true);
    setSelectedShePackId(shePackId);
    setSelectedShePackName(shePackName); // Save the SHE Pack name
  
    try {
      // Add logging to verify the request
      console.log('Fetching documents for shePackId:', shePackId);
      
      const response = await axios.get(`${API_BASE_URL}/she-pack/view-she-packs/view-she-packs-documents/${shePackId}`);
      
      // Add logging to verify the response
      console.log('API Response:', response.data);
      
      if (response.status === 200) {
        // Verify the data structure
        if (Array.isArray(response.data)) {
          setShePackDocuments(response.data);
          console.log('Set documents:', response.data);
        } else {
          console.error('Response data is not an array:', response.data);
        }
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error fetching SHE Pack documents:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };


  const handleSubmissionIdChange = (event) => {
    setSelectedSubmissionId(event.target.value);
  };


  const handleImageClick = (index) => {
    setExpandedImageIndex(expandedImageIndex === index ? null : index);
  };

  const handleConfirmDelete = async () => {
    if (checklistToDelete) {
      try {
        console.log(`Attempting to delete checklist: ${checklistToDelete}`);
        const response = await axios.delete(`${API_BASE_URL}/delete-checklist/${checklistToDelete}`);
        console.log('Delete response:', response.data);
        
        // Remove the deleted checklist from the state
        setShePacks(prevChecklists => prevChecklists.filter(checklist => checklist.checklist_id !== checklistToDelete));
        
        // Reset selected checklist if it was the one deleted
        if (selectedShePack && selectedShePack.checklist_id === checklistToDelete) {
          setSelectedShePack(null);
          setColumns([]);
          setRows([]);
        }

        console.log(`Checklist ${checklistToDelete} deleted successfully.`);
      } catch (error) {
        console.error('Error deleting checklist:', error);
        // Handle error (e.g., show an error message to the user)
      }
    }
    setShowDeleteToaster(false);
    setChecklistToDelete(null);
  };

  const handleCancelDelete = () => {
    setShowDeleteToaster(false);
    setTableToDelete(null);
  };

  const handleNavigateBack = () => {
    navigate(-1);
  };

  const isValidDate = (date) => {
    const parsedDate = Date.parse(date);
    return !isNaN(parsedDate);
  };

  const sortData = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedRows = [...rows].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
      return 0;
    });

    setRows(sortedRows);
  };

  const Pagination = ({ rowsPerPage, totalRows, paginate, currentPage }) => {
    const pageNumbers = [];
    
    for (let i = 1; i <= Math.ceil(totalRows / rowsPerPage); i++) {
      pageNumbers.push(i);
    }
    
    return (
      <nav aria-label="Pagination">
        <ul className="pagination">
          {pageNumbers.map(number => (
            <li 
              key={number} 
              className={`page-item ${currentPage === number ? 'active' : ''}`}
            >
              <button
                onClick={() => paginate(number)}
                className="page-link"
                aria-current={currentPage === number ? 'page' : undefined}
              >
                {number}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    );
  };

  const bufferToBase64 = (buffer) => {
    if (buffer && buffer.type === 'Buffer' && Array.isArray(buffer.data)) {
      const binaryString = buffer.data.map(byte => String.fromCharCode(byte)).join('');
      return btoa(binaryString);
    }
    return null;
  };

  
  

  const renderFileResponse = (fileResponse, row, index) => {
    if (!fileResponse || !row.response) {
      return <p>Not Applicable</p>;
    }
  
    // Check if the response column ends with .pdf
    const isPdf = row.response.toLowerCase().endsWith('.pdf');
  
    if (isPdf) {
      const base64String = bufferToBase64(fileResponse);
      if (!base64String) {
        return <p>Invalid PDF data</p>;
      }

      const base64ToBinary = atob(base64String)

      
  
      return (
        <div className="pdf-container">
          <BsFileEarmarkPdf 
            className='view-checklist-view-image-icon' 
            onClick={() => {
              // Create a new window
              const pdfWindow = window.open('', '_blank');
              
              // Write the PDF content directly to the new window
              pdfWindow.document.write(
                `<html>
                  <head>
                    <title>${row.response}</title>
                  </head>
                  <body style="margin: 0;">
                    <iframe 
                      src="data:application/pdf;base64,${base64ToBinary}" 
                      width="100%" 
                      height="100%" 
                      style="border: none;"
                    ></iframe>
                  </body>
                </html>`
              );
              pdfWindow.document.close();
            }} 
          />
          
        </div>
      );
    }
  
    // Existing image rendering logic
    const base64String = bufferToBase64(fileResponse);
    if (!base64String) {
      return <p>Invalid image data</p>;
    }

    const base64ToBinary = atob(base64String)
  
    const mimeType = 'image/png'; // Assuming the mimeType is 'image/png'
  
    return (
      <div className="image-container">
        <CiImageOn 
          className='view-checklist-view-image-icon' 
          onClick={() => handleImageClick(index)} 
        />
        {expandedImageIndex === index && (
          <div className="expanded-image-overlay" onClick={() => setExpandedImageIndex(null)}>
            <img
              className='view-checklist-view-image'
              src={`data:${mimeType};base64,${base64ToBinary}`}
              alt='view'
            />
          </div>
        )}
      </div>
    );
  };

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  

  return (
    <div className="component-container">
      <div className="create-checklist-component-container">

        <div className="create-company-text-container">

          <div>
            <h1> View She Files </h1>
          </div>

          <div>
              <img src={blueKbcLogo} alt="white-kbc-logo" className="side-menu-logo" />
          </div>
          
        </div>

        <div className="navigate-back-button-main-container">
          <div className="navigate-back-button-container">
            <button className="navigate-back-button" onClick={handleNavigateBack}>Back</button>
          </div>
        </div>

        <div className="view-checklist-table-container">
          {isLoading ? (
            <div className="loading-spinner-container">
              <LiaSpinnerSolid className="lia-spin-solid-icon" />
            </div>
          ) : (
            <div className="view-checklist-table-table-container">
              <table className="view-checklist-table">

                <colgroup>
                  {/* Remove whitespace between col elements */}
                  <col style={{width: "150px"}}/>{/* Delete icon column */}
                  <col style={{width: "500px"}}/>{/* question_type column */}          
                </colgroup>

                <thead>
                  <tr>
                    <th className="table-header">View SHE Packs</th>
                    <th className="table-header">SHE Pack Name</th>
                    <th className="table-header">SHE Pack Score</th>
                    <th className="table-header">Assign Users</th>
                    <th className="table-header">Edit</th>
                  </tr>
                </thead>

                <tbody className="view-table-body">

                  {shePacks.length === 0 ? (
                    <tr>
                      <td colSpan="4">
                        <p>Currently no SHE Packs created. Click "Create SHE Pack" button to create a SHE Pack.</p>
                        
                        <Link to='/she-pack/create-she-pack'>
                          <div className='view-checklist-create-checklist-button-contianer'>
                            <p>Create SHE Pack</p>
                          </div>
                        </Link>

                      </td>
                    </tr>
                  ) : (
                    shePacks.map((shePack, index) => (

                      <tr key={index}>

                        {/* column - view SHE pack */}
                        <td onClick={() => handleClick(shePack.shepack_id, shePack.shepack_name)}>
                          <FaRegEye className='view-checklist-submition-icon' />
                        </td>

                        {/* column - SHE Pack name */}
                        <td>{shePack.shepack_name}</td>

                        {/* column - SHE Pack score  */}
                        <td onClick={navigateToManageUsers}>
                          <p>{shePack.shepack_score !== null ? shePack.shepack_score : 'No Score'}</p>
                        </td>

                        {/* column - Assign User*/}
                        <td onClick={navigateToManageUsers}>
                          <LuUsers className='manage-users-checklist-submition-icon' />
                        </td>

                        {/* Column - Edit SHE Pack */}
                        <td onClick={() => handleDeleteClick(shePack.shepack_id)}>
                          <FiEdit  className='delete-checklist-submition-icon' />
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>

        {selectedShePackId && (
          <div className='view-checklist-checklist-data-container'>
            <div className="view-data-checklist-table-container">
              <div>
                <h1>SHE Pack Documents</h1>
              </div>

              <div>
                <h3>Selected SHE Pack : <span style={{ color: 'black' }}>{selectedShePackName}</span></h3>
              </div>

              {isLoading ? (
                <div className="loading-spinner-container">
                  <LiaSpinnerSolid className="lia-spin-solid-icon" />
                </div>
              ) : (
                <div className='view-checklist-submission-table-container'>
                  <table className="view-checklist-table">
                    <colgroup>
                      <col style={{width: "400px"}}/>
                      <col style={{width: "150px"}}/>
                      <col style={{width: "150px"}}/>
                    </colgroup>

                    <thead>
                      <tr>
                        <th className="table-header">Document Name</th>
                        <th className="table-header">Document Score</th>
                        <th className="table-header">Created By</th>
                        <th className="table-header">Date Created</th>
                        <th className="table-header">Edit</th>
                      </tr>
                    </thead>

                    <tbody className="view-table-body">
                      {shePackDocuments.length === 0 ? (
                        <tr>
                          <td colSpan="5">
                            <i>No documents found for this SHE Pack.</i>
                          </td>
                        </tr>
                      ) : (
                        shePackDocuments.map((document, index) => {
                          console.log('Rendering document:', document); // Add this line
                          return (
                            <tr key={index}>
                              <td>{document.shepack_document_name}</td>
                              <td>{document.shepack_document_score || 'No Score'}</td>
                              <td>{document.created_by}</td>
                              <td>{new Date(document.date_created).toLocaleDateString()}</td>
                              <td>
                                <FiEdit 
                                  className='delete-checklist-submition-icon'
                                  onClick={() => handleDeleteClick(document.shepack_document_id)} 
                                />
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {showDeleteToaster && (
      <ConfirmdeleteTableToaster
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />
    )}
    </div>
  );
};

export default ViewShePack;
