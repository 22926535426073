// ------------------ React Components ------------------------
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CiImageOn, CiSearch } from "react-icons/ci";
import { LiaSpinnerSolid } from "react-icons/lia";
import { MdDeleteOutline } from "react-icons/md";
import { FaRegSave, FaClipboardList, FaSpinner } from 'react-icons/fa';
import blueKbcLogo from '../../../assets/images/branding/blue-kbc-logo.png';
import ItemAddedToaster from '../../toasterscomponents/checklistToasters/ItemAddedToaster';
import FilledInChecklistToaster from '../../toasterscomponents/FilledInChecklistToaster';
import CreatedSuccesFullyToaster from '../../toasterscomponents/shepacktoasters/CreatedSuccesFullyToaster';

const ToasterViewSubmissionComponents = ({ 
  
  isOpen, 
  onClose, 
  selectedShePackName,
  shePackDocuments,
  isLoading,
  questionsPerDocument,

  shePackDocumentScore,

  selectedCompany,
  selectedDepartment// Add this prop

}) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const [showToaster, setShowToaster] = useState({});
  const [showSuccess, setShowSuccess] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [answers, setAnswers] = useState({});
  const [previewPhoto, setPreviewPhoto] = useState(null);
  const [isPreviewExpanded, setIsPreviewExpanded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [questionErrors, setQuestionErrors] = useState({});
  const [questionCategoryErrors, setQuestionCategoryErrors] = useState({});
  const [expiryDateInput, setExpiryDateInput] = useState({});
  const [expandedDocuments, setExpandedDocuments] = useState({});
  const [comments, setComments] = useState({});
  const [questionScores, setQuestionScores] = useState({});

  const user = JSON.parse(sessionStorage.getItem('user'));

  //
  const calculateUpdatedDocumentScore = (documentId) => {
    let totalDocumentScore = 0;
    const documentQuestions = questionsPerDocument[documentId];
    
    if (documentQuestions) {
      Object.values(documentQuestions).forEach(categoryQuestions => {
        categoryQuestions.forEach(question => {
          const score = questionScores[question.question_id];
          // Only include question score if it's not marked as Not Applicable
          if (score !== 'Not Applicable') {
            totalDocumentScore += parseInt(question.question_score);
          }
        });
      });
    }
    
    return totalDocumentScore;
  };
  

  useEffect(() => {
    // Initialize previous responses when questions are loaded
    if (questionsPerDocument) {
      Object.entries(questionsPerDocument).forEach(([documentId, categories]) => {
        Object.values(categories).forEach(questions => {
          questions.forEach(question => {
            // Handle previous comments
            if (question.previous_comment) {
              setComments(prev => ({
                ...prev,
                [question.question_id]: question.previous_comment
              }));
            }

            // Initialize scores with "Not Selected" if no previous score exists
            if (question.previous_user_question_score === undefined) {
              setQuestionScores(prev => ({
                ...prev,
                [question.question_id]: "Not Selected"
              }));
            } else {
              setQuestionScores(prev => ({
                ...prev,
                [question.question_id]: question.previous_user_question_score
              }));
            }
          });
        });
      });
    }

  }, [questionsPerDocument]);

  
  useEffect(() => {
    const timers = Object.keys(showToaster).map(key => showToaster[key] && setTimeout(() => setShowToaster(prev => ({ ...prev, [key]: false })), 3000));
    return () => timers.forEach(timer => clearTimeout(timer));
  }, [showToaster]);

  const toggleDocument = (documentId) => {
    setExpandedDocuments(prev => ({ ...prev, [documentId]: !prev[documentId] }));
  };

  const handleAnswerChange = (questionId, answer, questionType) => {
    setAnswers(prev => {
      if (questionType === 'Multiple Select') {
        const currentAnswers = Array.isArray(prev[questionId]) ? prev[questionId] : [];
        return { ...prev, [questionId]: currentAnswers.includes(answer) ? currentAnswers.filter(item => item !== answer) : [...currentAnswers, answer] };
      } else {
        return { ...prev, [questionId]: answer };
      }
    });
  };

  const handleFileChange = (questionId, event) => {
    const file = event.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'application/pdf') && file.size <= 10 * 1024 * 1024) {
      handleAnswerChange(questionId, file);
      setPreviewPhoto(file.type.startsWith('image/') ? URL.createObjectURL(file) : null);
    } else {
      setErrorMessage('Only PNG, JPEG, and PDF files less than 10MB are allowed.');
    }
  };

  const handleRemoveFile = (questionId) => {
    handleAnswerChange(questionId, '');
    setPreviewPhoto(null);
    document.querySelectorAll('input[type="file"]').forEach(input => input.value = '');
  };

  const handleCommentChange = (questionId, comment) => {
    setComments(prev => ({
      ...prev,
      [questionId]: comment
    }));
  };


  const handleIsExpiryDateChange = (questionId, isExpiryDate) => {
    setExpiryDateInput(prev => ({ ...prev, [questionId]: isExpiryDate }));
  };

  const handleScoreChange = (questionId, score) => {
    setQuestionScores(prev => ({
      ...prev,
      [questionId]: score
    }));
  };

  const generateScoreOptions = (maxScore) => {
    const options = [<option key="not-selected" value="Not Selected">Not Selected</option>];
    for (let i = 0; i <= maxScore; i += 5) {
      options.push(<option key={i} value={i.toString()}>{i}</option>);
    }
    options.push(<option key="not-applicable" value="Not Applicable">Not Applicable</option>);
    return options;
  };

  const isOptionSelected = (questionId, option) => {
    const answer = answers[questionId];
    return Array.isArray(answer) ? answer.includes(option) : answer === option;
  };


  const renderQuestionOptions = (question) => {
    const options = ['option_1', 'option_2', 'option_3', 'option_4', 'option_5', 'option_6', 'option_7', 'option_8', 'option_9', 'option_10', 'option_11', 'option_12', 'option_13', 'option_14', 'option_15'];
    switch (question.question_type) {
      
     
      case 'File':
      case 'Photo':
        return (
          <div className='file-upload-container'>
            <p className='secondary-color-paragraph'>Please upload a {question.question_type}. File should not exceed 10MB</p>
            <input
              className='file-upload-input'
              type="file"
              accept={question.question_type === 'File' ? ".png,.jpg,.jpeg,.pdf" : "image/png, image/jpeg"}
              onChange={(e) => handleFileChange(question.question_id, e)}
            />
            {answers[question.question_id] && (
              <div>
                {answers[question.question_id].type.startsWith('image/') && (
                  <div className='checklist-question-toaster-image-delete-button-container'>
                    <CiImageOn className='checklist-question-toaster-image-icon' onClick={() => setIsPreviewExpanded(!isPreviewExpanded)} />
                    <button type='button' className='delete-image-button' onClick={() => handleRemoveFile(question.question_id)}> <MdDeleteOutline /> </button>
                  </div>
                )}
                {isPreviewExpanded && previewPhoto && (
                  <img onClick={() => setIsPreviewExpanded(false)} src={previewPhoto} alt="Preview" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                )}
              </div>
            )}
          </div>
        );
      case 'Short Text':
        return (
          <div className='file-upload-container'>
            <input
              className="create-questions-form-name-input"
              type="text"
              value={answers[question.question_id] || ''}
              onChange={(e) => handleAnswerChange(question.question_id, e.target.value)}
              placeholder="Please enter your response"
            />
          </div>
        );
      case 'Time':
        return (
          <div className='file-upload-container'>
            <input
              type="time"
              value={answers[question.question_id] || ''}
              onChange={(e) => handleAnswerChange(question.question_id, e.target.value)}
            />
          </div>
        );
      case 'Date':
        return (
          <div className='file-upload-container'>
            <input
              type="date"
              value={answers[question.question_id] || ''}
              onChange={(e) => handleAnswerChange(question.question_id, e.target.value)}
              max={new Date().toISOString().split('T')[0]}
              className='checklist-question-toaster-date'
            />
          </div>
        );
      case 'Drop Down':
        return (
          <div className='file-upload-container'>
            <select
              value={answers[question.question_id] || ''}
              onChange={(e) => handleAnswerChange(question.question_id, e.target.value)}
            >
              <option value="">Select an option</option>
              {options.map((option, optionIndex) => (
                question[option] && (
                  <option key={optionIndex} value={question[option]}>
                    {question[option]}
                  </option>
                )
              ))}
            </select>
          </div>
        );
      default:
        return null;
    }
  };

  if (!isOpen) return null;

  return (

    <div className="checklist-question-user-fill-in-checklist-main-container">
        
        <div className="template-question-fill-in-checklist-container">
        <div className="template-fill-in-elements-container">
          <div className="user-fill-in-back-button-time-container">
            <div className='user-fill-in-back-button-container'>
              <img src={blueKbcLogo} alt="white-kbc-logo" className="add-quesiton-side-menu-logo" />
              <button type="button" onClick={onClose} className="user-fill-in-back-button"> Back</button>
            </div>
          </div>
          
          <div className="Template-fill-in-header-main-container">
            <div className="Template-fill-in-header-text-and-search-main-container">

              <div className="Template-fill-in-header-text-main-container">

                <div>
                  <h3>View submisitons for {selectedShePackName}</h3>
                </div>
                
              </div>

              {/*

                <div className="Template-fill-in-header-search-main-container">

                  <div>
                    <h4 className='secondary-color-text'>Quick Questions Search</h4>
                    <div className="Template-fill-in-header-search-search-bar-container">
                      <input 
                        placeholder='Enter question name'
                        className='template-fill-in-search-bar'
                      />
                      <button className='confirm-button'>
                        <CiSearch />
                      </button>
                    </div>
                  </div>

                </div>
               
              
              */}

              
            </div>
            {isLoading ? (
              <div className="loading-spinner-container">
                <LiaSpinnerSolid className="lia-spin-solid-icon" />
              </div>
            ) : (
              <div className='template-questions-user-fill-in-main-container'>
                <div className="template-table-main-container">
                  <div className='template-table-main-header-container'>
                    <div className='template-table-header-container'>
                      <div className="template-table-header">View Questions</div>
                      <div className="template-table-header">Document Name</div>
                      <div className="template-table-header">Document Score</div>
                      <div className="template-table-header">Percentage</div>
                      <div className="template-table-header">Status</div>
                    </div>
                  </div>
                  <div className='template-table-body'>
                    {shePackDocuments.length === 0 ? (
                      <div className='template-table-body-row-main-container'>
                        <div className='template-table-body-row-container'>
                          <i>No documents found for this SHE Pack.</i>
                        </div>
                      </div>
                    ) : (
                      shePackDocuments.map((document, index) => (
                        <div className='template-table-body-row-main-container' key={index}>
                          <div className='template-table-body-row-container'>

                            <div className='template-table-body-row-td'>
                              <FaClipboardList 
                                className='view-checklist-submition-icon' 
                                onClick={() => toggleDocument(document.shepack_document_id)}
                                
                              />
                            </div>


                            <div className='template-table-body-row-td'>{document.shepack_document_name}</div>
                            <div className='template-table-body-row-td'>  {document.user_score} / {document.effective_document_score || document.shepack_document_score}</div>
                            <div className='template-table-body-row-td'>{document.user_percentage } %</div>
                            <div className='template-table-body-row-td'>{document.submission_status || 'No submission'}</div>
                          </div>
                          {expandedDocuments[document.shepack_document_id] && (
                            <div className='template-questions-fill-in-main-container'>

                              {questionsPerDocument[document.shepack_document_id] && 
                                Object.entries(questionsPerDocument[document.shepack_document_id]).map(([category, categoryQuestions]) => (
                                  <>  
                                    <div className='template-questions-fill-in-container' key={category}>
                                    
                                    {questionCategoryErrors[category] && (
                                      <div className='error-text'>
                                        <ul>
                                          {questionCategoryErrors[category].map((error, index) => (
                                            <li key={index} className='category-error-item'>{error}</li>
                                          ))}
                                        </ul>
                                      </div>
                                    )}

                                    <div className="checklist-question-toaster-user-fill-in-checklist-category-container">
                                    <div className="checklist-question-toaster-user-fill-in-checklist-category-container">
  <div className='template-questions-fill-in-individal-questions-main-container'>
    {/* Table head moved outside the loop */}
    <table className="question-table">
      <thead>
        <tr>
          <th>Question Name</th>
          <th>Question Reference</th>
          <th>Score</th>
          <th>Additional Info</th>
        </tr>
      </thead>
      <tbody>
        {categoryQuestions.map((question, index) => (
          <React.Fragment key={index}>
            {questionErrors[question.question_id] && (
              <tr>
                <td colSpan={4} className='error-text'>
                  <p>{questionErrors[question.question_id]}</p>
                </td>
              </tr>
            )}
            <tr>
              <td>{question.question_name}</td>
              <td className="secondary-color-text">{question.question_reference}</td>
              <td>
                <div className="score-container">
                  <p>{questionScores[question.question_id] || 'Not Selected'} / {question.question_score}</p>
                </div>
              </td>
              <td>
                <div className="additional-info-container">
                  <p className="comment-text">
                    {comments[question.question_id] || 'No additional comments provided.'}
                  </p>
                </div>
              </td>
            </tr>
           
          </React.Fragment>
        ))}
      </tbody>
      <div colSpan={5}>
        <div className='Total_document_score_div'>
          <p>Total Document Score {document.user_score} / {document.effective_document_score || document.shepack_document_score}</p>
        </div>
        
      </div>
    </table>
  </div>


</div>

                                    </div>
                                  </div>

                                  
                                  
                                  
                                  </>

                                  
                                ))}
                              
                            </div>
                          )}
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {showToaster.questionSaved && <ItemAddedToaster item={'Question Saved'} />}
      {showToaster.categorySaved && <ItemAddedToaster item={'Category Saved'} />}

      {showSuccess && 
        
        <CreatedSuccesFullyToaster
          heading='SHE File Filled Successfully'
        />
      }

        
    </div>
  );
};

export default ToasterViewSubmissionComponents;